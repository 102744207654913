import styled from 'styled-components'

import { secondary, white } from '@website/common/styles/colors'
import _SlideLeft from '@editor/common/lib/SlideLeft'
import _UpgradeBanner from '../../../components/UpgradeBanner'
import _UpgradeButton from '../../../components/UpgradeButton'
import { EmailBlockCard as _EmailBlockCard } from '../styled'

const PURPLE = '#766de8'

export const EmailBlockCard = styled(_EmailBlockCard)`
  position: relative;
`

export const UpgradeLabel = styled.img`
  position: absolute;
  right: 23px;
  top: -5px;
`

export const UpgradeBanner = styled(_UpgradeBanner)`
  margin-top: 30px;
  padding: 35px 20px 25px;

  & > button {
    font-size: 16px;
    font-weight: 500;
    & > svg {
      width: 24px;
    }
  }

  & > h1 {
    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
`

export const SlideLeft = styled(_SlideLeft)`
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`

export const PreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  width: 568px;
  height: 100%;
  overflow: auto;

  table {
    border: none !important;
    border-radius: 8px;
    box-shadow: 0px 3px 12px 0px rgba(50, 113, 230, 0.1);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
export const PreviewWrapper = styled.div`
  background-color: #f8fbff;
  flex-grow: 1;
  padding: 24px;
  @media only screen and (max-width: 768px) {
    padding: unset;
  }

  table {
    @media only screen and (max-width: 768px) {
      border-top: none !important;
      margin: 0 auto 20px;
    }
  }
`

export const PreviewFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 54px 24px 30px;
  text-align: center;
  color: ${secondary.Dark};
  font-size: 16px;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    padding: 15px 20px 20px;
    box-shadow: 0px -4px 4px 0px rgba(50, 113, 230, 0.1);
  }
`

export const UpgradeButton = styled(_UpgradeButton)`
  background: ${white};
  margin-top: 15px;
  box-shadow: unset;
  border: 1px solid ${PURPLE};
  color: ${PURPLE};
  font-size: 16px;
  font-weight: 500;
  & > svg {
    width: 24px;
    fill: ${PURPLE};
  }

  &:hover {
    background: #f5f4ff;
  }
`
