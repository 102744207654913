//@flow
import React, { memo, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'

import { DEFAULT_COMP_DATA } from '@website/common/components/RSVPEmptyComponent'
import { selectAllRsvpComponents, selectEventId } from '@selectors'
import RSVPCards from '@website/components/RSVPCards'

import Preview from '../../components/Preview'
import { RightSection as StyledRightSection } from '../styled'
import { TRightSectionProps } from './types'
import * as Styled from './styled'

export const RightSection = ({
  data,
  notificationMessage
}: TRightSectionProps) => {
  const allRsvpComponents = useSelector(selectAllRsvpComponents)
  const eventId = useSelector(selectEventId)

  const rsvpComponent = allRsvpComponents[eventId] || DEFAULT_COMP_DATA
  const hasRSVPComponent = !!allRsvpComponents[eventId]
  const PreviewCard = RSVPCards[rsvpComponent.id]
  const rsvpComponentData = rsvpComponent.data

  const previewCardMetaData = useMemo(
    () => ({
      dateTitle: rsvpComponentData.dateTitle,
      locationTitle: rsvpComponentData.locationTitle,
      cardImage: rsvpComponentData.cardImage,
      centerImage: rsvpComponentData.centerImage,
      backgroundTopImage: rsvpComponentData.backgroundTopImage,
      backgroundBottomImage: rsvpComponentData.backgroundBottomImage,
      subtitleAlignment: rsvpComponentData.subtitleAlignment,
      titleAlignment: rsvpComponentData.titleAlignment,
      paragraphAlignment: rsvpComponentData.paragraphAlignment,
      dateAlignment: rsvpComponentData.dateAlignment,
      locationAlignment: rsvpComponentData.locationAlignment,
      dateTitleAlignment: rsvpComponentData.dateTitleAlignment,
      locationTitleAlignment: rsvpComponentData.locationTitleAlignment,
      dateValueAlignment: rsvpComponentData.dateValueAlignment,
      locationValueAlignment: rsvpComponentData.locationValueAlignment,
      titleTextGradient: rsvpComponentData.titleTextGradient
    }),
    [rsvpComponentData]
  )

  return (
    <StyledRightSection>
      <Preview>
        <ThemeProvider theme={{ colorMode: rsvpComponent.colorMode }}>
          <PreviewCard detailsData={data} metaData={previewCardMetaData} />
        </ThemeProvider>
      </Preview>
      {hasRSVPComponent ? null : (
        <Styled.NotificationMessage type="info" message={notificationMessage} />
      )}
    </StyledRightSection>
  )
}

export default memo(RightSection)
