import styled from 'styled-components'

import { primary, secondary, white } from '@website/common/styles/colors'
import { DatePickerWrapper as CommonDatePickerWrapper } from '../styled'

export const DatePickerWrapper = styled(CommonDatePickerWrapper)`
  .react-datepicker-popper {
    z-index: 3;

    .react-datepicker {
      font-family: inherit;
      border-radius: 5px;
      border: 1px solid ${secondary.Light};
      box-shadow: 0 3px 6px 0 rgba(50, 113, 230, 0.2);

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__current-month {
        font-size: 14px;
        font-weight: 500;
        color: ${secondary.ExtraDark};
        margin-left: 8px;
      }

      .react-datepicker__month-container {
        padding: 15px 10px 5px;
        .react-datepicker__header {
          text-align: unset;
          background-color: unset;
          border: unset;
          padding-top: 0;
        }
        .react-datepicker__day-name {
          font-size: 12px;
          color: #8493bd;
        }
      }

      .react-datepicker__month {
        margin: 0;

        .react-datepicker__day {
          font-size: 12px;
          color: ${secondary.SemiDark};

          &:hover {
            font-weight: 600;
            color: ${secondary.ExtraDark};
            background: ${primary.Light};
            border-radius: 15px;
          }
        }

        .react-datepicker__day--today {
          color: #d64040;
          font-weight: 600;
          &:hover {
            color: #d64040;
          }
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--keyboard-selected {
          color: ${white};
          background: #3271e6;
          border-radius: 15px;
          font-weight: 600;
          &:hover {
            color: ${white};
            background: #3271e6;
          }
        }

        .react-datepicker__day--disabled {
          color: ${secondary.Light};
          &:hover {
            font-weight: unset;
            color: ${secondary.Light};
            background: transparent;
          }
        }

        .react-datepicker__day--outside-month {
          color: transparent;
          pointer-events: none;
        }
      }

      .react-datepicker__navigation--previous {
        left: unset;
        right: 40px;
        top: 16px;
        border-right-color: ${secondary.SemiDark};
        &:hover {
          border-right-color: ${secondary.ExtraDark};
        }
      }
      .react-datepicker__navigation--next {
        right: 10px;
        top: 16px;
        border-left-color: ${secondary.SemiDark};
        &:hover {
          border-left-color: ${secondary.ExtraDark};
        }
      }
    }
  }
`
