//@flow

import React, { memo, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { getFlagUrl } from '@website/common/utils/website-languages'
import {
  selectGuestsListData,
  selectIsGuestsListLoading,
  selectGuestListSortingInfo,
  selectGuestsCountsByLanguage,
  selectIsGuestListPaginationLoading
} from '@selectors'
import {
  setGuestsListSortingInfo,
  getGuestListNextPage,
  removeGuest
} from '@actions'
import useInfiniteScroll from '@hooks/useInfiniteScroll'
import { RippleLoading } from '@editor/common/components/Loaders'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import Loader from '@editor/common/components/Loaders/CircleProgressLoader'
import RsvpAnswer from '../RsvpAnswer'
import EmptyContent from './EmptyContent'
import TH from './TableHead'
import type { TTableProps } from './types'
import * as Styled from './styled'
import { TABLE_META } from './consts'

const DesktopTable = ({
  guestsList,
  activeGuestInfo,
  isGuestListLoading,
  isLangColumnVisible,
  guestListSortingInfo,
  isGuestListPaginationLoading,
  setActiveGuestInfo,
  setGuestsListSortingInfo,
  openGuestDetails,
  removeGuest,
  getGuestListNextPage,
  openEditGuestSidebar
}: TTableProps) => {
  const tableWrapperRef = useRef()

  const stopPropagation = useCallback(e => {
    e.stopPropagation()
  }, [])

  const hasGuests = guestsList.length > 0


  useInfiniteScroll(tableWrapperRef, getGuestListNextPage)

  return  (
    <Styled.TableContainer isEmptyContentVisible={!hasGuests}>
      {isGuestListLoading ? <Loader className="circle-loader" /> : null}
      <Styled.TableWrapper ref={tableWrapperRef} hasGuests={hasGuests}>
        <Styled.Table hasGuests={hasGuests}>
          <thead>
            <Styled.TableRow>
              {TABLE_META.map(column => {
                const isSortingActive =
                  guestListSortingInfo.columnName === column.columnName
                const hideColumn =
                  column.columnName === 'lang' && !isLangColumnVisible
                return hideColumn ? null : (
                  <TH
                    {...column}
                    isAscending={guestListSortingInfo.isAscending}
                    onSortChange={setGuestsListSortingInfo}
                    isSortActive={isSortingActive}
                  />
                )
              })}
            </Styled.TableRow>
          </thead>
          {hasGuests ? (
            <tbody>
              {guestsList.map((guest, guestIdx) => {
                const isActive = activeGuestInfo?.id === guest.id
                return (
                  <Styled.TableRow
                    isActive={isActive}
                    onClick={() => {
                      setActiveGuestInfo(guest)
                      openGuestDetails()
                    }}
                  >
                    {isLangColumnVisible ? (
                      <td>
                        <Styled.Flag
                          src={getFlagUrl(guest.language.flagCode)}
                        />
                      </td>
                    ) : null}
                    <td>{moment(guest.createdAt).format('DD.MM.YYYY')}</td>
                    <td>{`${guest.firstName} ${guest.lastName}`}</td>
                    <td>
                      <RsvpAnswer answer={guest.rsvpOption.answer} />
                    </td>
                    <td>{guest.additionalGuests.length}</td>
                    <td>{guest.email}</td>
                    <td onClick={stopPropagation}>
                      <Styled.ActionButtonWrapper>
                        <Styled.ActionButton
                          onClick={() => {
                            setActiveGuestInfo(guest)
                            openEditGuestSidebar()
                          }}
                        >
                          <EditIcon />
                        </Styled.ActionButton>
                        <Styled.ActionButton
                          onClick={() => removeGuest(guest.id, guestIdx)}
                        >
                          <DeleteIcon />
                        </Styled.ActionButton>
                      </Styled.ActionButtonWrapper>
                    </td>
                  </Styled.TableRow>
                )
              })}
            </tbody>
          ) : null}
        </Styled.Table>
        {!hasGuests ? <EmptyContent /> : null}
      </Styled.TableWrapper>
      {isGuestListPaginationLoading ? (
        <Styled.PaginationLoaderWrapper>
          <RippleLoading />
        </Styled.PaginationLoaderWrapper>
      ) : null}
    </Styled.TableContainer>
  )
}

const mapStateToProps = state => ({
  isGuestListLoading: selectIsGuestsListLoading(state),
  guestsList: selectGuestsListData(state),
  guestListSortingInfo: selectGuestListSortingInfo(state),
  isLangColumnVisible: selectGuestsCountsByLanguage(state).length !== 0,
  isGuestListPaginationLoading: selectIsGuestListPaginationLoading(state)
})

const mapDispatchToProps = {
  setGuestsListSortingInfo,
  getGuestListNextPage,
  removeGuest
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(DesktopTable))
