//@flow
import React, { useRef, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'

import {
  setGuestsListSearchStr,
  setGuestsListAnswer,
  setGuestsListLanguage
} from '@actions'
import {
  selectGuestsListFilters,
  selectGuestsCountsByAnswer,
  selectGuestsCountsByLanguage
} from '@selectors'
import SearchInput from '@editor/common/components/SearchInput'
import { translate } from '@editor/common/utils/translations'
import debounce from '@editor/common/utils/debounce'

import { useResponsive } from '@shared/hooks'

import MobileFilters from './MobileFilters'
import AnswerDropdown from './AnswerDropdown'
import LanguageDropdown from './LanguageDropdown'
import * as Styled from './styled'
import { TProps } from './types'

const Filters = ({
  guestsListFilters,
  guestsCountsByAnswer,
  guestsCountsByLanguage,
  setGuestsListSearchStr,
  setGuestsListAnswer,
  setGuestsListLanguage
}: TProps) => {
  const { searchStr, answer, language } = guestsListFilters
  const [_searchStr, setSearchStr] = useState(searchStr)
  const isLanguageFilterVisible = guestsCountsByLanguage.length > 1

  useEffect(() => {
    setSearchStr(searchStr)
  }, [searchStr])

  const debouncedSearch = useRef(
    debounce((str: string) => {
      setGuestsListSearchStr(str)
    }, 500)
  ).current

  const handleSearchChange = useCallback(value => {
    setSearchStr(value)
    debouncedSearch(value)
  }, [])

  const isMobile = useResponsive(768)

  return (
    <Styled.Filters isLanguageFilterVisible={isLanguageFilterVisible}>
      <SearchInput
        placeholder={translate('search_name_label')}
        value={_searchStr}
        onChange={handleSearchChange}
      />
      {isMobile ? (
        <MobileFilters
          isLanguageFilterVisible={isLanguageFilterVisible}
          guestsCountsByAnswer={guestsCountsByAnswer}
          activeAnswer={answer}
          setGuestsListAnswer={setGuestsListAnswer}
          guestsCountsByLanguage={guestsCountsByLanguage}
          activeLanguage={language}
          setGuestsListLanguage={setGuestsListLanguage}
        />
      ) : (
        <Styled.GuestFiltersWrapper
          isLanguageFilterVisible={isLanguageFilterVisible}
        >
          <AnswerDropdown
            guestsCountsByAnswer={guestsCountsByAnswer}
            answer={answer}
            setGuestsListAnswer={setGuestsListAnswer}
          />
          {isLanguageFilterVisible ? (
            <LanguageDropdown
              guestsCountsByLanguage={guestsCountsByLanguage}
              language={language}
              setGuestsListLanguage={setGuestsListLanguage}
            />
          ) : null}
        </Styled.GuestFiltersWrapper>
      )}
    </Styled.Filters>
  )
}

const mapStateToProps = state => ({
  guestsListFilters: selectGuestsListFilters(state),
  guestsCountsByAnswer: selectGuestsCountsByAnswer(state),
  guestsCountsByLanguage: selectGuestsCountsByLanguage(state)
})

const mapDispatchToProps = {
  setGuestsListSearchStr,
  setGuestsListAnswer,
  setGuestsListLanguage
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
