//@flow

import React, { memo, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  selectGuestsListData,
  selectIsGuestsListLoading,
  selectGuestListSortingInfo,
  selectGuestsCountsByLanguage,
  selectIsGuestListPaginationLoading
} from '@selectors'
import {
  setGuestsListSortingInfo,
  getGuestListNextPage,
  removeGuest
} from '@actions'
import useInfiniteScroll from '@hooks/useInfiniteScroll'
import { RippleLoading } from '@editor/common/components/Loaders'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_left.svg'
import Loader from '@editor/common/components/Loaders/CircleProgressLoader'
import RsvpAnswer from '../RsvpAnswer'
import EmptyContent from './EmptyContent'
import GuestDetailsMobile from './GuestDetailsMobile'
import type { TTableProps } from './types'
import * as Styled from './styled'

const MobileTable = ({
  guestsList,
  activeGuestInfo,
  isGuestListLoading,
  isLangColumnVisible,
  isGuestListPaginationLoading,
  setActiveGuestInfo,
  removeGuest,
  getGuestListNextPage,
  openEditGuestSidebar
}: TTableProps) => {
  const tableWrapperRef = useRef()

  const hasGuests = guestsList.length > 0

  useInfiniteScroll(tableWrapperRef, getGuestListNextPage)

  const [guestId, setGuestId] = useState(null)

  return (
    <Styled.TableContainer isEmptyContentVisible={!hasGuests}>
      {isGuestListLoading ? <Loader className="circle-loader" /> : null}
      <Styled.TableWrapper ref={tableWrapperRef} hasGuests={hasGuests}>
        <Styled.GuestListContainer hasGuests={hasGuests}>
          {hasGuests ? (
            <div>
              {guestsList.map((guest, guestIdx) => {
                const isActive = guestId === guest.id
                return (
                  <Styled.RowWrapper isActive={isActive}>
                    <Styled.GuestListRow
                      isActive={isActive}
                      onClick={() => {
                        setGuestId(isActive ? null : guest.id)
                      }}
                    >
                      <Styled.GuestListName>{`${guest.firstName} ${guest.lastName}`}</Styled.GuestListName>
                      <Styled.GuestListInfo>
                        <Styled.GuestListResponse>
                          <RsvpAnswer answer={guest.rsvpOption.answer} />
                        </Styled.GuestListResponse>
                        <Styled.ActionButton
                          onClick={e => {
                            setActiveGuestInfo(guest)
                            openEditGuestSidebar()
                          }}
                        >
                          <EditIcon />
                        </Styled.ActionButton>
                        <Styled.ActionButton
                          onClick={e => {
                            removeGuest(guest.id, guestIdx)
                          }}
                        >
                          <DeleteIcon />
                        </Styled.ActionButton>
                        <Styled.ActionButton
                          className="arrow-button"
                          isActive={isActive}
                        >
                          <ArrowIcon />
                        </Styled.ActionButton>
                      </Styled.GuestListInfo>
                    </Styled.GuestListRow>
                    {isActive ? (
                      <GuestDetailsMobile
                        data={guest}
                        isLangColumnVisible={isLangColumnVisible}
                      />
                    ) : null}
                  </Styled.RowWrapper>
                )
              })}
            </div>
          ) : null}
        </Styled.GuestListContainer>
        {!hasGuests ? <EmptyContent /> : null}
      </Styled.TableWrapper>
      {isGuestListPaginationLoading ? (
        <Styled.PaginationLoaderWrapper>
          <RippleLoading />
        </Styled.PaginationLoaderWrapper>
      ) : null}
    </Styled.TableContainer>
  )
}

const mapStateToProps = state => ({
  isGuestListLoading: selectIsGuestsListLoading(state),
  guestsList: selectGuestsListData(state),
  guestListSortingInfo: selectGuestListSortingInfo(state),
  isLangColumnVisible: selectGuestsCountsByLanguage(state).length !== 0,
  isGuestListPaginationLoading: selectIsGuestListPaginationLoading(state)
})

const mapDispatchToProps = {
  setGuestsListSortingInfo,
  getGuestListNextPage,
  removeGuest
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(MobileTable))
