import styled from 'styled-components'

import { white, secondary } from '@website/common/styles/colors'
import {
  Divider as _Divider,
  TextHeader as CommonTextHeader
} from '../../styled'

export const PreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8fbff;
  padding-top: 24px;
  width: 568px;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-top: 0;
  }

  & table {
    border: none !important;
    border-radius: 0 0 5px 5px;

    @media only screen and (max-width: 768px) {
      & {
        margin: 0 auto;
      }
    }
  }
`
export const PreviewWrapper = styled.div`
  background-color: #f8fbff;
  flex-grow: 1;
  margin: 0 24px 24px;

  @media only screen and (max-width: 768px) {
    padding: unset;
  }
`

export const TextHeader = styled(CommonTextHeader)`
  background-color: ${white};
  margin: 0 24px;
  padding: 16px 24px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 3px 12px 0 rgba(50, 113, 230, 0.1);
`
export const Divider = styled(_Divider)`
  margin: 40px 0 30px;
`
export const SubjectSection = styled.div`
  display: flex;
  padding: 20px 20px 0;
  background: ${white};
  font-size: 14px;
  font-weight: 500;
  line-height: normal;

  @media only screen and (max-width: 768px) {
    max-width: 480px;
    margin: 0 auto;
  }
`

export const SubjectLabel = styled.div`
  color: #8493bd;
`

export const Subject = styled.span`
  color: ${secondary.ExtraDark};
  margin-left: 10px;
`

export const PreviewContentWrapper = styled.div`
  height: fit-content;
  box-shadow: 0 3px 12px 0 rgba(50, 113, 230, 0.1);
`

export const SubjectPlaceholder = styled.span`
  color: ${secondary.Normal};
`
