//@flow
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import { editGuest, setEventSuccessMessage } from '@actions'
import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { translate } from '@editor/common/utils/translations'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import { selectEventSuccessMessage } from '@selectors'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'

import { SlideLeft } from '../styled'
import { GuestDetailsForm, RSVPOptions } from './components'
import * as Styled from './styled'
import { TEditGuestProps } from './types'
import { getEditGuestInitialState } from './utils'

const EditGuest = ({
  isOpen,
  formDataFromRevision,
  activeGuestInfo,
  onClose,
  setHasChangesInTab,
  setActiveGuestInfo,
  editGuest,
  successMessage,
  setEventSuccessMessage
}: TEditGuestProps) => {
  if (!isOpen) {
    return null
  }

  const initialState = useMemo(
    () => getEditGuestInitialState(activeGuestInfo),
    [activeGuestInfo]
  )

  const [formData, setFormData] = useState(initialState)

  const { firstName, lastName, email, phone, rsvpOption } = formDataFromRevision

  const hasChanges = JSON.stringify(initialState) !== JSON.stringify(formData)

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  const _onClose = useCallback(() => {
    onClose()
    setFormData(initialState)
    successMessage && setEventSuccessMessage('')
  }, [initialState, onClose, successMessage, setEventSuccessMessage])

  const closeSidebar = useDiscardDialog(_onClose, {
    id: 'add-guest',
    hasChanges
  })

  const updateData = data => {
    setActiveGuestInfo(data)
  }

  const onSave = useCallback(() => {
    editGuest(formData, activeGuestInfo.id, updateData)
  }, [editGuest, formData, activeGuestInfo])

  const { register, formErrors, handleSubmit } = useFormValidation(onSave)

  return (
    <SlideLeft
      isOpen={isOpen}
      onClose={closeSidebar}
      shouldCloseOnBackdropClick
      backdropDisablingSelectors={['.global-error-popup']}
    >
      <Styled.Content isEdit>
        <Styled.Form>
          <Styled.Title>{translate('edit_guest_response_label')}</Styled.Title>
          {rsvpOption.isVisible ? (
            <RSVPOptions
              isInEdit
              rsvpOption={rsvpOption}
              rsvpOptionAnswer={formData?.rsvpOption?.answer}
              setFormData={setFormData}
            />
          ) : null}
          <GuestDetailsForm
            isInEdit
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            formData={formData}
            setFormData={setFormData}
            formErrors={formErrors}
            register={register}
          />
        </Styled.Form>
        <SaveAndDiscardFooter
          className="save-cancel-footer"
          discardBtnText={translate('cancel_label')}
          successMessage={successMessage}
          isSaveBtnDisabled={!hasChanges}
          onDiscard={closeSidebar}
          onSave={handleSubmit}
          setSuccessMessage={setEventSuccessMessage}
        />
      </Styled.Content>
    </SlideLeft>
  )
}

const mapStateToProps = state => ({
  successMessage: selectEventSuccessMessage(state)
})

const mapDispatchToProps = { editGuest, setEventSuccessMessage }

export default connect(mapStateToProps, mapDispatchToProps)(memo(EditGuest))
