//@flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TInputProps } from '@editor/common/lib/Input/types'
import Input from '@editor/common/lib/Input'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'

const SearchInput = ({ inputRef, ...rest }: TInputProps) => (
  <Styled.SearchInput>
    <Icon
      className="search-icon"
      name="search_tiny"
      size="medium"
      color="#545f7e"
    />
    <Input ref={inputRef} {...rest} />
  </Styled.SearchInput>
)

export default memo(SearchInput)
