import styled, { css } from 'styled-components'

import { SimpleDropdown as LibSimpleDropdown } from '@renderforest/rf-ui-library/molecules/CustomizableDropdown/SimpleDropdown'
import { SearchDropdown as LibSearchDropdown } from '@renderforest/rf-ui-library/molecules/CustomizableDropdown/SearchDropdown'
import { secondary } from '@website/common/styles/colors'

const getDropdownStyles = () => css`
  width: 100%;
  border-radius: 5px;
  box-shadow: none;

  & > label {
    margin: 0 0 5px 0;
    color: ${secondary.SemiDark};
    font-size: 14px;
  }

  .dropdown-wrapper {
    border: 1px solid ${secondary.Light};
    &:hover {
      border: 1px solid #8493bd;
    }
  }

  .dropdown-wrapper-active {
    border: 1px solid #3271e6;
  }

  .dropdown-wrapper,
  .dropdown-wrapper-active {
    height: 40px;
    max-height: unset;
    border-radius: 5px;
    padding: 0 15px;
    justify-content: center;
    box-sizing: border-box;

    .search-dropdown-active-item {
      color: ${({ isDisabled }) =>
        isDisabled ? '#8493bd' : secondary.ExtraDark};
    }

    .search-dropdown-options-wrapper {
      padding: 0;
      top: 44px;
      box-sizing: unset;
      border: 1px solid ${secondary.Light};
      border-radius: 5px;
      box-shadow: 0 3px 6px 0 rgba(50, 113, 230, 0.2);
      .react-window-wrapper {
        padding: 0;

        .search-dropdown-list-item {
          border: none;
          border-radius: 0;
          color: ${secondary.SemiDark};
          padding: 0 15px;
          font-size: 14px;
        }
      }
    }

    ${({ isDisabled }) =>
      isDisabled
        ? css`
            background-color: ${secondary.UltraLight};
            cursor: default;
            pointer-events: none;
          `
        : ''}
  }

  .arrow_filled_bottom {
    margin-top: 0;
    position: absolute;
    right: 10px;
    top: 7px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    box-shadow: none;
  }
`

export const SimpleDropdown = styled(LibSimpleDropdown)`
  ${getDropdownStyles};
`

export const SearchDropdown = styled(LibSearchDropdown)`
  ${getDropdownStyles};
`

export const MobileDropdownWrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const MobileDropdownItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ isActive }) => (isActive ? '#252E48' : '#545F7E')};
`

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`

export const InputLabel = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  color: rgb(84, 95, 126);
`

export const Input = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-shadow: none;
  height: 40px;
  max-height: unset;
  border-radius: 5px;
  padding: 0px 15px;
  box-sizing: border-box;
  border: 1px solid rgb(193, 201, 224);
  font-size: 14px;
  background-color: #ffffff;
  ${({ isSlideUpOpen }) =>
    isSlideUpOpen
      ? css``
      : css`
          .arrow-icon-filled {
            transform: rotate(180deg);
          }
        `};
  .arrow-icon-filled {
    position: absolute;
    right: 15px;
    transition: transform 0.3s;
  }
`

export const SlideUpHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px 40px;
  border-bottom: 1px solid #c1c9e0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  @media only screen and (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  .arrow-icon {
    position: absolute;
    transform: rotate(270deg);
    left: 40px;
    top: 20px;
    @media only screen and (max-width: 600px) {
      left: 20px;
    }
  }
`

export const ResponseTypeLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #252e48;
`

export const DropdownItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 20px;
  @media only screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const SlideUpContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`
