import styled, { css } from 'styled-components'

import { primary, secondary } from '@website/common/styles/colors'

const ACTIVE_ITEM_STYLES = css`
  background-color: ${primary.Light};
  & > div > svg {
    fill: ${secondary.Dark};
  }
  & > div > span {
    color: ${secondary.Dark};
  }
`

export const MenuItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #e0e4f0;
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;

  & > div {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  & > div > svg {
    & {
      fill: ${secondary.SemiDark};
    }
    width: 24px;
    height: auto;
  }

  & > div > span {
    font-size: 14px;
    color: ${secondary.SemiDark};
    font-weight: 400;
    margin-left: 10px;
  }

  &:hover {
    & > div {
      transform: translateX(3px);
      transition: transform 0.3s;
    }

    ${ACTIVE_ITEM_STYLES}
  }

  ${({ isActive }) =>
    isActive
      ? css`
          ${ACTIVE_ITEM_STYLES};
          & > div > span {
            font-weight: 500;
          }
        `
      : ''};
`
