//@flow
import React, { memo } from 'react'

import { Layer } from '../styled'
import { ADD, EDIT } from '../consts'
import AddGuest from './AddGuest'
import EditGuest from './EditGuest'

const AddEditSidebar = ({
  type,
  onClose,
  formDataFromRevision,
  activeGuestInfo,
  setActiveGuestInfo,
  setHasChangesInTab
}) => {
  if (!Object.keys(formDataFromRevision).length) {
    return null
  }

  const isEditOpen = type === EDIT
  const isAddOpen = type === ADD

  return (
    <>
      <AddGuest
        isOpen={isAddOpen}
        formDataFromRevision={formDataFromRevision}
        onClose={onClose}
        setHasChangesInTab={setHasChangesInTab}
      />
      <EditGuest
        isOpen={isEditOpen}
        formDataFromRevision={formDataFromRevision}
        activeGuestInfo={activeGuestInfo}
        onClose={onClose}
        setActiveGuestInfo={setActiveGuestInfo}
        setHasChangesInTab={setHasChangesInTab}
      />
      {isEditOpen || isAddOpen ? <Layer /> : null}
    </>
  )
}

export default memo(AddEditSidebar)
