//@flow
import React, { useEffect, useState, useCallback, useContext } from 'react'
import { connect } from 'react-redux'

import { UpgradePopupContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import {
  getFormDataFromRevision,
  getGuestsList,
  resetGuestsListFilters
} from '@actions'
import {
  selectFormDataFromRevision,
  selectTotalGuestsCount,
  selectIsGuestListUpgradeVisible,
  selectRsvpGuestLimit
} from '@selectors'

import { TabContainer, Content, PopupCloseIconState } from '../styled'
import ContentTitle from '../../components/ContentTitle'
import GuestDetails from './GuestDetails'
import AddEditSidebar from './AddEditSidebar'
import Table from './Table'
import UpgradeBanner from './UpgradeBanner'
import Filters from './ToolbarFilters'
import Buttons from './ToolbarButtons'
import { ADD, EDIT } from './consts'
import * as Styled from './styled'
import type { TGuestListProps } from './types'

const GuestList = ({
  getGuestsList,
  getFormDataFromRevision,
  isUpgradeBannerVisible,
  rsvpGuestLimit,
  totalCount,
  formDataFromRevision,
  setHasChangesInTab,
  resetGuestsListFilters
}: TGuestListProps) => {
  const [activeGuestInfo, setActiveGuestInfo] = useState(null)
  const [isGuestDetailsOpened, setGuestDetailsOpenedState] = useState(false)
  const [sidebarType, setSidebarType] = useState('')

  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  useEffect(() => {
    getGuestsList()
    getFormDataFromRevision()
  }, [])

  useEffect(
    () => () => {
      resetGuestsListFilters()
    },
    []
  )

  const popupCloseIconState = !!sidebarType || isGuestDetailsOpened

  const openGuestDetails = useCallback(() => {
    setGuestDetailsOpenedState(true)
  }, [])

  const resetActiveGuestInfo = useCallback(() => {
    setTimeout(() => setActiveGuestInfo(null), 300)
  }, [])

  const closeGuestDetails = useCallback(() => {
    setGuestDetailsOpenedState(false)
    resetActiveGuestInfo()
  }, [])

  const openAddGuestSidebar = useCallback(() => {
    if (totalCount >= rsvpGuestLimit) {
      setUpgradePopupState({ isOpen: true })
      return
    }

    setSidebarType(ADD)
  }, [rsvpGuestLimit, totalCount])

  const openEditGuestSidebar = useCallback(() => {
    setSidebarType(EDIT)
  }, [])

  const closeAddEditSidebar = useCallback(() => {
    activeGuestInfo && resetActiveGuestInfo()
    setSidebarType('')
  }, [activeGuestInfo])

  return (
    <TabContainer>
      <Content>
        {popupCloseIconState ? <PopupCloseIconState /> : null}
        <ContentTitle
          title={translate('event_guest_list_label')}
          subTitle={translate('event_guest_list_subtitle')}
          withUnderline={false}
        />
        <Styled.MainContent>
          <Styled.Toolbar>
            <Filters />
            <Buttons
              isDownloadBtnDisabled={!totalCount}
              isAddGuestBtnDisabled={!Object.keys(formDataFromRevision).length}
              onAddGuestClick={openAddGuestSidebar}
            />
          </Styled.Toolbar>
          <Table
            activeGuestInfo={activeGuestInfo}
            setActiveGuestInfo={setActiveGuestInfo}
            openGuestDetails={openGuestDetails}
            openEditGuestSidebar={openEditGuestSidebar}
          />
        </Styled.MainContent>
        <GuestDetails
          isOpen={isGuestDetailsOpened}
          onClose={closeGuestDetails}
          data={activeGuestInfo}
        />
        <AddEditSidebar
          type={sidebarType}
          activeGuestInfo={activeGuestInfo}
          formDataFromRevision={formDataFromRevision}
          onClose={closeAddEditSidebar}
          setActiveGuestInfo={setActiveGuestInfo}
          setHasChangesInTab={setHasChangesInTab}
        />
        {isUpgradeBannerVisible ? <UpgradeBanner /> : null}
      </Content>
    </TabContainer>
  )
}

const mapStateToProps = state => ({
  isUpgradeBannerVisible: selectIsGuestListUpgradeVisible(state),
  rsvpGuestLimit: selectRsvpGuestLimit(state),
  totalCount: selectTotalGuestsCount(state),
  formDataFromRevision: selectFormDataFromRevision(state)
})

export default connect(mapStateToProps, {
  getGuestsList,
  getFormDataFromRevision,
  resetGuestsListFilters
})(GuestList)
