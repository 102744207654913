import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'
import _UpgradeButton from '../UpgradeButton'

export const BannerContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: ${({ bgImageUrl }) => `url(${bgImageUrl})`};
  border-radius: 10px;
`
export const Title = styled.h1`
  color: ${secondary.ExtraDark};
  width: 100%;
  margin-bottom: 0;
  text-align: center;
`
export const Paragraph = styled.p`
  color: ${secondary.Dark};
  width: 100%;
  margin-bottom: 0;
  text-align: center;
  margin-top: 10px;
`

export const UpgradeButton = styled(_UpgradeButton)`
  margin-top: 15px;
`
