import { translate } from '@editor/common/utils/translations'

export const getTabsData = formErrors => [
  {
    label: translate('yes_label'),
    value: 'yes',
    hasErrorInTab:
      formErrors.hasOwnProperty(`yes_title`) ||
      formErrors.hasOwnProperty(`yes_buttonText`)
  },
  {
    label: translate('no_label'),
    value: 'no',
    hasErrorInTab: formErrors.hasOwnProperty(`no_title`)
  },
  {
    label: translate('registration_closed_label'),
    value: 'closed',
    hasErrorInTab: formErrors.hasOwnProperty(`closed_title`)
  }
]
