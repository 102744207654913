//@flow
import React, { memo, useState, useEffect, useRef } from 'react'

import * as Styled from './styled'
import type { TGoogleMapProps } from './types'
import { YVN } from './consts'
import SearchInput from '@editor/common/components/SearchInput'
import { addressSplitter, injectMapScript } from './utils'
import { translate } from '@editor/common/utils/translations'
import { getAddressFromQuery } from '@website/common/utils'

export const GoogleMap = ({
  mapSrc,
  geoLocation,
  areControlsHidden,
  setMapSrc,
  setGeoLocation
}: TGoogleMapProps) => {
  const [inputValue, setInputValue] = useState(getAddressFromQuery(mapSrc))
  const inputRef = useRef(null)
  const mapWrapperRef = useRef(null)

  const onScriptLoad = () => {
    const isGeoLocationObjEmpty = Object.keys(geoLocation).length === 0

    const map = new google.maps.Map(mapWrapperRef.current, {
      zoom: 16,
      center: isGeoLocationObjEmpty ? YVN : geoLocation,
      disableDefaultUI: areControlsHidden
    })

    const marker = new google.maps.Marker({
      map,
      position: isGeoLocationObjEmpty ? YVN : geoLocation,
      draggable: true
    })

    const input = inputRef.current
    const searchBox = new google.maps.places.SearchBox(input)
    const geocoder = new google.maps.Geocoder()

    if (isGeoLocationObjEmpty) {
      geocoder.geocode(
        { address: getAddressFromQuery(mapSrc) },
        (results, status) => {
          if (status === 'OK') {
            map.setCenter(results[0].geometry.location)
            marker.setPosition(results[0].geometry.location)
          }
        }
      )
    }

    const changeAddress = event => {
      const latlng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const address = addressSplitter(results[0].formatted_address)

            setMapSrc(`https://maps.google.com/maps?q=${address}&output=embed`)
            setGeoLocation(latlng)
            setInputValue(results[0].formatted_address)
          } else {
            console.log('No results found')
          }
        } else {
          console.log('Geocoder failed due to: ' + status)
        }
      })
    }

    const changePlace = () => {
      const places = searchBox.getPlaces()

      if (places.length === 0) {
        return
      }

      const bounds = new google.maps.LatLngBounds()

      places.forEach(place => {
        if (!place.geometry) {
          console.log('Returned place contains no geometry')
          return
        }

        marker.setPosition(place.geometry.location)

        const address = addressSplitter(place.formatted_address)
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        setMapSrc(`https://maps.google.com/maps?q=${address}&output=embed`)
        setGeoLocation({ lat, lng })
        setInputValue(place.formatted_address)

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }

        map.fitBounds(bounds)
      })
    }

    map.addListener('click', event => changeAddress(event))
    marker.addListener('dragend', event => changeAddress(event))
    searchBox.addListener('places_changed', changePlace)
  }

  useEffect(() => {
    injectMapScript(onScriptLoad)
  }, [])

  return (
    <div>
      <SearchInput
        inputRef={inputRef}
        value={inputValue}
        label={translate('address_on_the_map_label')}
        placeholder={translate('search_label')}
        onChange={setInputValue}
      />
      <Styled.MapWrapper ref={mapWrapperRef}></Styled.MapWrapper>
    </div>
  )
}

export default memo(GoogleMap)
