//@flow
import React, { memo } from 'react'

import { getFlagUrl } from '@website/common/utils/website-languages'

import * as Styled from './styled'
import type { TCheckboxLabelProps } from './types'

const CheckboxLabel = ({
  data: { language, flagCode }
}: TCheckboxLabelProps) => (
  <Styled.CheckBoxLabel>
    <img src={getFlagUrl(flagCode)} alt={flagCode} className="flag" />
    <p className="language">{language}</p>
  </Styled.CheckBoxLabel>
)

export default memo(CheckboxLabel)
