//@flow
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { connect } from 'react-redux'
import produce from 'immer'

import {
  setEventSuccessMessage,
  updateEventConfirmationEmailContent,
  updateEventConfirmationEmailStatus
} from '@actions'
import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import CloseIcon from '@editor/common/assets/svgr-icons/cancel.svg'
import Switcher from '@editor/common/lib/Switcher/Switcher'
import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import {
  selectEmailConfirmationIsEnabled,
  selectEventSuccessMessage
} from '@selectors'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'

import MobilePreviewButton from '../../../MobilePreviewButton'
import { PopupCloseIconState } from '../../../styled'
import { ContentTitle, Layer, SlideLeft } from '../../styled'
import usePreviewData from '../../usePreviewData'
import {
  EditButton,
  EmailBlock,
  MobileTextHeader,
  SidebarContent,
  SidebarMainContent
} from '../styled'
import Preview from '../Preview'
import EmailForm from '../EmailForm'
import { TProps } from './types'
import useInitialData from './useInitialData'

const ConfirmationEmail = ({
  isEnabled,
  successMessage,
  setHasChangesInTab,
  setEventSuccessMessage,
  updateEventConfirmationEmailStatus,
  updateEventConfirmationEmailContent
}: TProps) => {
  const initialData = useInitialData()
  const previewData = usePreviewData()

  const [data, setData] = useState(initialData)
  const [isMobilePreviewOpen, setMobilePreviewState] = useState(false)
  const [isSidebarOpened, setSidebarOpenedState] = useState(false)
  const [isSwitcherChecked, setSwitcherCheckedState] = useState(isEnabled)

  const isMobile = useContext(MobileDetectContext)

  const hasChanges = useMemo(
    () =>
      Object.keys(data).some(key => initialData[key].text !== data[key].text),
    [data, initialData]
  )

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  const onSwitcherChange = useCallback(value => {
    setSwitcherCheckedState(value)
    updateEventConfirmationEmailStatus(value)
  }, [])

  const openSidebar = useCallback(() => {
    setSidebarOpenedState(true)
  }, [])

  const onClose = useCallback(() => {
    successMessage && setEventSuccessMessage('')

    setSidebarOpenedState(false)
    setData(initialData)
  }, [successMessage, initialData])

  const closeSidebar = useDiscardDialog(onClose, {
    id: 'confirmation-email-sidebar',
    hasChanges
  })

  const onSave = useCallback(() => {
    updateEventConfirmationEmailContent(data)
  }, [data])

  const onDataChange = useCallback(
    key => value => {
      setData(
        produce(draft => {
          draft[key].text = value
        })
      )
    },
    []
  )

  const openMobilePreview = useCallback(() => {
    setMobilePreviewState(true)
  }, [])

  const closeMobilePreview = useCallback(() => {
    setMobilePreviewState(false)
  }, [])

  const { handleSubmit, formErrors, register } = useFormValidation(onSave)

  return (
    <>
      <EmailBlock>
        <div>
          <ContentTitle
            title={translate('confirmation_email_title')}
            subTitle={translate('confirmation_email_subtitle')}
            withUnderline={false}
            hasPaddings={false}
          />
          <EditButton onClick={openSidebar}>
            <EditIcon />
            {translate('edit_label')}
          </EditButton>
        </div>
        <Switcher
          isChecked={isSwitcherChecked}
          checkedBgColor="#3271e6"
          className="switcher"
          onChange={onSwitcherChange}
        />
      </EmailBlock>
      <SlideLeft
        isOpen={isSidebarOpened}
        onClose={closeSidebar}
        shouldCloseOnBackdropClick
        backdropDisablingSelectors={[
          '.global-error-popup',
          '.confirm-email-preview'
        ]}
      >
        <SidebarContent>
          {isMobile ? (
            <MobileTextHeader>
              <CloseIcon onClick={closeSidebar} />
              {translate('confirmation_email_title')}
            </MobileTextHeader>
          ) : null}
          <SidebarMainContent>
            <EmailForm
              emailData={data}
              setEmailData={onDataChange}
              register={register}
              formErrors={formErrors}
            />
            {isMobile ? (
              <MobilePreviewButton onClick={openMobilePreview} />
            ) : null}
            {!isMobile ? (
              <Preview
                subject={data.subject.text}
                message={data.message.text}
                greeting={data.greeting.text}
                title={data.title.text}
                onClose={closeMobilePreview}
                {...previewData}
              />
            ) : null}
          </SidebarMainContent>
          <SaveAndDiscardFooter
            className="save-cancel-footer"
            discardBtnText={translate('cancel_label')}
            isSaveBtnDisabled={!hasChanges}
            successMessage={successMessage}
            onDiscard={closeSidebar}
            onSave={handleSubmit}
            setSuccessMessage={setEventSuccessMessage}
          />
        </SidebarContent>
      </SlideLeft>
      {isMobile ? (
        <SlideLeft
          isOpen={isMobilePreviewOpen}
          className="confirm-email-preview"
        >
          <Preview
            subject={data.subject.text}
            message={data.message.text}
            greeting={data.greeting.text}
            title={data.title.text}
            onClose={closeMobilePreview}
            {...previewData}
          />
        </SlideLeft>
      ) : null}
      {isSidebarOpened || isMobilePreviewOpen ? (
        <>
          <Layer />
          <PopupCloseIconState />
        </>
      ) : null}
    </>
  )
}

const mapStateToProps = state => ({
  isEnabled: selectEmailConfirmationIsEnabled(state),
  successMessage: selectEventSuccessMessage(state)
})

const mapDispatchToProps = {
  updateEventConfirmationEmailStatus,
  updateEventConfirmationEmailContent,
  setEventSuccessMessage
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationEmail)
