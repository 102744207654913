//@flow
import React, { memo } from 'react'
import LibInput from '@editor/common/lib/Input'
import { translate } from '@editor/common/utils/translations'
import TooltipWrapper from '@website/common/components/TooltipWrapper'

import SectionTitle from '../../../components/SectionTitle'
import SectionTitleWithCheckbox from '../../../components/SectionTitleWithCheckbox'
import { LABELS_VALIDATIONS } from '../../../consts'
import { Dropdown, Line, TooltipContent, AsteriskIcon } from '../styled'
import type { IStaticFieldsProps } from '../types'
import { dropdownList } from './consts'
import * as Styled from './styled'
import Validatable from '@website/common/lib/ValidatableHOC'

const VALIDATIONS = LABELS_VALIDATIONS.form

const Input = Validatable(LibInput)

const StaticFields = ({
  onDataChange,
  onRsvpOptionChange,
  data,
  formErrors,
  register
}: IStaticFieldsProps) => {
  const {
    rsvpOption,
    firstName,
    lastName,
    phone,
    email,
    guestBtn,
    additionalGuestNumber
  } = data
  const isPhoneRequired = phone.isRequired

  return (
    <Styled.Container>
      <SectionTitleWithCheckbox
        isChecked={rsvpOption.isVisible}
        label={translate('rsvp_option_label')}
        tooltipText={translate('rsvp_option_tooltip')}
        onChange={onDataChange('rsvpOption', 'isVisible')}
      />
      {rsvpOption.isVisible ? (
        <Styled.RSVPOptionForm>
          <Input
            isRequired={VALIDATIONS.rsvpOption.isRequired}
            label={translate('title_label')}
            value={rsvpOption.text}
            charLimit={VALIDATIONS.rsvpOption.charLimit}
            errorMessage={formErrors.rsvpOptionTitle}
            {...register({
              fieldName: 'rsvpOptionTitle',
              initialValue: rsvpOption.text,
              onChange: onDataChange('rsvpOption', 'text'),
              validationOptions: {
                isRequired: VALIDATIONS.rsvpOption.isRequired
              }
            })}
          />
          {rsvpOption.options.map(({ name, text }, i) => (
            <Input
              key={name}
              isRequired={VALIDATIONS[`rsvpOption_${name}`].isRequired}
              label={translate(`${name}_label`)}
              value={text}
              charLimit={VALIDATIONS[`rsvpOption_${name}`].charLimit}
              errorMessage={formErrors[`${name}RsvpOption`]}
              {...register({
                fieldName: `${name}RsvpOption`,
                initialValue: text,
                onChange: onRsvpOptionChange(i, 'text'),
                validationOptions: {
                  isRequired: VALIDATIONS[`rsvpOption_${name}`].isRequired
                }
              })}
            />
          ))}
        </Styled.RSVPOptionForm>
      ) : null}
      <Line />
      <SectionTitle
        label={translate('contact_form_title')}
        tooltipText={translate('contact_form_tooltip')}
      />
      <Input
        isRequired={VALIDATIONS.firstName.isRequired}
        label={translate('first_name_input_title_label')}
        value={firstName.text}
        charLimit={VALIDATIONS.firstName.charLimit}
        errorMessage={formErrors.firstName}
        {...register({
          fieldName: 'firstName',
          initialValue: firstName.text,
          onChange: onDataChange('firstName', 'text'),
          validationOptions: {
            isRequired: VALIDATIONS.firstName.isRequired
          }
        })}
      />
      <Input
        isRequired={VALIDATIONS.lastName.isRequired}
        label={translate('last_name_input_title_label')}
        value={lastName.text}
        charLimit={VALIDATIONS.lastName.charLimit}
        errorMessage={formErrors.lastName}
        {...register({
          fieldName: 'lastName',
          initialValue: lastName.text,
          onChange: onDataChange('lastName', 'text'),
          validationOptions: {
            isRequired: VALIDATIONS.lastName.isRequired
          }
        })}
      />
      <Input
        isRequired={VALIDATIONS.email.isRequired}
        label={translate('email_input_title_label')}
        value={email.text}
        charLimit={VALIDATIONS.email.charLimit}
        errorMessage={formErrors.email}
        {...register({
          fieldName: 'email',
          initialValue: email.text,
          onChange: onDataChange('email', 'text'),
          validationOptions: {
            isRequired: VALIDATIONS.email.isRequired
          }
        })}
      />
      <SectionTitleWithCheckbox
        isChecked={phone.isVisible}
        className="add-phone-number-label"
        label={translate('add_phone_number_label')}
        onChange={(e, value, isVisible) => {
          onDataChange('phone', 'isVisible')(isVisible)
          onDataChange('phone', 'isRequired')(isVisible)
        }}
      />
      {phone.isVisible ? (
        <Styled.InputWrapper>
          <Styled.RequiredControl
            isActive={isPhoneRequired}
            onClick={() =>
              onDataChange('phone', 'isRequired')(!isPhoneRequired)
            }
          >
            <TooltipWrapper
              className="tooltip"
              text={
                <TooltipContent>
                  {isPhoneRequired
                    ? translate('remove_required_label')
                    : translate('make_required_label')}
                </TooltipContent>
              }
            >
              <AsteriskIcon>*</AsteriskIcon>
            </TooltipWrapper>
          </Styled.RequiredControl>
          <Input
            isRequired={VALIDATIONS.phone.isRequired}
            label={translate('phone_number_input_title_label')}
            value={phone.text}
            charLimit={VALIDATIONS.phone.charLimit}
            errorMessage={formErrors.phone}
            {...register({
              fieldName: 'phone',
              initialValue: phone.text,
              onChange: onDataChange('phone', 'text'),
              validationOptions: {
                isRequired: VALIDATIONS.phone.isRequired
              }
            })}
          />
        </Styled.InputWrapper>
      ) : null}
      <Line />
      <SectionTitleWithCheckbox
        isChecked={guestBtn.isVisible}
        label={translate('additional_guest_option_label')}
        tooltipText={translate('additional_guest_option_tooltip')}
        onChange={onDataChange('guestBtn', 'isVisible')}
      />
      {guestBtn.isVisible ? (
        <>
          <Input
            isRequired={VALIDATIONS.guestBtn.isRequired}
            label={translate('link_button_title')}
            value={guestBtn.text}
            charLimit={VALIDATIONS.guestBtn.charLimit}
            errorMessage={formErrors.guestBtn}
            {...register({
              fieldName: 'guestBtn',
              initialValue: guestBtn.text,
              onChange: onDataChange('guestBtn', 'text'),
              validationOptions: {
                isRequired: VALIDATIONS.guestBtn.isRequired
              }
            })}
          />
          <Dropdown
            isSlideUpForMobile
            dropdownList={dropdownList}
            label={translate('additional_guests_label')}
            onListItemClick={onDataChange('additionalGuestNumber')}
            activeItem={{
              value: additionalGuestNumber,
              label: additionalGuestNumber,
              component: additionalGuestNumber
            }}
          />
        </>
      ) : null}
      <Line />
    </Styled.Container>
  )
}

export default memo(StaticFields)
