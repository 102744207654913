import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

export const NOTIFICATIONS_RADIO_DATA = [
  {
    label: translate('event_notifications_allowed_label'),
    value: 'true'
  },
  {
    label: translate('event_notifications_disallowed_label'),
    value: 'false'
  }
]

export const registrationTitle = translate('registration_status_title')
export const registrationSubTitle = translate('registration_status_subtitle')
export const notificationsTitle = translate('email_notifications_title')
export const notificationsSubTitle = translate('email_notifications_subtitle')
export const guestNumberTitle = translate('registration_limit_label')
export const guestNumberSubTitle = translate('registration_limit_subtitle')
export const deleteEventTitle = translate('delete_event_title')
export const deleteEventSubTitle = translate('delete_event_subtitle')

export const DELETE_EVENT_CONFIRMATION_TITLE = translate(
  'delete_event_confirmation_title'
)
export const DELETE_EVENT_DESCRIPTION = translate('delete_event_message_label')
export const DELETE_EVENT_NOTIFICATION_TEXT = transformTextVariables(
  translate('delete_event_warning_text'),
  [
    {
      value: translate('details_lowercase_label'),
      Comp: BoldComp
    },
    {
      value: translate('guest_list_lowercase_label'),
      Comp: BoldComp
    },
    {
      value: translate('rsvp_component_label'),
      Comp: BoldComp
    }
  ]
)

export const EMAIL_NOTIFICATION_LABEL = translate('enter_your_email_label')
export const EMAIL_NOTIFICATION_PLACEHOLDER = translate(
  'enter_your_email_label'
)

export const SETTINGS_MOBILE_UPGRADE_BANNER =
  'https://static.rfstat.com/renderforest/images/website_maker_images/event-settings-mobile-upgrade-banner.jpeg'
export const SETTINGS_UPGRADE_BANNER =
  'https://static.rfstat.com/renderforest/images/website_maker_images/event-settings-upgrade-banner.jpeg'
