//@flow

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import produce from 'immer'
import { RfLoader } from '@editor/common/components/Loaders'

import { useDiscardDialog } from '@hooks/useDiscardDialog'
import { translate } from '@editor/common/utils/translations'
import {
  selectTranslations,
  selectWebsiteLanguagesData,
  selectEventSuccessMessage,
  selectEventTranslationState
} from '@selectors'
import {
  updateEventTranslationData,
  getEventTranslationData,
  setEventSuccessMessage
} from '@actions'
import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'

import ContentTitle from '../../../components/ContentTitle'
import { TabContainer } from '../../styled'
import MainSectionContent from './Content'
import LanguagesDropdowns from './LanguagesDropdowns'
import { TMultilingualContentProps } from './types'
import { generateLangDropdownItem } from './utils'
import * as Styled from './styled'

const MultilingualContent = ({
  websiteLanguagesData,
  translations,
  updateEventTranslationData,
  setHasChangesInTab,
  closePopup,
  getEventTranslationData,
  successMessage,
  setEventSuccessMessage,
  isTranslationLoading
}: TMultilingualContentProps) => {
  const {
    params: { lang: websiteActiveLanguageCode }
  } = useRouteMatch()

  const defaultToLangCode = useMemo(() => {
    const language = websiteLanguagesData.find(
      data => data.lang.code !== websiteActiveLanguageCode
    )

    return language?.lang?.code
  }, [websiteLanguagesData])

  const [selectedFromLangCode, setSelectedFromLangCode] = useState(
    websiteActiveLanguageCode
  )

  const [selectedToLangCode, setSelectedToLangCode] =
    useState(defaultToLangCode)

  const initialToLangData = translations?.[selectedToLangCode]
  const [isLangDropdownsSticky, setLangDropdownsStickyState] = useState(false)

  const [toLangData, setToLangData] = useState(initialToLangData)

  const wrapperRef = useRef(null)
  const stickySectionRef = useRef(null)

  const hasChanges = useMemo(
    () =>
      toLangData && initialToLangData
        ? JSON.stringify(toLangData) !== JSON.stringify(initialToLangData)
        : false,
    [toLangData, initialToLangData]
  )

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  useEffect(() => {
    getEventTranslationData(selectedFromLangCode)
  }, [selectedFromLangCode])

  useEffect(() => {
    getEventTranslationData(selectedToLangCode)
  }, [selectedToLangCode])

  useEffect(() => {
    setToLangData(initialToLangData)
  }, [initialToLangData])

  const selectedFromLangData = useMemo(() => {
    return websiteLanguagesData.find(
      language => language.lang.code === selectedFromLangCode
    ).lang
  }, [selectedFromLangCode])

  const selectedToLangData = useMemo(() => {
    return websiteLanguagesData.find(
      language => language.lang.code === selectedToLangCode
    ).lang
  }, [selectedToLangCode])

  const translateLanguagesList = useMemo(() => {
    return websiteLanguagesData.reduce((acc, language) => {
      const { lang } = language

      if (
        lang.code !== selectedToLangData.code &&
        lang.code !== selectedFromLangData.code
      ) {
        acc.push(generateLangDropdownItem(lang))
      }
      return acc
    }, [])
  }, [selectedToLangData, selectedFromLangData, websiteLanguagesData])

  const onContentScroll = useCallback(() => {
    const wrapperScrollTop = wrapperRef?.current.scrollTop
    const stickyOffsetTop = stickySectionRef?.current?.offsetTop

    setLangDropdownsStickyState(wrapperScrollTop === stickyOffsetTop)
  }, [wrapperRef, stickySectionRef])

  const updateTranslations = useCallback((field, id, value) => {
    setToLangData(
      produce(draft => {
        draft[field][id] = value
      })
    )
  }, [])

  const onSave = useCallback(() => {
    updateEventTranslationData(selectedToLangCode, toLangData)
  }, [selectedToLangCode, toLangData, updateEventTranslationData])

  const { formErrors, register, handleSubmit } = useFormValidation(onSave)

  const handleToLangCodeChange = useDiscardDialog(setSelectedToLangCode, {
    id: 'translation',
    hasChanges
  })

  return (
    <TabContainer>
      <RfLoader isVisible={isTranslationLoading} />
      <Styled.Content ref={wrapperRef} onScroll={onContentScroll}>
        <ContentTitle
          withUnderline={false}
          title={translate('event_translation_label')}
          subTitle={translate('event_translation_subtitle')}
        />
        <LanguagesDropdowns
          stickySectionRef={stickySectionRef}
          isLangDropdownsSticky={isLangDropdownsSticky}
          translateLanguagesList={translateLanguagesList}
          toLangData={selectedToLangData}
          fromLangData={selectedFromLangData}
          onToLangClick={handleToLangCodeChange}
          onFromLangClick={setSelectedFromLangCode}
        />
        <Styled.MainSection>
          <MainSectionContent
            fromLangData={translations?.[selectedFromLangCode]}
            toLangData={toLangData}
            setData={updateTranslations}
            formErrors={formErrors}
            register={register}
          />
        </Styled.MainSection>
      </Styled.Content>
      <SaveAndDiscardFooter
        successMessage={successMessage}
        isSaveBtnDisabled={!hasChanges || isTranslationLoading}
        onDiscard={closePopup}
        onSave={handleSubmit}
        setSuccessMessage={setEventSuccessMessage}
      />
    </TabContainer>
  )
}

const mapStateToProps = state => ({
  translations: selectTranslations(state),
  websiteLanguagesData: selectWebsiteLanguagesData(state),
  successMessage: selectEventSuccessMessage(state),
  isTranslationLoading: selectEventTranslationState(state)
})

export default connect(mapStateToProps, {
  getEventTranslationData,
  updateEventTranslationData,
  setEventSuccessMessage
})(MultilingualContent)
