//@flow

import React, { memo, useCallback } from 'react'
import { translate } from '@editor/common/utils/translations'
import Switcher from '@editor/common/lib/Switcher'

import CommonTitleWrapper from './CommonTitleWrapper'
import { registrationTitle, registrationSubTitle } from './consts'
import type { TRegistrationStatusProps } from './types'
import * as Styled from './styled'

const RegistrationStatus = ({
  isRegistrationOpen,
  setData
}: TRegistrationStatusProps) => {
  const _setIsRegistrationOpen = useCallback(() => {
    setData('isRegistrationOpen', !isRegistrationOpen)
  }, [isRegistrationOpen])

  return (
    <>
      <CommonTitleWrapper
        title={registrationTitle}
        subTitle={registrationSubTitle}
      />
      <Styled.SwitcherWrapper isOpen={isRegistrationOpen}>
        <Styled.SwitcherLabel isActive={!isRegistrationOpen}>
          {translate('close_label')}
        </Styled.SwitcherLabel>
        <Switcher
          onChange={_setIsRegistrationOpen}
          size="small"
          className="status-switcher"
          isChecked={isRegistrationOpen}
        />
        <Styled.SwitcherLabel isActive={isRegistrationOpen}>
          {translate('open_label')}
        </Styled.SwitcherLabel>
      </Styled.SwitcherWrapper>
      <Styled.Line />
    </>
  )
}

export default memo(RegistrationStatus)
