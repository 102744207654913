//@flow

import React, { memo } from 'react'

import SectionTitle from '../../../components/SectionTitle'
import type { TCommonTitleWrapperProps } from './types'
import * as Styled from './styled'

const CommonTitleWrapper = ({
  title,
  subTitle,
  tooltipText
}: TCommonTitleWrapperProps) => (
  <Styled.Container>
    <SectionTitle label={title} tooltipText={tooltipText} />
    <Styled.SubTitle>{subTitle}</Styled.SubTitle>
  </Styled.Container>
)

export default memo(CommonTitleWrapper)
