//@flow
import React, { memo, useState, useCallback } from 'react'
import { getFlagUrl } from '@website/common/utils/website-languages'
import { translate } from '@editor/common/utils/translations'
import SlideUp from '@editor/common/lib/SlideUp'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_left.svg'
import ArrowIconFilled from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'

import * as Styled from './styled'
import type { TMobileDropdownProps } from './types'

const MobileDropdown = ({
  activeItem,
  langData,
  dropdownList,
  onListItemClick
}: TMobileDropdownProps) => {
  const [isSlideUpOpen, setSlideUpOpenState] = useState(false)

  const closeSlideUp = useCallback(() => {
    setSlideUpOpenState(false)
  }, [])

  const openSlideUp = useCallback(() => {
    setSlideUpOpenState(true)
  }, [])

  return (
    <Styled.MobileDropdownWrapper>
      <Styled.InputWrapper onClick={openSlideUp}>
        <Styled.Input isSlideUpOpen={isSlideUpOpen}>
          {activeItem.label} <ArrowIconFilled className="arrow-icon-filled" />
        </Styled.Input>
      </Styled.InputWrapper>
      <SlideUp isOpen={isSlideUpOpen} onClose={closeSlideUp}>
        <Styled.SlideUpContent>
          <Styled.SlideUpHeader onClick={closeSlideUp}>
            <ArrowIcon className="arrow-icon" />
            <Styled.ResponseTypeLabel>
              {translate('translate_from_label')}
            </Styled.ResponseTypeLabel>
          </Styled.SlideUpHeader>
          <Styled.DropdownItemsWrapper onClick={closeSlideUp}>
            {dropdownList.map(dropdownListItem => (
              <Styled.MobileItemWrapper
                onClick={() => onListItemClick(dropdownListItem.value)}
              >
                <Styled.CountryFlag
                  src={getFlagUrl(langData.flagCode)}
                  alt={langData.label}
                />
                <Styled.MobileDropdownItem
                  isActive={dropdownListItem.value === activeItem.value}
                >
                  {dropdownListItem.label}
                </Styled.MobileDropdownItem>
              </Styled.MobileItemWrapper>
            ))}
          </Styled.DropdownItemsWrapper>
        </Styled.SlideUpContent>
      </SlideUp>
    </Styled.MobileDropdownWrapper>
  )
}

export default memo(MobileDropdown)
