import styled, { css } from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'

export const LanguagesDropdownsWrapper = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  padding: 30px 25px 20px;
  justify-content: space-between;
  background-color: ${white};
  z-index: 1;
  ${({ isSticky }) =>
    isSticky
      ? css`
          z-index: 1;
          box-shadow: 0px 5px 15px rgb(56 125 255 / 15%);
        `
      : css``};

  .arrow-icon {
    fill: #8493bd;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 768px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 20px;
    padding-right: 40px;
    padding-left: 40px;
    border-top: 1px solid ${secondary.ExtraLight};
    img {
      display: none;
    }
  }
  @media only screen and (max-width: 650px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const ItemLanguageBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${secondary.SemiDark};

  & > img {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid ${secondary.ExtraLight};
  }
`
export const LanguagesDropdown = styled.div`
  width: 44%;
  position: relative;
  margin: 0 15px;

  .dropdown-wrapper,
  .dropdown-wrapper-active {
    padding-left: 50px;
    @media only screen and (max-width: 600px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
`
export const CountryFlag = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 9px;
  left: 15px;
  z-index: 1;
  margin-right: 15px;
  border-radius: 5px;
  border: 1px solid ${secondary.ExtraLight};
  @media only screen and (max-width: 768px) {
    position: static;
  }
`

export const LangNamesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LangNameWrapper = styled.div`
  position: relative;
  width: 44%;
  margin: 0 15px;
`

export const LangName = styled.div`
  border: 1px solid ${secondary.Light};
  border-radius: 5px;
  padding: 10px 0 10px 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${secondary.SemiDark};
  @media only screen and (max-width: 600px) {
    padding-left: 10px;
  }
`

export const SlideUpContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`
export const SlideUpHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px 40px;
  border-bottom: 1px solid ${secondary.Light};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  @media only screen and (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  .arrow-icon {
    position: absolute;
    transform: rotate(270deg);
    left: 40px;
    top: 20px;
    @media only screen and (max-width: 600px) {
      left: 20px;
    }
  }
`

export const ResponseTypeLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #252e48;
`

export const DropdownItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 20px;
  @media only screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const MobileDropdownItem = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${({ isActive }) => (isActive ? '#252E48' : '#545F7E')};
`

export const MobileDropdownWrapper = styled.div`
  width: 45%;
  display: flex;
  background-color: ${white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const MobileItemWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`

export const Input = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-shadow: none;
  height: 40px;
  max-height: unset;
  border-radius: 5px;
  padding: 0px 15px;
  box-sizing: border-box;
  border: 1px solid rgb(193, 201, 224);
  font-size: 14px;
  background-color: ${white};
  ${({ isSlideUpOpen }) =>
    isSlideUpOpen
      ? css``
      : css`
          .arrow-icon-filled {
            transform: rotate(180deg);
          }
        `};
  .arrow-icon-filled {
    position: absolute;
    right: 15px;
    transition: transform 0.3s;
  }
`
