export const EMPTY_DYNAMIC_FIELD_DATA = {
  text: '',
  isRequired: false,
  isVisible: true,
  isMultiple: false,
  options: []
}

export const SINGLE_FIELD_KEYS_TO_OMIT = ['options', 'isMultiple']

export const EMPTY_FIELD_OPTION_DATA = {
  text: 'Answer'
}

export const MAX_ALLOWED_OPTIONS_COUNT = 6
export const MIN_ALLOWED_OPTIONS_COUNT = 2
export const MAX_ALLOWED_PER_TYPE_FIELDS_COUNT = 5
export const ADDITIONAL_FIELD_OFFSET_TOP = 200
