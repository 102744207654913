//@flow

import React, { memo } from 'react'
import { getFlagUrl } from '@website/common/utils/website-languages'
import LibDropdown from '@editor/common/lib/Dropdown'

import { useResponsive } from '@shared/hooks'

import { generateLangDropdownItem } from '../utils'
import MobileDropdown from './MobileDropdown'
import * as Styled from './styled'
import { TDropdownProps } from './types'

const Dropdown = ({
  dropdownList,
  onListItemClick,
  langData
}: TDropdownProps) => {
  const isMobile = useResponsive(768)

  return (
    <>
      {isMobile ? (
        <MobileDropdown
          dropdownList={dropdownList}
          onListItemClick={onListItemClick}
          activeItem={generateLangDropdownItem(langData)}
          langData={langData}
        />
      ) : (
        <Styled.LanguagesDropdown>
          <LibDropdown
            dropdownList={dropdownList}
            onListItemClick={onListItemClick}
            activeItem={generateLangDropdownItem(langData)}
          />
          <Styled.CountryFlag
            src={getFlagUrl(langData.flagCode)}
            alt={langData.label}
          />
        </Styled.LanguagesDropdown>
      )}
    </>
  )
}

export default memo(Dropdown)
