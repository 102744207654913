//@flow
import React, { memo, useCallback, useEffect, useState } from 'react'

import AttentionIcon from '@editor/common/assets/svgr-icons/Attention_icon_red.svg'

import * as Styled from './styled'
import type { TCheckboxProps } from './types'

const Checkbox = ({
  isChecked,
  checkedItems: checkedItemsFromProps = [],
  className,
  errorMessage,
  data,
  onChange
}: TCheckboxProps) => {
  const [checkedItems, setCheckedItems] = useState(checkedItemsFromProps)

  const isList = data.length > 1

  useEffect(() => {
    if (
      JSON.stringify(checkedItemsFromProps) !== JSON.stringify(checkedItems)
    ) {
      setCheckedItems(checkedItemsFromProps)
    }
  }, [checkedItemsFromProps, checkedItems])

  const checkboxListChange = useCallback(
    (e, value, isChecked) => {
      const _checkedItems = isChecked
        ? [value, ...checkedItems]
        : checkedItems.filter(item => item !== value)

      onChange && onChange(_checkedItems)
      setCheckedItems(_checkedItems)
    },
    [checkedItems, onChange]
  )

  return isList ? (
    <Styled.CheckboxList className={className}>
      {data.map(dataItem => (
        <Styled.Checkbox
          key={dataItem.value}
          hasError={!!errorMessage}
          isChecked={checkedItems.includes(dataItem.value)}
          onChange={checkboxListChange}
          item={dataItem}
        />
      ))}
      {!!errorMessage ? (
        <Styled.ErrorMessage>
          <AttentionIcon />
          {errorMessage}
        </Styled.ErrorMessage>
      ) : null}
    </Styled.CheckboxList>
  ) : (
    <Styled.Checkbox
      className={className}
      isChecked={isChecked}
      onChange={onChange}
      item={data[0]}
    />
  )
}

export default memo(Checkbox)
