//@flow
import React, { memo, useCallback, useMemo } from 'react'
import LibInput from '@editor/common/lib/Input'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import MoveUpIcon from '@editor/common/assets/svgr-icons/move-up-desktop.svg'
import MoveDownIcon from '@editor/common/assets/svgr-icons/move-down-desktop.svg'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon.svg'
import Validatable from '@website/common/lib/ValidatableHOC'

import SectionTitleWithCheckbox from '../../../components/SectionTitleWithCheckbox'
import OptionsList from './OptionsList'

import {
  MAX_ALLOWED_PER_TYPE_FIELDS_COUNT,
  MAX_ALLOWED_OPTIONS_COUNT
} from '../consts'
import { LABELS_VALIDATIONS } from '../../../consts'

import { Dropdown, Line, TooltipContent, AsteriskIcon } from '../styled'
import type { TDynamicFieldsProps } from '../types'
import {
  CHECKBOX,
  INPUT,
  RADIO,
  RESPONSE_TYPES,
  RESPONSE_TYPES_LIST_ITEMS,
  RESPONSE_TYPES_WITH_OPTIONS
} from './consts'
import * as Styled from './styled'

const Input = Validatable(LibInput)
const VALIDATIONS = LABELS_VALIDATIONS.form

const DynamicFields = ({
  data,
  formErrors,
  register,
  onDeleteField,
  onSwapFields,
  onDataChange,
  addFieldOption,
  deleteFieldOption,
  addAdditionalField,
  onOptionsDataChange,
  onOptionsAnswerChange,
  onAdditionalFieldsVisibilityChange
}: TDynamicFieldsProps) => {
  const dataLength = data.length

  const responseTypes = useMemo(() => {
    const typesByCount = data.reduce((acc, item) => {
      const numValue = acc[item.type] || 0

      acc[item.type] = numValue + 1
      return acc
    }, {})

    return RESPONSE_TYPES_LIST_ITEMS.filter(
      item =>
        typesByCount[item.value] < MAX_ALLOWED_PER_TYPE_FIELDS_COUNT ||
        !typesByCount[item.value]
    )
  }, [data])

  const _addAdditionalField = useCallback(() => {
    const type =
      responseTypes[0]?.value === RADIO ? CHECKBOX : responseTypes[0]?.value
    addAdditionalField(type)
  }, [responseTypes])

  const isFieldsAllowed = useMemo(
    () => dataLength < MAX_ALLOWED_PER_TYPE_FIELDS_COUNT * 3,
    [dataLength]
  )

  const onDropdownItemChange = useCallback(
    (isMultiple, fieldIdx) => type => {
      const listItemData = {
        type: type === RADIO ? CHECKBOX : type,
        isMultiple: type === CHECKBOX
      }

      onOptionsDataChange(fieldIdx)(listItemData)
    },
    [onOptionsDataChange]
  )

  const getResponseTypes = useCallback(
    activeType => responseTypes.filter(item => item.value !== activeType),
    [responseTypes]
  )


  return (
    <Styled.Container>
      <SectionTitleWithCheckbox
        isChecked={!!dataLength}
        label={translate('additional_questions_label')}
        tooltipText={translate('additional_questions_tooltip')}
        onChange={onAdditionalFieldsVisibilityChange}
      />
      {dataLength ? (
        <>
          {data.map((field, fieldIdx) => {
            const { options, isRequired, isMultiple, type, text, id } = field
            const hasFieldOptions =
              RESPONSE_TYPES_WITH_OPTIONS.includes(field.type) && options
            const responseType =
              type === INPUT ? INPUT : isMultiple ? CHECKBOX : RADIO
            const respTypes = getResponseTypes(responseType)

            return (
              <div id={`field-${fieldIdx}`}>
                {fieldIdx !== 0 ? <Line /> : null}
                <Styled.AdditionalFieldsSubtitle>
                  {transformTextVariables(translate('question_label'), [
                    {
                      value: fieldIdx + 1
                    }
                  ])}
                  <Styled.IconsSection>
                    <TooltipWrapper
                      text={
                        <TooltipContent>
                          {isRequired
                            ? translate('remove_required_label')
                            : translate('make_required_label')}
                        </TooltipContent>
                      }
                    >
                      {responseType === RADIO ? null : (
                        <Styled.RequiredControl
                          isActive={isRequired}
                          onClick={() =>
                            onDataChange('isRequired', fieldIdx)(!isRequired)
                          }
                        >
                          <AsteriskIcon>*</AsteriskIcon>
                        </Styled.RequiredControl>
                      )}
                    </TooltipWrapper>
                    {fieldIdx !== dataLength - 1 ? (
                      <Styled.Icon
                        onClick={onSwapFields(fieldIdx, fieldIdx + 1)}
                      >
                        <TooltipWrapper text={translate('move_down_label')}>
                          <MoveDownIcon />
                        </TooltipWrapper>
                      </Styled.Icon>
                    ) : null}
                    {fieldIdx !== 0 ? (
                      <Styled.Icon
                        onClick={onSwapFields(fieldIdx, fieldIdx - 1)}
                      >
                        <TooltipWrapper text={translate('move_up_label')}>
                          <MoveUpIcon />
                        </TooltipWrapper>
                      </Styled.Icon>
                    ) : null}
                    <Styled.Icon onClick={onDeleteField(fieldIdx)}>
                      <TooltipWrapper text={translate('delete_label')}>
                        <DeleteIcon />
                      </TooltipWrapper>
                    </Styled.Icon>
                  </Styled.IconsSection>
                </Styled.AdditionalFieldsSubtitle>
                <Input
                  key={id}
                  isRequired={VALIDATIONS.question.isRequired}
                  label={translate('question_title_label')}
                  placeholder={translate('enter_a_question_text')}
                  value={text}
                  charLimit={VALIDATIONS.question.charLimit}
                  errorMessage={formErrors[`question${fieldIdx}`]}
                  {...register({
                    fieldName: `question${fieldIdx}`,
                    initialValue: text,
                    onChange: onDataChange('text', fieldIdx),
                    validationOptions: {
                      isRequired: VALIDATIONS.question.isRequired
                    }
                  })}
                />
                {isFieldsAllowed && respTypes.length ? (
                  <Dropdown
                    isSlideUpForMobile
                    dropdownList={respTypes}
                    label={translate('response_type_label')}
                    onListItemClick={onDropdownItemChange(isMultiple, fieldIdx)}
                    activeItem={RESPONSE_TYPES[responseType]}
                  />
                ) : (
                  <Styled.FieldInput>
                    {RESPONSE_TYPES[field.type].label}
                  </Styled.FieldInput>
                )}

                {hasFieldOptions ? (
                  <OptionsList
                    fieldIdx={fieldIdx}
                    options={options}
                    register={register}
                    formErrors={formErrors}
                    addFieldOption={addFieldOption}
                    onDataChange={onOptionsAnswerChange}
                    deleteFieldOption={deleteFieldOption}
                  />
                ) : null}
                {hasFieldOptions &&
                options?.length < MAX_ALLOWED_OPTIONS_COUNT ? (
                  <Styled.AnswerButton
                    type="tertiary"
                    onClick={addFieldOption(fieldIdx)}
                  >
                    {translate('add_answer_label')}
                  </Styled.AnswerButton>
                ) : null}
              </div>
            )
          })}
          {isFieldsAllowed ? (
            <>
              <Line />
              <Styled.Button type="tertiary" onClick={_addAdditionalField}>
                {translate('add_field_label')}
              </Styled.Button>
            </>
          ) : null}
        </>
      ) : null}
      <Line />
    </Styled.Container>
  )
}

export default memo(DynamicFields)
