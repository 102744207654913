import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  .checkbox-label {
    margin: 0;
    div {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: ${secondary.ExtraDark};

      :last-child {
        margin-right: 5px;
      }
    }
  }

  .info-icon-wrapper {
    display: flex;
    cursor: pointer;

    & > svg {
      fill: #8493bd;
    }
  }
`
