//@flow
import React, { memo } from 'react'
import { connect } from 'react-redux'
import { selectTranslationDataForUI } from '@selectors'

import { TABS_TRANSLATIONS } from './consts'
import SectionItem from './SectionItem'
import * as Styled from './styled'
import type { TContentProps } from './types'

const Content = ({
  toLangData,
  fromLangData,
  translationsForUI,
  setData,
  formErrors,
  register
}: TContentProps) => {
  if (!toLangData || !fromLangData) {
    return null
  }

  return (
    <Styled.Container>
      <Styled.Content>
        {Object.keys(translationsForUI).map(tabKey => {
          const translationByTab = translationsForUI[tabKey]

          return (
            <>
              <Styled.SectionHeader>
                {TABS_TRANSLATIONS[tabKey]}
              </Styled.SectionHeader>
              <Styled.SectionItemsGroup>
                {translationByTab.map(item => {
                  const toLangText =
                    toLangData[tabKey]?.[item.translationId] || ''
                  const fromLangText =
                    fromLangData[tabKey]?.[item.translationId] || ''

                  return item.isVisible ? (
                    <SectionItem
                      item={item}
                      tabKey={tabKey}
                      toLangText={toLangText}
                      fromLangText={fromLangText}
                      formErrors={formErrors}
                      register={register}
                      setData={setData}
                    />
                  ) : null
                })}
              </Styled.SectionItemsGroup>
            </>
          )
        })}
      </Styled.Content>
    </Styled.Container>
  )
}

const mapStateToProps = state => ({
  translationsForUI: selectTranslationDataForUI(state)
})

export default connect(mapStateToProps)(memo(Content))
