//@flow
import React, { memo, useCallback } from 'react'
import produce from 'immer'

import { getRsvpRadioButtonsListItems } from '@website/common/components/RSVPForm/utils'

import * as Styled from './styled'
import type { RSVPOptionsProps } from './types'

const RSVPOptions = ({
  isInEdit,
  rsvpOption,
  rsvpOptionAnswer,
  setFormData
}: RSVPOptionsProps) => {
  const onChange = useCallback(
    (e, value) => {
      const activeOption = rsvpOption.options.find(
        option => option.name === value
      )

      setFormData(
        produce(draft => {
          draft.rsvpOption.id = activeOption.id
          draft.rsvpOption.text = activeOption.text
          draft.rsvpOption.answer = activeOption.name
        })
      )
    },
    [rsvpOption]
  )

  return (
    <Styled.Section isInEdit={isInEdit}>
      <Styled.SectionTitle isInEdit={isInEdit}>
        {rsvpOption.text}
      </Styled.SectionTitle>
      <Styled.RSVPOptions
        onChange={onChange}
        checked={rsvpOptionAnswer}
        list={getRsvpRadioButtonsListItems(rsvpOption.options)}
      />
    </Styled.Section>
  )
}

export default memo(RSVPOptions)
