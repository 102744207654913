//@flow
import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'

import { setGuestsListAnswer, setGuestsListLanguage } from '@actions'
import {
  selectGuestsListFilters,
  selectGuestsCountsByAnswer,
  selectGuestsCountsByLanguage
} from '@selectors'

import { translate } from '@editor/common/utils/translations'
import SlideUp from '@editor/common/lib/SlideUp'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_left.svg'
import FiltersIcon from '@editor/common/assets/svgr-icons/filters.svg'

import AnswerDropdown from '../AnswerDropdown'
import LanguageDropdown from '../LanguageDropdown'

import * as Styled from './styled'
import type { TMobileDropdownProps } from './types'

const MobileFilters = ({
  isLanguageFilterVisible,
  guestsListFilters,
  guestsCountsByAnswer,
  guestsCountsByLanguage,
  setGuestsListAnswer,
  setGuestsListLanguage
}: TMobileDropdownProps) => {
  const [isSlideUpOpen, setSlideUpOpenState] = useState(false)

  const { answer, language } = guestsListFilters

  const [activeAnswer, setActiveAnswer] = useState(answer)
  const [activeLanguage, setActiveLanguage] = useState(language)

  const activeFiltersCount =
    (activeAnswer !== 'all') + (activeLanguage !== 'all')

  const openSlideUp = useCallback(() => {
    setSlideUpOpenState(true)
  }, [])

  const closeSlideUp = useCallback(() => {
    setSlideUpOpenState(false)
  }, [])

  const applyFilters = useCallback(() => {
    setGuestsListAnswer(activeAnswer)
    setGuestsListLanguage(activeLanguage)
    closeSlideUp()
  }, [activeAnswer, activeLanguage])

  const clearFilters = useCallback(() => {
    setGuestsListAnswer('all')
    setGuestsListLanguage('all')
    setActiveAnswer('all')
    setActiveLanguage('all')
  }, [activeAnswer, activeLanguage])

  return (
    <Styled.MobileFiltersContainer>
      <Styled.MobileFiltersWrapper onClick={openSlideUp}>
        <FiltersIcon />
      </Styled.MobileFiltersWrapper>
      <SlideUp isOpen={isSlideUpOpen} onClose={closeSlideUp}>
        <Styled.SlideUpContent>
          <Styled.SlideUpHeader onClick={closeSlideUp}>
            <ArrowIcon className="arrow-icon" />
            <Styled.ResponseTypeLabel>Filters</Styled.ResponseTypeLabel>
          </Styled.SlideUpHeader>
          <Styled.FiltersWrapper>
            <AnswerDropdown
              guestsCountsByAnswer={guestsCountsByAnswer}
              answer={activeAnswer}
              setGuestsListAnswer={setActiveAnswer}
            />
            {isLanguageFilterVisible ? (
              <LanguageDropdown
                guestsCountsByLanguage={guestsCountsByLanguage}
                language={activeLanguage}
                setGuestsListLanguage={setActiveLanguage}
              />
            ) : null}
          </Styled.FiltersWrapper>
          <Styled.ActionButtons>
            <Styled.ClearAllButton onClick={clearFilters} type="secondary">
              {translate('clear_all_label')}
              {activeFiltersCount ? (
                <span>{` (${activeFiltersCount})`}</span>
              ) : null}
            </Styled.ClearAllButton>
            <Styled.ApplyButton onClick={applyFilters} type="primary">
              {translate('apply_label')}
            </Styled.ApplyButton>
          </Styled.ActionButtons>
        </Styled.SlideUpContent>
      </SlideUp>
    </Styled.MobileFiltersContainer>
  )
}

const mapStateToProps = state => ({
  guestsListFilters: selectGuestsListFilters(state),
  guestsCountsByAnswer: selectGuestsCountsByAnswer(state),
  guestsCountsByLanguage: selectGuestsCountsByLanguage(state)
})

const mapDispatchToProps = {
  setGuestsListAnswer,
  setGuestsListLanguage
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileFilters)
