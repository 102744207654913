//@flow
import React, { useContext, memo } from 'react'

import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import EmailBody from '@renderforest/email-body-generator'

import MobilePreviewHeader from '../../MobilePreviewHeader'
import { TextHeader } from '../styled'
import type { TPreviewContentProps } from './types'
import * as Styled from './styled'

const Preview = ({ onClose, ...rest }: TPreviewContentProps) => {
  const isMobile = useContext(MobileDetectContext)

  return (
    <Styled.PreviewContent>
      {isMobile ? (
        <MobilePreviewHeader onClick={onClose} />
      ) : (
        <TextHeader>{translate('preview_label')}</TextHeader>
      )}
      <Styled.PreviewWrapper>
        <EmailBody {...rest} />
      </Styled.PreviewWrapper>
      <Styled.PreviewFooter>
        {translate('email_upgrade_banner_title')}
        <Styled.UpgradeButton />
      </Styled.PreviewFooter>
    </Styled.PreviewContent>
  )
}

export default memo(Preview)
