import styled from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'
import Button from '@editor/common/lib/Button'

export const MobileFiltersContainer = styled.div`
  display: flex;
  background-color: ${white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const SlideUpHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px 40px;
  border-bottom: 1px solid ${secondary.Light};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  @media only screen and (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  .arrow-icon {
    position: absolute;
    transform: rotate(270deg);
    left: 40px;
    top: 20px;
    @media only screen and (max-width: 600px) {
      left: 20px;
    }
  }
`

export const ResponseTypeLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #252e48;
`

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 20px;
  @media only screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  > div:nth-child(2) {
    margin-top: 20px;
  }
  .search-dropdown-options-wrapper {
    max-height: 120px;
    overflow: scroll;
  }
`

export const SlideUpContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  padding-bottom: 190px;
`

export const MobileFiltersWrapper = styled.div`
  height: 40px;
  width: 40px;
  margin-left: 10px;
  background-color: ${white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border: 1px solid ${secondary.Light};
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  & > svg {
    fill: #8493bd;
  }
`

export const ActionButtons = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  column-gap: 40px;
  border-top: 1px solid ${secondary.Light};
  background-color: ${white};
  @media only screen and (max-width: 650px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const ClearAllButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
`

export const ApplyButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 500;
`
