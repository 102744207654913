import styled from 'styled-components'

const YES_COLOR = '#2A8735'
const NO_COLOR = '#D64040'

const getColorByAnswer = ({ answer }) =>
  answer === 'yes' ? YES_COLOR : NO_COLOR

export const Answer = styled.span`
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 3px;
  border: 1px solid ${getColorByAnswer};
  color: ${getColorByAnswer};
`
