// @flow

import React, { memo, useCallback, useContext } from 'react'
import CrownIcon from '@editor/common/assets/svgr-icons/crown.svg'
import { translate } from '@editor/common/utils/translations'
import { UpgradePopupContext } from '@contexts'
import { UpgradeButton as StyledUpgradeButton } from './styled'

type TUpgradeButtonProps = { className?: string, referrer?: string }

const UpgradeButton = ({ className = '', referrer }: TUpgradeButtonProps) => {
  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const handleUpgradeClick = useCallback(() => {
    setUpgradePopupState({ isOpen: true, ...(!!referrer && { referrer }) })
  }, [])

  return (
    <StyledUpgradeButton
      type="purple"
      onClick={handleUpgradeClick}
      className={className}
    >
      <CrownIcon />
      {translate('upgrade_label')}
    </StyledUpgradeButton>
  )
}

export default memo(UpgradeButton)
