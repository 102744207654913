//@flow
import React, { useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'
import { deleteEvent } from '@actions'
import { useHistoryWithSearch } from '@hooks'
import { ConfirmDialogContext } from '@contexts/ConfirmDialog.context'
import { PAGE_BASE } from '@editor/conf/routes'

import {
  deleteEventTitle,
  deleteEventSubTitle,
  DELETE_EVENT_CONFIRMATION_TITLE,
  DELETE_EVENT_DESCRIPTION,
  DELETE_EVENT_NOTIFICATION_TEXT
} from './consts'
import CommonTitleWrapper from './CommonTitleWrapper'
import * as Styled from './styled'
import type { TDeleteButtonProps } from './types'

const DeleteEvent = ({ deleteEvent }: TDeleteButtonProps) => {
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(PAGE_BASE)
  const { setProps } = useContext(ConfirmDialogContext)

  const navigateToEditor = useCallback(() => {
    history.push(url)
  }, [history, url])

  const handleConfirm = useCallback(() => {
    setProps({ isOpen: false })
    deleteEvent(navigateToEditor)
  }, [navigateToEditor, setProps])

  const onDeleteBtnClick = useCallback(() => {
    setProps({
      isOpen: true,
      isWarning: true,
      title: DELETE_EVENT_CONFIRMATION_TITLE,
      content: DELETE_EVENT_NOTIFICATION_TEXT,
      description: DELETE_EVENT_DESCRIPTION,
      buttonTexts: {
        cancel: translate('cancel_label'),
        confirm: translate('delete_label', true)
      },
      onConfirm: handleConfirm
    })
  }, [setProps, handleConfirm])

  return (
    <>
      <CommonTitleWrapper
        title={deleteEventTitle}
        subTitle={deleteEventSubTitle}
      />
      <Styled.DeleteButton type="tertiary" onClick={onDeleteBtnClick}>
        {translate('delete_label')}
      </Styled.DeleteButton>
    </>
  )
}

export default connect(null, { deleteEvent })(DeleteEvent)
