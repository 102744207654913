//@flow
import React, { Fragment, memo, useCallback } from 'react'
import produce from 'immer'

import LibInput from '@editor/common/lib/Input'
import Validatable from '@website/common/lib/ValidatableHOC'
import { getCheckboxComponentItems } from '@website/common/components/RSVPForm/utils'
import { translate } from '@editor/common/utils/translations'
import { LABELS_VALIDATIONS } from '@containers/edit/containers/event/consts'

import * as Styled from './styled'
import type { TAdditionalInformationProps } from './types'

const Input = Validatable(LibInput)
const CheckboxList = Validatable(Styled.CheckboxList)
const VALIDATIONS = LABELS_VALIDATIONS.form

const AdditionalInformation = ({
  dynamicFields,
  formData,
  setFormData,
  formErrors,
  register
}: TAdditionalInformationProps) => {
  const setField = useCallback(
    (value, idx) => {
      setFormData(
        produce(draft => {
          draft.dynamicFields[idx].answer = value
        })
      )
    },
    [setFormData]
  )

  const onCheckboxListChange = useCallback(
    (checkedItems, idx, field) => {
      const selectedOptions = field.options.filter(option =>
        checkedItems.includes(option.id)
      )

      setField(selectedOptions, idx)
    },
    [setField]
  )

  const onRadioButtonListChange = useCallback(
    (value, idx, field) => {
      const selectedOption = field.options.find(option => option.id === value)

      setField([selectedOption], idx)
    },
    [setField]
  )

  const onInputChange = useCallback(
    (value, idx) => {
      setField(value, idx)
    },
    [setField]
  )

  return (
    <Styled.AdditionalInformation>
      <Styled.SectionTitle>
        {translate('additional_information_label')}
      </Styled.SectionTitle>
      {dynamicFields.map((dynamicField, idx) => {
        const question = `${dynamicField.text} ${
          dynamicField.isRequired ? '*' : ''
        }`
        const answer = formData.dynamicFields?.[idx]?.answer

        return (
          <Fragment key={idx}>
            {dynamicField.type === 'checkbox' && dynamicField.isMultiple ? (
              <Styled.AdditionalField>
                <Styled.Question>{question}</Styled.Question>
                <CheckboxList
                  checkedItems={answer?.map(item => item.id)}
                  data={getCheckboxComponentItems(dynamicField.options)}
                  errorMessage={formErrors[`checkbox-list-${idx}`]}
                  {...register({
                    fieldName: `checkbox-list-${idx}`,
                    initialValue: answer?.map(item => item.id),
                    onChange: checkedItems =>
                      onCheckboxListChange(checkedItems, idx, dynamicField),
                    validationOptions: {
                      ...(dynamicField.isRequired
                        ? {
                            checkboxList: {
                              validator: value => !!value.length,
                              errorMessage: translate(
                                'this_field_cannot_be_empty_label'
                              )
                            }
                          }
                        : {})
                    }
                  })}
                />
              </Styled.AdditionalField>
            ) : dynamicField.type === 'checkbox' ? (
              <Styled.AdditionalField>
                <Styled.Question>{dynamicField.text}</Styled.Question>
                <Styled.RadioBtnList
                  list={getCheckboxComponentItems(dynamicField.options)}
                  checked={answer[0].id}
                  onChange={(e, value) =>
                    onRadioButtonListChange(value, idx, dynamicField)
                  }
                  className="radio-btn-list"
                />
              </Styled.AdditionalField>
            ) : (
              <Styled.AdditionalField isTextInput>
                <Styled.Question>{question}</Styled.Question>
                <Input
                  isRequired={dynamicField.isRequired}
                  value={answer}
                  placeholder={translate('enter_answer_placeholder')}
                  charLimit={VALIDATIONS.question.charLimit}
                  errorMessage={
                    formErrors[`${dynamicField.type}-dynamicField-${idx}`]
                  }
                  {...register({
                    fieldName: `${dynamicField.type}-dynamicField-${idx}`,
                    initialValue: answer,
                    onChange: value => onInputChange(value, idx),
                    validationOptions: {
                      isRequired: dynamicField.isRequired
                    }
                  })}
                />
              </Styled.AdditionalField>
            )}
          </Fragment>
        )
      })}
    </Styled.AdditionalInformation>
  )
}

export default memo(AdditionalInformation)
