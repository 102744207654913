// @flow

import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { EditingContext } from '@contexts'
import { getVisualParams } from '@selectors'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TPreviewProps } from './types'

const Preview = ({ children }: TPreviewProps) => {
  const visualParams = useSelector(getVisualParams)

  return (
    <Styled.PreviewContainer>
      <Styled.PreviewHeader>{translate('preview_label')}</Styled.PreviewHeader>
      <ThemeProvider 
        theme={{
          look: visualParams.look,
          palette: visualParams.palette,
          fontSize: visualParams.textSize,
          primaryFont: visualParams.primaryFont,
          secondaryFont: visualParams.secondaryFont
        }}
      >
        <EditingContext.Provider value={{ isEditing: false }}>
          {children}
        </EditingContext.Provider>
      </ThemeProvider>
    </Styled.PreviewContainer>
  )
}

export default memo(Preview)
