import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDetailsData, selectRegretEmailData } from '@selectors'

import {
  EMAIL_DEFAULT_GREETING,
  REGRET_EMAIL_DEFAULT_MESSAGE
} from '../../consts'

const useInitialData = () => {
  const detailsData = useSelector(selectDetailsData)
  const regretEmailData = useSelector(selectRegretEmailData)

  return useMemo(
    () =>
      regretEmailData || {
        title: { text: detailsData.title.text },
        subject: { text: detailsData.title.text },
        message: { text: REGRET_EMAIL_DEFAULT_MESSAGE },
        greeting: { text: EMAIL_DEFAULT_GREETING }
      },
    [regretEmailData, detailsData]
  )
}
export default useInitialData
