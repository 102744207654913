import { translate } from '@editor/common/utils/translations'

export const TABLE_META = [
  {
    columnName: 'lang',
    className: 'language-column',
    label: translate('lang_label'),
    isSortable: false
  },
  {
    columnName: 'date',
    className: 'date-column',
    label: translate('date_label'),
    isSortable: true
  },
  {
    columnName: 'full_name',
    className: 'full-name-column',
    label: translate('full_name_label'),
    isSortable: false
  },
  {
    columnName: 'rsvp',
    className: 'rsvp-column',
    label: translate('rsvp_label'),
    isSortable: false
  },
  {
    columnName: 'additional_guests',
    className: 'additional-guests-column',
    label: translate('additional_guests_label'),
    isSortable: false
  },
  {
    columnName: 'contacts',
    className: 'contacts-column',
    label: translate('contacts_label'),
    isSortable: false
  },
  {
    columnName: 'actions',
    className: 'actions-column',
    label: translate('actions_label'),
    isSortable: false
  }
]

export const NO_RESULT_IMAGE_URL = 'https://static.rfstat.com/renderforest/images/website_maker_images/no_results.png'
export const NO_GUESTS_IMAGE_URL = 'https://static.rfstat.com/renderforest/images/website_maker_images/no_guests.png'