//@flow
import React, { memo } from 'react'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import type { TSectionTitleProps } from './types'
import * as Styled from './styled'

const SectionTitle = ({
  label,
  tooltipText,
  tooltipPosition,
  tooltipWithClick,
  className
}: TSectionTitleProps) => (
  <Styled.SectionTitleWrapper className={className}>
    <Styled.Title>{label}</Styled.Title>
    {tooltipText ? (
      <TooltipWrapper
        className="info-icon-wrapper"
        text={tooltipText}
        position={tooltipPosition}
        withClick={tooltipWithClick}
      >
        <InfoIcon />
      </TooltipWrapper>
    ) : null}
  </Styled.SectionTitleWrapper>
)

export default memo(SectionTitle)
