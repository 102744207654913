import styled, { css } from 'styled-components'

import { white, secondary } from '@website/common/styles/colors'

import { ButtonWithIcon } from '../styled'
export const saveDiscardFooterStyles = css`
  height: 65px;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-left: 24px;
  padding-right: 24px;

  > div:last-child {
    flex-direction: row-reverse;
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`
export const EditButton = styled(ButtonWithIcon)`
  & > svg {
    width: 20px;
    margin-right: 8px;
  }
`
export const MobileTextHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid ${secondary.Light};
  padding: 15px 20px;
  box-sizing: border-box;

  & > svg {
    fill: ${secondary.Normal};
    margin-right: 15px;
  }
`

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  height: 100%;

  .save-cancel-footer {
    ${saveDiscardFooterStyles};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
export const SidebarMainContent = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
`

export const EmailBlock = styled.div`
  display: flex;
  justify-content: space-between;

  .switcher {
    transform: scale(0.9);
  }
`
