//@flow

import React, { memo } from 'react'
import { getFlagUrl } from '@website/common/utils/website-languages'
import ArrowRightIcon from '@editor/common/assets/svgr-icons/rsvp/arrow_right.svg'
import Dropdown from './Dropdown'
import * as Styled from './styled'
import { TLanguagesDropdownsProps } from './types'

const LanguagesDropdowns = ({
  stickySectionRef,
  onToLangClick,
  onFromLangClick,
  fromLangData,
  toLangData,
  translateLanguagesList,
  isLangDropdownsSticky
}: TLanguagesDropdownsProps) => {
  return (
    <Styled.LanguagesDropdownsWrapper
      ref={stickySectionRef}
      isSticky={isLangDropdownsSticky}
    >
      {!translateLanguagesList.length ? (
        <Styled.LangNamesWrapper>
          <Styled.LangNameWrapper>
            <Styled.LangName>{fromLangData.name}</Styled.LangName>
            <Styled.CountryFlag
              src={getFlagUrl(fromLangData.flagCode)}
              alt={fromLangData.label}
            />
          </Styled.LangNameWrapper>
          <ArrowRightIcon className="arrow-icon" />
          <Styled.LangNameWrapper>
            <Styled.LangName>{toLangData.name}</Styled.LangName>
            <Styled.CountryFlag
              src={getFlagUrl(toLangData.flagCode)}
              alt={toLangData.label}
            />
          </Styled.LangNameWrapper>
        </Styled.LangNamesWrapper>
      ) : (
        <>
          <Dropdown
            dropdownList={translateLanguagesList}
            onListItemClick={onFromLangClick}
            langData={fromLangData}
          />
          <ArrowRightIcon className="arrow-icon" />
          <Dropdown
            dropdownList={translateLanguagesList}
            onListItemClick={onToLangClick}
            langData={toLangData}
          />
        </>
      )}
    </Styled.LanguagesDropdownsWrapper>
  )
}

export default memo(LanguagesDropdowns)
