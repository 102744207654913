//@flow
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'

import LibInput from '@editor/common/lib/Input'
import { translate } from '@editor/common/utils/translations'
import { useHistoryWithSearch } from '@hooks'
import Validatable from '@website/common/lib/ValidatableHOC'

import { LABELS_VALIDATIONS } from '../../consts'
import { LeftSection as StyledLeftSection } from '../styled'
import { DateBlock, LocationBlock, TimezoneBlock } from './components'
import * as Styled from './styled'
import type { TLeftSectionProps } from './types'

const VALIDATIONS = LABELS_VALIDATIONS.details

const Input = Validatable(LibInput)

export const LeftSection = ({
  data,
  formErrors,
  register,
  setData
}: TLeftSectionProps) => {
  const leftSectionRef = useRef(null)
  const history = useHistoryWithSearch()
  const activeInputClassName = history.location.state?.activeInputClassName

  useEffect(() => {
    const leftSection = leftSectionRef.current
    const elem =
      leftSection && leftSection.querySelector(`.${activeInputClassName}`)

    if (!elem || !activeInputClassName) return

    elem.focus()
    const length = elem.value.length
    elem.setSelectionRange(length, length)
  }, [activeInputClassName, leftSectionRef])

  const updateData = useCallback((key, updatedChildData) => {
    setData(data => ({
      ...data,
      [key]: { ...data[key], ...updatedChildData }
    }))
  }, [])

  const onInputChange = key => value => updateData(key, { text: value })

  const dateInfo = useMemo(
    () => ({
      startDateInfo: data.startDateInfo,
      endDateInfo: data.endDateInfo
    }),
    [data.startDateInfo, data.endDateInfo]
  )

  return (
    <StyledLeftSection ref={leftSectionRef}>
      <Styled.InputsContainer>
        <LibInput
          isRequired={VALIDATIONS.subTitle.isRequired}
          label={translate('subtitle_label')}
          value={data.subTitle?.text}
          charLimit={VALIDATIONS.subTitle.charLimit}
          className="subTitle"
          onChange={onInputChange('subTitle')}
        />
        <Input
          isRequired={VALIDATIONS.title.isRequired}
          label={translate('title_label')}
          value={data.title.text}
          charLimit={VALIDATIONS.title.charLimit}
          errorMessage={formErrors.title}
          className="title"
          {...register({
            fieldName: 'title',
            initialValue: data.title.text,
            onChange: onInputChange('title'),
            validationOptions: {
              isRequired: VALIDATIONS.title.isRequired
            }
          })}
        />
        <LibInput
          isTextArea
          isRequired={VALIDATIONS.paragraph.isRequired}
          label={translate('paragraph_label')}
          className="paragraph"
          value={data.paragraph.text}
          charLimit={VALIDATIONS.paragraph.charLimit}
          onChange={onInputChange('paragraph')}
        />
        <TimezoneBlock data={data.timezone} updateData={updateData} />
        <DateBlock data={dateInfo} updateData={updateData} />
      </Styled.InputsContainer>
      <LocationBlock
        data={data.location}
        updateData={updateData}
        formErrors={formErrors}
        register={register}
      />
      <Input
        isRequired={VALIDATIONS.button.isRequired}
        label={translate('rsvp_button_title')}
        value={data.button.text}
        charLimit={VALIDATIONS.button.charLimit}
        errorMessage={formErrors.button}
        className="rsvp-button"
        {...register({
          fieldName: 'button',
          initialValue: data.button.text,
          onChange: onInputChange('button'),
          validationOptions: {
            isRequired: VALIDATIONS.button.isRequired
          }
        })}
      />
    </StyledLeftSection>
  )
}

export default memo(LeftSection)
