import { useSelector } from 'react-redux'

import { getPalette, selectDetailsData } from '@selectors'
import { GUEST_FULL_NAME } from './consts'
import { useMemo } from 'react'

const usePreviewData = isRegret => {
  const detailsData = useSelector(selectDetailsData)
  const palette = useSelector(getPalette)

  const {
    startDateInfo: { time: startDateTime, date: startDate },
    endDateInfo: {
      isVisible: isEndDateVisible,
      time: ednDateTime,
      date: endDate
    },
    timezone: { isVisible: isTimezoneVisible, value },
    location: { isVisible: isLocationVisible, name }
  } = detailsData

  return useMemo(
    () => ({
      guestFullName: GUEST_FULL_NAME,
      ...(!isRegret && {
        dateInfo: {
          startTime: startDateTime,
          startDate: startDate,
          timeZone: isTimezoneVisible ? value : '',
          endTime: isEndDateVisible ? ednDateTime : '',
          endDate: isEndDateVisible ? endDate : ''
        },
        location: {
          name: isLocationVisible ? name : ''
        }
      }),
      emailSettings: {
        color: palette.primaryDark
      }
    }),
    [isRegret]
  )
}
export default usePreviewData
