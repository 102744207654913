//@flow
import React, { memo, useCallback, useMemo, useEffect } from 'react'

import Checkbox from '@editor/common/lib/Checkbox'
import Button from '@editor/common/lib/Button'
import { translate } from '@editor/common/utils/translations'

import { SELECT_ALL_CHECKBOX_DATA } from './consts'
import * as Styled from './styled'
import type { TProps } from './types'
import { getCheckboxData } from './utils'

const CSVPopupContent = ({
  data,
  selectedLanguages,
  setSelectedLanguages,
  onCancel,
  onDownload
}: TProps) => {
  const allLanguages = useMemo(() => data.map(({ code }) => code), [data])
  const isDownloadBtnDisabled = !selectedLanguages.length

  useEffect(() => {
    setSelectedLanguages(allLanguages)
  }, [allLanguages])

  const onAllCheckboxChange = useCallback(
    (e, value, isChecked) => {
      if (isChecked) {
        setSelectedLanguages(allLanguages)
        return
      }

      setSelectedLanguages([])
    },
    [allLanguages, setSelectedLanguages]
  )

  const onDownloadBtnClick = useCallback(() => {
    if (isDownloadBtnDisabled) return

    onDownload()
  }, [isDownloadBtnDisabled, onDownload])

  return (
    <>
      <Styled.Header>
        <Styled.Title>{translate('download_csv_popup_title')}</Styled.Title>
        <Styled.Description>
          {translate('download_csv_popup_description')}
        </Styled.Description>
      </Styled.Header>
      <Styled.Main>
        <Checkbox
          isChecked={data.length === selectedLanguages.length}
          className="checkbox-all"
          data={SELECT_ALL_CHECKBOX_DATA}
          onChange={onAllCheckboxChange}
        />
        <Checkbox
          className="checkbox-list"
          data={getCheckboxData(data)}
          checkedItems={selectedLanguages}
          onChange={setSelectedLanguages}
        />
      </Styled.Main>
      <Styled.Footer>
        <Button type="secondary" onClick={onCancel}>
          {translate('cancel_label')}
        </Button>
        <Button
          type="primary"
          onClick={onDownloadBtnClick}
          disabled={isDownloadBtnDisabled}
        >
          {translate('download_label', true)}
        </Button>
      </Styled.Footer>
    </>
  )
}

export default memo(CSVPopupContent)
