//@flow
import React, { memo, Fragment } from 'react'
import { getFlagUrl } from '@website/common/utils/website-languages'

import { translate } from '@editor/common/utils/translations'
import moment from 'moment'

import * as Styled from './styled'
import type { TGuestDetailsProps } from './types'

const GuestDetails = ({ data, isLangColumnVisible }: TGuestDetailsProps) => {
  const { language, createdAt, email, phone, additionalGuests, dynamicFields } =
    data || {}

  return (
    <Styled.Container>
      <Styled.Header>
        {isLangColumnVisible ? (
          <Styled.Flag src={getFlagUrl(language.flagCode)} />
        ) : null}
        <Styled.Date isLangColumnVisible={isLangColumnVisible}>
          {moment(createdAt).format('DD.MM.YYYY')}
        </Styled.Date>
      </Styled.Header>
      <Styled.MainContent>
        <Styled.BlockContent>
          <Styled.BlockTitle>{translate('email_label')}</Styled.BlockTitle>
          <Styled.BlockText>{email}</Styled.BlockText>
          <Styled.BlockTitle>{translate('phone_label')}</Styled.BlockTitle>
          <Styled.BlockText>{phone}</Styled.BlockText>
        </Styled.BlockContent>
        <Styled.BlockHeader>
          {translate('additional_guests_label')}
        </Styled.BlockHeader>
        <Styled.BlockContent>
          {additionalGuests && additionalGuests.length ? (
            <Styled.List>
              {additionalGuests.map((additionalGuest, idx) => (
                <Styled.ListItem key={idx}>
                  {additionalGuest.firstName} {additionalGuest.lastName}
                </Styled.ListItem>
              ))}
            </Styled.List>
          ) : (
            <Styled.NotFountMessage>
              {translate('no_additional_guests')}
            </Styled.NotFountMessage>
          )}
        </Styled.BlockContent>
        <Styled.BlockContent className="dynamic-fields-content">
          {dynamicFields && dynamicFields.length ? (
            dynamicFields.map((dynamicField, idx) => {
              const answer = dynamicField.answer

              return (
                <Fragment key={idx}>
                  <Styled.BlockTitle>{dynamicField.text}</Styled.BlockTitle>
                  <Styled.BlockText key={idx}>
                    {Array.isArray(answer)
                      ? answer.map(answer => answer.text).join(', ')
                      : answer}
                  </Styled.BlockText>
                </Fragment>
              )
            })
          ) : (
            <Styled.NotFountMessage>
              {translate('no_information')}
            </Styled.NotFountMessage>
          )}
        </Styled.BlockContent>
      </Styled.MainContent>
    </Styled.Container>
  )
}

export default memo(GuestDetails)
