import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'
export const From = styled.div`
  padding: 20px;
  width: 100%;

  @media only screen and (min-width: 769px) {
    width: 420px;

    border-left: 1px solid ${secondary.Light};
    border-right: 1px solid ${secondary.Light};
  }

  .input-container {
    margin-bottom: 25px;
    & .error-message {
      @media only screen and (max-width: 568px) {
        max-width: 100% !important;
      }
    }
  }
`
