// @flow

import React, { memo, useMemo } from 'react'
import * as Styled from './styled'
import { getLanguageDropdownList } from './utils'

const LanguageDropdown = ({
  guestsCountsByLanguage,
  language,
  setGuestsListLanguage
}) => {
  const languageDropdownList = useMemo(
    () => getLanguageDropdownList(guestsCountsByLanguage),
    [guestsCountsByLanguage]
  )

  const filteredLanguageDropdownList = useMemo(
    () => languageDropdownList.filter(lang => lang.value !== language),
    [languageDropdownList, language]
  )

  const languageDropdownActiveItem = useMemo(
    () => languageDropdownList.find(lang => lang.value === language),
    [language, languageDropdownList]
  )

  return (
    <Styled.Dropdown
      dropdownList={filteredLanguageDropdownList}
      activeItem={languageDropdownActiveItem}
      onListItemClick={setGuestsListLanguage}
    />
  )
}

export default memo(LanguageDropdown)
