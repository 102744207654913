import styled from 'styled-components'
import LibInput from '@editor/common/lib/Input'
import { Radio as LibRadio } from '@editor/common/lib/Radio'
import { secondary } from '@website/common/styles/colors'
import CommonButton from '@editor/common/lib/Button'
import _InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import _UpgradeBanner from '../../../components/UpgradeBanner'

const grayColor = '#8493BD'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`

export const SubTitle = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: ${secondary.SemiDark};
`

export const Content = styled.div`
  padding: 30px 40px;
  box-sizing: border-box;
  overflow-y: auto;
  @media only screen and (max-width: 1024px) {
    max-width: 768px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const EmailNotifications = styled.div`
  .input-container {
    width: 335px;
    margin: 15px 0 25px 0;
  }
`

export const SwitcherWrapper = styled.div`
  display: flex;
  margin: 30px 0;

  .status-switcher {
    zoom: 0.9;
    margin: 0 10px;
    background-color: ${({ isOpen }) => (isOpen ? '#3271E6' : grayColor)};
  }
`

export const SwitcherLabel = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ isActive }) =>
    isActive ? '${secondary.ExtraDark}' : '${secondary.SemiDark}'};
`

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${secondary.ExtraLight};
  margin: 20px 0 30px;
  z-index: 2;
`
export const RadioWrapper = styled.div`
  margin-top: 20px;
  & > div {
    flex-direction: row;
    & > label:first-child {
      margin-right: 30px;
    }
  }
`

export const Radio = styled(LibRadio)`
  &:first-child {
    margin-right: 45px;
  }
`

export const GuestNumberInputWrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  box-sizing: border-box;
  margin: 30px 0px;
  background-color: rgb(255, 255, 255);
  @media only screen and (max-width: 600px) {
    width: 100%;
    .input-container {
      width: 100%;
    }
  }
`

export const Input = styled(LibInput)`
  border-radius: 5px;
  width: 335px;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
  }
  @media only screen and (max-width: 457px) {
    width: 270px;
  }
`

export const IconButton = styled.div`
  &:first-child {
    svg {
      width: 16px;
    }

    border-bottom: 1px solid rgb(221, 227, 240);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 31px;
  width: 31px;
  height: 20px;
  svg {
    fill: ${grayColor};
  }

  &:hover {
    svg {
      fill: ${secondary.SemiDark};
    }
  }
`

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 25px;
  display: flex;
  flex-direction: column;
  min-width: 31px;
  height: 38px;
  overflow: hidden;
  border-radius: 0px 3px 3px 0px;
  border-left: 1px solid rgb(221, 227, 240);
`
export const DeleteButton = styled(CommonButton)`
  font-weight: 600;
  padding: 12px 30px;
  margin: 30px 0 10px;
  background: rgba(255, 76, 76, 0.1);
  color: #ff4545;

  &:hover {
    background: linear-gradient(180deg, #ff706b 0%, #ff4141 100%);
  }
`
export const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${secondary.ExtraLight};
  margin-bottom: 30px;
  user-select: none;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const UpgradeBanner = styled(_UpgradeBanner)`
  max-width: 610px;
  margin-left: 33px;
  margin-bottom: 30px;

  @media only screen and (max-width: 1024px) {
    margin-left: 0;
    margin-right: auto;
  }
`

export const BannerContent = styled.div`
  margin-right: auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
    label,
    input {
      width: 100%;
    }
  }
`

export const InfoIcon = styled(_InfoIcon)`
  fill: #8493bd;
  width: 20px;
  height: auto;
`
