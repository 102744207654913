import styled, { css } from 'styled-components'

import { secondary } from '@website/common/styles/colors'

const titleStyles = css`
  font-size: 14px;
  font-weight: 600;
  color: ${secondary.SemiDark};
`

const textStyles = css`
  font-size: 14px;
  color: ${secondary.SemiDark};
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f8fbff;
  border-radius: 5px;
  padding: 0 12px 20px 12px;
  box-sizing: border-box;
  transition: height 300ms ease;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${secondary.Light};
  box-sizing: border-box;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const Flag = styled.img`
  width: 16px;
  display: block;
  height: auto;
  border-radius: 2px;
  margin-right: 20px;
  position: relative;
  margin-right: 20px;
`

export const Date = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${secondary.ExtraDark};
  margin-right: 30px;
  ${({ isLangColumnVisible }) =>
    isLangColumnVisible
      ? css`
          border-left: 1px solid ${secondary.Light};
          padding-left: 20px;
        `
      : ''}
`

export const MainContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  & > div:first-child {
    border-top: unset;
  }
`

export const BlockHeader = styled.div`
  color: ${secondary.SemiDark};
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid ${secondary.ExtraLight};
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
`

export const BlockTitle = styled.p`
  ${titleStyles};
  margin-bottom: 10px;
  font-weight: 500;
`

export const BlockText = styled.p`
  ${textStyles};
`

export const BlockContent = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  &.dynamic-fields-content {
    border-top: 1px solid ${secondary.ExtraLight};
  }
  & > ${BlockText}:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-reset: list-item;
`

export const ListItem = styled.li`
  ${textStyles};

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &::before {
    ${titleStyles};
    content: counter(list-item) '.';
    margin-right: 10px;
  }
`

export const NotFountMessage = styled.p`
  font-size: 14px;
  color: #8493bd;
`
