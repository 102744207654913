// @flow

import React, { memo } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { translate } from '@editor/common/utils/translations'
import { Tabs } from '@editor/common/components/Tabs'
import HorizontalScrollWrapper from '@editor/common/components/HorizontalScrollWrapper'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import { useHistoryWithSearch } from '@hooks'
import { useResponsive } from '@shared/hooks'

import {
  MENU_METADATA,
  MOBILE_TABS_DATA,
  LEAVE_TAB_DIALOG_BUTTONS
} from './consts'
import * as Styled from './styled'
import type { TSidebarMenuProps } from './types'

const getActiveItem = pathname => pathname.split('/')[6]

const SidebarMenu = ({ hasChangesInTab }: TSidebarMenuProps) => {
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistoryWithSearch()

  const isTabletOrMobile = useResponsive(1024)

  const activeItem = getActiveItem(pathname)

  const changeRoute = route => {
    history.push(`${url}${route}`)
  }

  const handleItemClick = useDiscardDialog(
    changeRoute,
    {
      id: 'event',
      hasChanges: hasChangesInTab
    },
    {
      title: translate('settings_tab_changing_popup_label'),
      buttonTexts: LEAVE_TAB_DIALOG_BUTTONS
    }
  )

  const getActiveTab = pathname => {
    return pathname.split('/')[6]
  }

  const activeTab = getActiveTab(pathname)

  return (
    <>
      {!isTabletOrMobile ? (
        <ul>
          {MENU_METADATA.map(({ label, icon: IconComp, route }, idx) => {
            const isActive = `/${activeItem}` === route
            return (
              <Styled.MenuItem
                key={idx}
                onClick={() => {
                  if (isActive) return
                  handleItemClick(route)
                }}
                isActive={isActive}
              >
                <div>
                  <IconComp />
                  <span>{label}</span>
                </div>
              </Styled.MenuItem>
            )
          })}
        </ul>
      ) : (
        <HorizontalScrollWrapper scrollToId={activeTab}>
          <Tabs
            className="rsvp-menu"
            activeTab={activeTab}
            data={MOBILE_TABS_DATA}
            setActiveTab={tab => handleItemClick(`/${tab}`)}
          />
        </HorizontalScrollWrapper>
      )}
    </>
  )
}

export default memo(SidebarMenu)
