import styled from 'styled-components'

import { secondary, white } from '@website/common/styles/colors'

export const PreviewContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0 3px 12px 0 rgba(50, 113, 230, 0.1);
  overflow: hidden;

  & > * {
    pointer-events: none;
  }

  & > div:nth-child(2) {
    zoom: 0.8;
    width: auto;
    height: 100%;
    max-width: 100%;
    border-radius: unset;
    border: unset;
    &.rsvp-5-container {
      overflow: hidden;
      & > div:nth-child(1) {
        display: none;
      }
      & > div:nth-child(2) {
        max-width: 100%;
        border-radius: 0;
      }
    }
    &.rsvp-6-container {
      & > div:nth-child(1) {
        display: none;
      }
      & > div:nth-child(2) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
      & > div:nth-child(3) {
        display: none;
      }
    }
    .card-img-wrapper {
      > div {
        &:after {
          border-radius: unset;
        }
      }
      img {
        border-radius: unset;
      }
    }
  }
`

export const PreviewHeader = styled.div`
  height: 40px;
  padding: 10px 30px;
  background-color: ${white};
  border-bottom: 1px solid ${secondary.Light};
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  color: ${secondary.ExtraDark};
`
