import styled, { css } from 'styled-components'

import { secondary } from '@website/common/styles/colors'

const titleStyles = css`
  font-size: 14px;
  font-weight: 600;
  color: ${secondary.ExtraDark};
`

const textStyles = css`
  font-size: 14px;
  color: ${secondary.SemiDark};
`

export const Container = styled.div`
  height: 100%;
  width: 380px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid ${secondary.Light};
  box-sizing: border-box;
`

export const FullName = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${secondary.ExtraDark};
  margin-right: 30px;
`

export const MainContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  & > div:first-child {
    border-top: unset;
  }
`

export const BlockHeader = styled.div`
  background-color: #f8fbff;
  color: ${secondary.SemiDark};
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid ${secondary.ExtraLight};
  border-bottom: 1px solid ${secondary.ExtraLight};
  padding: 10px 30px;
  box-sizing: border-box;
`

export const BlockTitle = styled.p`
  ${titleStyles};
  margin-bottom: 10px;
`

export const BlockText = styled.p`
  ${textStyles};
`

export const BlockContent = styled.div`
  padding: 15px 30px;

  & > ${BlockText}:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-reset: list-item;
`

export const ListItem = styled.li`
  ${textStyles};

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &::before {
    ${titleStyles};
    content: counter(list-item) '.';
    margin-right: 10px;
  }
`

export const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${secondary.Light};
  box-sizing: border-box;
`

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  color: ${secondary.SemiDark};
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
  background-color: unset;
  border: none;
  outline: none;

  & > svg {
    display: flex;
    width: 24px;
    height: 24px;
    fill: ${secondary.SemiDark};
    margin: 0 5px 0 3px;
  }

  &:hover {
    color: ${secondary.ExtraDark};

    & > svg {
      fill: ${secondary.ExtraDark};
    }
  }
`

export const NotFountMessage = styled.p`
  font-size: 14px;
  color: #8493bd;
`
