/* @flow */
import React, { memo } from 'react'

import DatePickerInput from './DatePickerInput'
import TimePickerInput from './TimePickerInput'
import type { TDatePickerInputsProps } from './types'
import * as Styled from './styled'

const DatePickerInputs = ({
  date,
  time,
  isDateRequired,
  isTimeRequired,
  onDateSelect,
  onTimeSelect
}: TDatePickerInputsProps) => (
  <Styled.DatePickerInputs>
    <DatePickerInput
      date={date}
      onDateSelect={onDateSelect}
      isRequired={isDateRequired}
    />
    <TimePickerInput
      time={time}
      onTimeSelect={onTimeSelect}
      isRequired={isTimeRequired}
    />
  </Styled.DatePickerInputs>
)

export default memo(DatePickerInputs)
