//@flow
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

import {
  selectDetailsData,
  selectEventSuccessMessage,
  selectWebsiteLanguagesData
} from '@selectors'
import { updateEventData, setEventSuccessMessage } from '@actions'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'
import { useResponsive } from '@shared/hooks'

import ContentTitle from '../../components/ContentTitle'
import MobilePreviewButton from '../MobilePreviewButton'
import MobilePreviewHeader from '../MobilePreviewHeader'
import { TabContainer, Content, MainSection, PreviewSlideLeft } from '../styled'
import LeftSection from './LeftSection'
import RightSection from './RightSection'
import type { TDetailsProps } from './types'

const Details = ({
  detailsData,
  websiteLanguagesData,
  successMessage,
  updateEventData,
  setHasChangesInTab,
  closePopup,
  setEventSuccessMessage
}: TDetailsProps) => {
  const {
    params: { lang: websiteActiveLanguageCodeFromRoute }
  } = useRouteMatch()

  const [data, setData] = useState(detailsData)

  const hasChanges = JSON.stringify(data) !== JSON.stringify(detailsData)

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  useEffect(() => {
    setData(detailsData)
  }, [detailsData])

  const notificationMessage = useMemo(() => {
    const activeLangData =
      websiteLanguagesData.find(
        languageData =>
          languageData.lang.code === websiteActiveLanguageCodeFromRoute
      ) || {}

    return transformTextVariables(translate('no_rsvp_component_message'), [
      {
        value: activeLangData.lang?.name || '',
        Comp: BoldComp
      },
      {
        value: translate('rsvp_component_label'),
        Comp: BoldComp
      }
    ])
  }, [websiteLanguagesData, websiteActiveLanguageCodeFromRoute])

  const onSave = useCallback(() => {
    const _data = {
      ...data,
      location: {
        ...data.location,
        name: data.location.name || detailsData.location.name
      }
    }

    updateEventData(_data, 'details')
  }, [data, detailsData, updateEventData])

  const isTabletOrMobile = useResponsive(1024)
  const [isMobilePreviewOpen, setMobilePreviewState] = useState(false)

  const { formErrors, register, handleSubmit } = useFormValidation(onSave)

  return (
    <TabContainer>
      <Content>
        <ContentTitle
          title={translate('event_details_title')}
          subTitle={translate('event_details_subtitle')}
        />
        <MainSection>
          <LeftSection
            data={data}
            formErrors={formErrors}
            register={register}
            setData={setData}
          />
          {isTabletOrMobile && !isMobilePreviewOpen ? (
            <MobilePreviewButton onClick={() => setMobilePreviewState(true)} />
          ) : null}
          {!isTabletOrMobile ? (
            <RightSection
              data={data}
              notificationMessage={notificationMessage}
            />
          ) : null}
        </MainSection>
      </Content>
      <SaveAndDiscardFooter
        successMessage={successMessage}
        isSaveBtnDisabled={!hasChanges}
        onDiscard={closePopup}
        onSave={handleSubmit}
        setSuccessMessage={setEventSuccessMessage}
      />
      <PreviewSlideLeft isOpen={isMobilePreviewOpen && isTabletOrMobile}>
        <MobilePreviewHeader onClick={() => setMobilePreviewState(false)} />
        <RightSection data={data} notificationMessage={notificationMessage} />
      </PreviewSlideLeft>
    </TabContainer>
  )
}

const mapStateToProps = state => ({
  detailsData: selectDetailsData(state),
  successMessage: selectEventSuccessMessage(state),
  websiteLanguagesData: selectWebsiteLanguagesData(state)
})

const mapDispatchToProps = { updateEventData, setEventSuccessMessage }

export default connect(mapStateToProps, mapDispatchToProps)(memo(Details))
