// @flow

import React, { memo } from 'react'
import { translate } from '@editor/common/utils/translations'
import LibInput from '@editor/common/lib/Input'
import Checkbox from '@editor/common/lib/Checkbox'

import { useResponsive } from '@shared/hooks'

import Validatable from '@website/common/lib/ValidatableHOC'

import * as Styled from './styled'
import { LABELS_VALIDATIONS } from '../../consts'
import {
  CHECKBOX_DATA,
  TABS_DESCRIPTIONS,
  YES,
  TABS_LABLES,
  REGISTRATION_CLOSED_DESCRIPTION
} from './consts'
import type { TActiveContentProps } from './types'

const VALIDATIONS = LABELS_VALIDATIONS.popups

const Input = Validatable(LibInput)

const ActiveContent = ({
  data,
  isVisible,
  tabName,
  formErrors,
  register,
  onInputChange,
  onCheckBoxChange
}: TActiveContentProps) => {
  const isTabletOrMobile = useResponsive(1024)
  return (
    <Styled.ActiveContent isVisible={isVisible}>
      {isTabletOrMobile ? (
        <Styled.SectionTitle
          tooltipWithClick
          label={TABS_LABLES[tabName]}
          tooltipText={TABS_DESCRIPTIONS[tabName]}
          tooltipPosition="right"
        />
      ) : (
        <Styled.Description>{TABS_DESCRIPTIONS[tabName]}</Styled.Description>
      )}
      {tabName === 'closed' ? (
        <Styled.ResigtrationClosedMessage>
          {REGISTRATION_CLOSED_DESCRIPTION}
        </Styled.ResigtrationClosedMessage>
      ) : null}
      <Input
        isRequired={VALIDATIONS[`${tabName}_title`].isRequired}
        label={translate('title_label')}
        value={data.title.text}
        charLimit={VALIDATIONS[`${tabName}_title`].charLimit}
        errorMessage={formErrors[`${tabName}_title`]}
        {...register({
          fieldName: `${tabName}_title`,
          initialValue: data.title.text,
          onChange: onInputChange('title', tabName),
          validationOptions: {
            isRequired: VALIDATIONS[`${tabName}_title`].isRequired
          }
        })}
      />
      <LibInput
        isTextArea
        isRequired={VALIDATIONS[`${tabName}_paragraph`].isRequired}
        label={translate('paragraph_label')}
        className="paragraph-text-area"
        value={data.paragraph.text}
        charLimit={VALIDATIONS[`${tabName}_paragraph`].charLimit}
        onChange={onInputChange('paragraph', tabName)}
      />
      {tabName === YES ? (
        <Styled.CheckboxContainer>
          <Checkbox
            data={CHECKBOX_DATA}
            className="add-calendar-checkbox"
            isChecked={data.showButton}
            onChange={onCheckBoxChange}
          />
          {data.showButton ? (
            <Input
              isRequired={VALIDATIONS.yes_buttonText.isRequired}
              label={translate('button_text_label')}
              value={data.buttonText.text}
              charLimit={VALIDATIONS.yes_buttonText.charLimit}
              errorMessage={formErrors['yes_buttonText']}
              {...register({
                fieldName: 'yes_buttonText',
                initialValue: data.buttonText.text,
                onChange: onInputChange('buttonText', 'yes'),
                validationOptions: {
                  isRequired: VALIDATIONS.yes_buttonText.isRequired
                }
              })}
            />
          ) : null}
        </Styled.CheckboxContainer>
      ) : null}
    </Styled.ActiveContent>
  )
}

export default memo(ActiveContent)
