//@flow
import React, { memo } from 'react'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon.svg'
import LibInput from '@editor/common/lib/Input'
import Validatable from '@website/common/lib/ValidatableHOC'

import { LABELS_VALIDATIONS } from '../../../consts'
import { MIN_ALLOWED_OPTIONS_COUNT } from '../consts'
import * as Styled from './styled'
import type { TOptionsListProps } from './types'

const VALIDATIONS = LABELS_VALIDATIONS.form

const Input = Validatable(LibInput)

const OptionsList = ({
  options,
  fieldIdx,
  register,
  formErrors,
  onDataChange,
  deleteFieldOption
}: TOptionsListProps) => (
  <>
    {options.map((option, idx) => {
      const fieldName = `optionsAnswer${option.id}`
      return (
        <Styled.AnswerWrapper key={option.id}>
          <Input
            isRequired={VALIDATIONS.answer.isRequired}
            label={transformTextVariables(translate('answer_label'), [
              {
                value: idx + 1
              }
            ])}
            placeholder={translate('enter_a_answer_text')}
            value={option.text || ''} // the check will be removed after the all required datas sending
            charLimit={VALIDATIONS.answer.charLimit}
            errorMessage={formErrors[fieldName]}
            {...register({
              fieldName: fieldName,
              initialValue: option.text,
              onChange: onDataChange('text', fieldIdx, idx),
              validationOptions: {
                isRequired: VALIDATIONS.answer.isRequired
              }
            })}
          />
          {options.length <= MIN_ALLOWED_OPTIONS_COUNT ? null : (
            <Styled.Icon
              onClick={deleteFieldOption(fieldIdx, idx)}
              className="answer-delete-icon"
            >
              <TooltipWrapper text={translate('delete_label')}>
                <DeleteIcon />
              </TooltipWrapper>
            </Styled.Icon>
          )}
        </Styled.AnswerWrapper>
      )
    })}
  </>
)

export default memo(OptionsList)
