import styled from 'styled-components'

import { white, secondary } from '@website/common/styles/colors'

import { SMALL_SIZES_BREAKPOINT } from './consts'

export const Footer = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-top: 1px solid ${secondary.Light};
  background: ${white};
  border-radius: 0 0 10px 10px;
  padding-left: 30px;
  padding-right: 30px;
  flex-shrink: 0;
  @media only screen and (max-width: ${SMALL_SIZES_BREAKPOINT}px) {
    justify-content: center;
  }
`

export const SavingInfo = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${secondary.SemiDark};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  max-width: 430px;
  svg {
    margin-right: 10px;
  }
  @media only screen and (max-width: ${SMALL_SIZES_BREAKPOINT}px) {
    display: none;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  & > button {
    height: 40px;
    padding: 11px 30px;
    margin-right: 10px;
  }

  .save-btn {
    text-transform: uppercase;
  }
`
