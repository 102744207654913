import styled, { css } from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'
import LibDropdown from '@editor/common/lib/Dropdown'
import LibCheckbox from '@editor/common/lib/Checkbox'
import { Content as CommonContent } from '../styled'

export const Content = styled(CommonContent)`
  overflow: auto;
  @media only screen and (max-width: 1024px) {
    padding-bottom: 60px;
  }
  .input-container {
    margin-top: 20px;
  }

  .form-preview {
    width: auto !important;
  }

  .input-container .error-message {
    @media only screen and (max-width: 568px) {
      max-width: 100% !important;
    }
  }
`

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${secondary.Light};
  margin: 30px 0;
  z-index: 2;
`

export const Checkbox = styled(LibCheckbox)`
  margin: 20px 0 0;

  & > div:nth-child(2) {
    color: ${secondary.ExtraDark};
  }
`

export const Dropdown = styled(LibDropdown)`
  margin-top: 20px;

  label {
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 3px 0px;
    color: ${secondary.SemiDark};
  }
`

export const InputWrapper = styled.div`
  display: flex;
  position: relative;

  .input-container {
    width: 100%;
    margin-top: 20px;
  }
`

export const InputControl = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 1;
  margin: 0 1px;
  width: 24px;
  height: 24px;
  border: 1px solid ${secondary.Light};
  border-radius: 3px;
  background: ${white};
  fill: #8493bd;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 10px;
  }

  :hover {
    cursor: pointer;
    fill: ${secondary.SemiDark};
  }
`

export const RequiredControl = styled(InputControl)`
  align-items: center;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${secondary.SemiDark};
          &:hover {
            color: ${secondary.SemiDark};
          }
        `
      : css`
          color: #8493bd;
        `}
`

export const AsteriskIcon = styled.span`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export const TooltipContent = styled.p`
  width: 110px;
  text-align: center;
`
