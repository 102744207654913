//@flow
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { connect } from 'react-redux'

import { setEventSuccessMessage, updateEventEmailSettings } from '@actions'
import { MobileDetectContext } from '@contexts'
import { RSVP_EMAIL_DEFAULT_SENDER_NAME } from '@editor/conf/consts'
import LibInput from '@editor/common/lib/Input'
import { translate } from '@editor/common/utils/translations'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import CloseIcon from '@editor/common/assets/svgr-icons/cancel.svg'
import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import {
  selectEmailSettings,
  selectEventSuccessMessage,
  selectUserEmail,
  selectWebsiteEmail
} from '@selectors'
import Validatable, {
  useFormValidation
} from '@website/common/lib/ValidatableHOC'

import { PopupCloseIconState } from '../../../styled'
import { SlideLeft, Layer, TextHeader, ContentTitle } from '../../styled'
import { EditButton, MobileTextHeader } from '../styled'
import * as Styled from './styled'
import type { TProps } from './types'

const Input = Validatable(LibInput)

const EmailSettings = ({
  emailSettings: { senderName: senderNameFromProps, replyTo: replyToFromProps },
  websiteEmail,
  userEmail,
  successMessage,
  setEventSuccessMessage,
  setHasChangesInTab,
  updateEventEmailSettings
}: TProps) => {
  const senderNameInitialState = useMemo(
    () => senderNameFromProps || RSVP_EMAIL_DEFAULT_SENDER_NAME,
    [senderNameFromProps]
  )

  const replyToInitialState = useMemo(
    () => replyToFromProps || websiteEmail || userEmail,
    [replyToFromProps, websiteEmail, userEmail]
  )

  const [isSidebarOpened, setSidebarOpenedState] = useState(false)
  const [senderName, setSenderName] = useState(senderNameInitialState)
  const [replyTo, setReplyTo] = useState(replyToInitialState)

  const isMobile = useContext(MobileDetectContext)

  const hasChanges =
    senderName !== senderNameInitialState || replyTo !== replyToInitialState

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  const openSidebar = useCallback(() => {
    setSidebarOpenedState(true)
  }, [])

  const onClose = useCallback(() => {
    setSidebarOpenedState(false)
    setSenderName(senderNameInitialState)
    setReplyTo(replyToInitialState)
    successMessage && setEventSuccessMessage('')
  }, [senderNameInitialState, replyToInitialState, successMessage])

  const closeSidebar = useDiscardDialog(onClose, {
    id: 'email-settings-sidebar',
    hasChanges
  })

  const onSave = useCallback(() => {
    updateEventEmailSettings({ senderName, replyTo })
  }, [senderName, replyTo])

  const { register, formErrors, handleSubmit } = useFormValidation(onSave)

  return (
    <>
      <ContentTitle
        title={translate('email_settings_title')}
        subTitle={translate('email_settings_subtitle')}
        withUnderline={false}
        hasPaddings={false}
      />
      <EditButton onClick={openSidebar}>
        <EditIcon />
        {translate('edit_label')}
      </EditButton>
      <SlideLeft
        isOpen={isSidebarOpened}
        onClose={closeSidebar}
        shouldCloseOnBackdropClick
        backdropDisablingSelectors={['.global-error-popup']}
      >
        <Styled.SidebarContent>
          {isMobile ? (
            <MobileTextHeader>
              <CloseIcon onClick={closeSidebar} />
              {translate('email_settings_title')}
            </MobileTextHeader>
          ) : (
            <TextHeader>{translate('email_settings_title')}</TextHeader>
          )}
          <Styled.MainContent>
            <ContentTitle
              title={translate('sender_name_label')}
              subTitle={translate('sender_name_description')}
              withUnderline={false}
              hasPaddings={false}
            />
            <Input
              isRequired
              label={translate('sender_name_label')}
              value={senderName}
              charLimit={40}
              errorMessage={formErrors.senderName}
              {...register({
                fieldName: 'senderName',
                initialValue: senderName,
                onChange: setSenderName,
                validationOptions: {
                  isRequired: true
                }
              })}
            />
            <Styled.Divider />
            <ContentTitle
              title={translate('reply_to_email_label')}
              subTitle={translate('reply_to_email_description')}
              withUnderline={false}
              hasPaddings={false}
            />
            <Styled.ReplyToInputWrapper>
              <Input
                isRequired
                label={translate('enter_your_email_label')}
                value={replyTo}
                errorMessage={formErrors.replyTo}
                {...register({
                  fieldName: 'replyTo',
                  initialValue: replyTo,
                  onChange: setReplyTo,
                  validationOptions: {
                    isRequired: true,
                    isEmail: true
                  }
                })}
              />
            </Styled.ReplyToInputWrapper>
          </Styled.MainContent>
          <SaveAndDiscardFooter
            className="save-cancel-footer"
            discardBtnText={translate('cancel_label')}
            isSaveBtnDisabled={!hasChanges}
            successMessage={successMessage}
            onDiscard={closeSidebar}
            onSave={handleSubmit}
            setSuccessMessage={setEventSuccessMessage}
          />
        </Styled.SidebarContent>
      </SlideLeft>
      {isSidebarOpened ? (
        <>
          <Layer />
          <PopupCloseIconState />
        </>
      ) : null}
    </>
  )
}
const mapStateToProps = state => ({
  emailSettings: selectEmailSettings(state),
  websiteEmail: selectWebsiteEmail(state),
  userEmail: selectUserEmail(state),
  successMessage: selectEventSuccessMessage(state)
})

const mapDispatchToProps = { updateEventEmailSettings, setEventSuccessMessage }
export default connect(mapStateToProps, mapDispatchToProps)(EmailSettings)
