import styled from 'styled-components'

export const SearchInput = styled.div`
  position: relative;

  .search-icon {
    position: absolute;
    left: 15px;
    bottom: 11px;
    z-index: 1;
  }

  input {
    padding-left: 40px;
  }
`
