//@flow
import React, { memo, useCallback } from 'react'
import produce from 'immer'
import LibInput from '@editor/common/lib/Input'
import { translate } from '@editor/common/utils/translations'
import Validatable from '@website/common/lib/ValidatableHOC'

import SectionTitle from '../../components/SectionTitle'
import { LABELS_VALIDATIONS } from '../../consts'
import { LeftSection as StyledLeftSection } from '../styled'
import { MAX_ALLOWED_OPTIONS_COUNT } from './consts'
import DynamicFields from './DynamicFields'
import { INPUT, RESPONSE_TYPES_WITH_OPTIONS } from './DynamicFields/consts'
import StaticFields from './StaticFields'
import type { TLeftSectionProps } from './types'
import { getEmptyDynamicField, getEmptyFieldOption } from './utils'

const VALIDATIONS = LABELS_VALIDATIONS.form
const Input = Validatable(LibInput)

const LeftSection = ({
  formErrors,
  register,
  setStaticFields,
  setDynamicFields,
  dynamicFields,
  staticFields,
  dynamicFieldsRef
}: TLeftSectionProps) => {
  const onStaticFieldChange = useCallback(
    (field, key) => (_value, e, optionalVal) => {
      const targetValue =
        typeof optionalVal === 'undefined'
          ? typeof _value !== 'undefined'
            ? _value
            : e?.target?.value
          : optionalVal

      setStaticFields(
        produce(draft => {
          draft[field] = key
            ? { ...draft[field], [key]: targetValue }
            : targetValue
        })
      )
    },
    [setStaticFields]
  )

  const onStaticRsvpOptionChange = useCallback(
    (index, key) => value => {
      setStaticFields(
        produce(draft => {
          draft.rsvpOption.options[index][key] = value
        })
      )
    },
    [setStaticFields]
  )

  const onDynamicFieldChange = useCallback(
    (index, fieldObj = {}) => {
      setDynamicFields(
        produce(draft => {
          draft[index] = { ...draft[index], ...fieldObj }
          draft[index].options = draft[index].options?.length
            ? draft[index].options
            : [getEmptyFieldOption(), getEmptyFieldOption()]
        })
      )
    },
    [setDynamicFields]
  )

  const onDynamicFieldOptionTypeChange = useCallback(
    index => fieldObj => {
      onDynamicFieldChange(index, fieldObj)
    },
    [onDynamicFieldChange]
  )

  const onDynamicFieldDataChange = useCallback(
    (key, index) => value => {
      onDynamicFieldChange(index, { [key]: value })
    },
    [onDynamicFieldChange]
  )

  const onDynamicFieldAnswerChange = useCallback(
    (key, fieldIdx, idx) => value => {
      setDynamicFields(
        produce(draft => {
          draft[fieldIdx].options[idx][key] = value
        })
      )
    },
    [setDynamicFields]
  )

  const addAdditionalField = useCallback(
    type => {
      const data = [...dynamicFields, { ...getEmptyDynamicField(), type }]
      if (RESPONSE_TYPES_WITH_OPTIONS.includes(type)) {
        data[data.length - 1].options = [
          getEmptyFieldOption(),
          getEmptyFieldOption()
        ]
      }

      setDynamicFields(data)
    },
    [dynamicFields]
  )

  const onSwapFields = useCallback(
    (indexFrom, indexTo) => () => {
      const updatedFields = [...dynamicFields]

      ;[updatedFields[indexFrom], updatedFields[indexTo]] = [
        updatedFields[indexTo],
        updatedFields[indexFrom]
      ]
      setDynamicFields(updatedFields)

      const elem = document.getElementById(`field-${indexTo}`)

      if (elem) {
        elem.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    },
    [dynamicFields]
  )

  const onDeleteField = useCallback(
    index => () => {
      const updatedFields = [...dynamicFields]
      updatedFields.splice(index, 1)
      setDynamicFields(updatedFields)
    },
    [dynamicFields]
  )

  const deleteFieldOption = useCallback(
    (fieldIdx, answerIdx) => () => {
      const options = dynamicFields[fieldIdx].options

      if (!options || !options.length) {
        return
      }

      setDynamicFields(
        produce(draft => {
          draft[fieldIdx].options.splice(answerIdx, 1)
        })
      )
    },
    [dynamicFields]
  )

  const addFieldOption = useCallback(
    index => () => {
      const options = dynamicFields[index].options
      const lastIndex = options?.length

      if (lastIndex + 1 > MAX_ALLOWED_OPTIONS_COUNT) {
        return
      }

      setDynamicFields(
        produce(draft => {
          if (!options) {
            draft[index].options = [getEmptyFieldOption()]
          } else {
            draft[index].options.push(getEmptyFieldOption())
          }
        })
      )
    },
    [dynamicFields]
  )

  const onAdditionalFieldsVisibilityChange = useCallback(() => {
    let _data = []

    if (dynamicFields.length) {
      dynamicFieldsRef.current = dynamicFields
    } else {
      _data = dynamicFieldsRef.current?.length
        ? dynamicFieldsRef.current
        : [{ ...getEmptyDynamicField(), type: INPUT }]
    }

    setDynamicFields(_data)
  }, [dynamicFields, dynamicFieldsRef.current])

  return (
    <StyledLeftSection>
      <StaticFields
        data={staticFields}
        formErrors={formErrors}
        register={register}
        onDataChange={onStaticFieldChange}
        onRsvpOptionChange={onStaticRsvpOptionChange}
      />
      <DynamicFields
        data={dynamicFields}
        formErrors={formErrors}
        register={register}
        addFieldOption={addFieldOption}
        onSwapFields={onSwapFields}
        onDeleteField={onDeleteField}
        deleteFieldOption={deleteFieldOption}
        onDataChange={onDynamicFieldDataChange}
        onOptionsDataChange={onDynamicFieldOptionTypeChange}
        onOptionsAnswerChange={onDynamicFieldAnswerChange}
        addAdditionalField={addAdditionalField}
        onAdditionalFieldsVisibilityChange={onAdditionalFieldsVisibilityChange}
      />
      <SectionTitle label={translate('submit_button_title')} />
      <Input
        isRequired={VALIDATIONS.submitBtn.isRequired}
        label={translate('submit_button_label')}
        value={staticFields.submitBtn.text}
        charLimit={VALIDATIONS.submitBtn.charLimit}
        errorMessage={formErrors.submitBtn}
        {...register({
          fieldName: 'submitBtn',
          initialValue: staticFields.submitBtn.text,
          onChange: onStaticFieldChange('submitBtn', 'text'),
          validationOptions: {
            isRequired: VALIDATIONS.submitBtn.isRequired
          }
        })}
      />
    </StyledLeftSection>
  )
}

export default memo(LeftSection)
