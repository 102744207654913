//@flow
import React, { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { isSubscriber } from '@selectors'
import { SocialImage } from '@containers/edit/containers/settings/contents/General/components'
import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

import DeleteEvent from './DeleteEvent'
import EmailNotifications from './EmailNotifications'
import GuestNumber from './GuestNumber'
import RegistrationStatus from './RegistrationStatus'
import * as Styled from './styled'
import type { TContentProps } from './types'
import {
  SETTINGS_UPGRADE_BANNER,
  SETTINGS_MOBILE_UPGRADE_BANNER
} from './consts'

const Content = ({
  data: { isRegistrationOpen, emailNotification, guestNumber },
  websiteSocialImageData,
  setWebsiteSocialImageData,
  setWebsiteEmail,
  websiteEmail,
  setData,
  formErrors,
  register
}: TContentProps) => {
  const isNotFree = useSelector(isSubscriber)
  const isMobile = useContext(MobileDetectContext)

  return (
    <Styled.Content>
      <RegistrationStatus
        isRegistrationOpen={isRegistrationOpen}
        setData={setData}
      />
      <EmailNotifications
        emailNotification={emailNotification}
        setData={setData}
        email={websiteEmail}
        setEmail={setWebsiteEmail}
        formErrors={formErrors}
        register={register}
      />
      <Styled.BannerWrapper>
        <Styled.BannerContent>
          <GuestNumber guestNumber={guestNumber} setData={setData} />
        </Styled.BannerContent>
        {isNotFree ? null : (
          <Styled.UpgradeBanner
            title={translate('invite_unlimited_guests_title')}
            paragraph={translate('invite_unlimited_guests_text')}
            bgImageUrl={
              isMobile
                ? SETTINGS_MOBILE_UPGRADE_BANNER
                : SETTINGS_UPGRADE_BANNER
            }
          />
        )}
      </Styled.BannerWrapper>
      <SocialImage
        socialImageData={websiteSocialImageData}
        setSocialImageData={setWebsiteSocialImageData}
      />
      <DeleteEvent />
    </Styled.Content>
  )
}

export default memo(Content)
