/* @flow */
import React, { useState, memo, useCallback } from 'react'
import DatePicker from 'react-datepicker'

import CalendarIcon from '@editor/common/assets/svgr-icons/rsvp/calendar.svg'
import { translate } from '@editor/common/utils/translations'

import type { TDatePickerInputProps } from './types'
import { Label, DatePickerContainer } from '../styled'
import * as Styled from './styled'
import { formatDate, getSelectedDate } from './utils'

const DatePickerInput = ({
  date,
  onDateSelect,
  isRequired
}: TDatePickerInputProps) => {
  const [isDatePickerOpened, setDatePickerOpenedState] = useState(false)

  const openDatePicker = useCallback(() => {
    setDatePickerOpenedState(true)
  }, [])

  const closeDatePicker = useCallback(() => {
    setDatePickerOpenedState(false)
  }, [])

  const onChange = useCallback(
    date => {
      if (date === null) return

      onDateSelect(formatDate(date))
    },
    [onDateSelect]
  )

  return (
    <DatePickerContainer>
      <Label>
        {translate('date_label')} {isRequired ? '*' : ''}
      </Label>
      <Styled.DatePickerWrapper isInputActive={isDatePickerOpened}>
        <CalendarIcon />
        <DatePicker
          className="date-picker-input"
          selected={getSelectedDate(date)}
          minDate={new Date()}
          showMonthDropdown={false}
          dateFormat="dd.MM.yyyy"
          onChange={onChange}
          onCalendarOpen={openDatePicker}
          onCalendarClose={closeDatePicker}
        />
      </Styled.DatePickerWrapper>
    </DatePickerContainer>
  )
}

export default memo(DatePickerInput)
