// @flow

import React, { memo, useCallback } from 'react'

import * as Styled from './styled'
import type { TTableHeadProps } from './types'

const TableHead = ({
  label,
  columnName,
  isSortable,
  isSortActive,
  isAscending,
  className = '',
  onSortChange
}: TTableHeadProps) => {
  const toggleSort = useCallback(() => {
    onSortChange(columnName, !isAscending)
  }, [onSortChange, isAscending, columnName])

  return (
    <th className={className} onClick={toggleSort}>
      <Styled.TableHeadWrapper>
        {label}
        {isSortable ? (
          <Styled.SortButtonWrapper>
            <Styled.SortArrowTopIcon isActive={isSortActive && isAscending} />
            <Styled.SortArrowBottomIcon isActive={isSortActive && !isAscending} />
          </Styled.SortButtonWrapper>
        ) : null}
      </Styled.TableHeadWrapper>
    </th>
  )
}

export default memo(TableHead)
