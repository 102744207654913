//@flow
import React, { memo, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import RSVPFormContent from '@website/common/components/RSVPForm/RSVPFormContent'
import { DEFAULT_COMP_DATA } from '@website/common/components/RSVPEmptyComponent'
import { selectAllRsvpComponents, selectEventId } from '@selectors'
import { getInitialState } from '@website/common/components/RSVPForm/utils'

import Preview from '../../components/Preview'
import { RightSection as StyledRightSection } from '../styled'
import { TRightSectionProps } from './types'

export const RightSection = ({
  formDataFromProps,
  formData
}: TRightSectionProps) => {
  const _formData = getInitialState(formData)
  const allRsvpComponents = useSelector(selectAllRsvpComponents)
  const eventId = useSelector(selectEventId)

  const rsvpComponent = allRsvpComponents[eventId] || DEFAULT_COMP_DATA
  const rsvpComponentData = rsvpComponent.data

  const formPreviewMetaData = useMemo(
    () => ({
      cardImage: rsvpComponentData.cardImage,
      centerImage: rsvpComponentData.centerImage,
      subtitleAlignment: rsvpComponentData.subtitleAlignment,
      titleAlignment: rsvpComponentData.titleAlignment,
      paragraphAlignment: rsvpComponentData.paragraphAlignment,
      dateAlignment: rsvpComponentData.dateAlignment,
      locationAlignment: rsvpComponentData.locationAlignment
    }),
    [rsvpComponentData]
  )

  return (
    <StyledRightSection>
      <Preview>
        <ThemeProvider theme={{ colorMode: rsvpComponent.colorMode }}>
          <RSVPFormContent
            isInFormPreview
            eventId={eventId}
            formData={_formData}
            className="form-preview"
            metaData={formPreviewMetaData}
            eventFormData={formDataFromProps}
          />
        </ThemeProvider>
      </Preview>
    </StyledRightSection>
  )
}

export default memo(RightSection)
