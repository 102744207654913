//@flow
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'

import Dropdown from '@editor/common/lib/Dropdown'
import { translate } from '@editor/common/utils/translations'
import { selectTimeZonesData } from '@selectors'
import { fetchTimeZonesData } from '@actions'
import Checkbox from '@editor/common/lib/Checkbox'
import { Line } from '../../styled'

import { getDropdownList } from './utils'
import { TTimezoneBlockProps } from './types'
import { ADD_LOCATION_CHECKBOX_DATA } from './consts'

const TimezoneBlock = ({
  data,
  updateData,
  timeZonesData,
  fetchTimeZonesData
}: TTimezoneBlockProps) => {
  useEffect(() => {
    fetchTimeZonesData()
  }, [])

  const onListItemClick = useCallback(
    value => updateData('timezone', { value }),
    [updateData]
  )

  const dropdownList = useMemo(
    () => getDropdownList(timeZonesData),
    [timeZonesData]
  )

  const dropdownActiveValue = useMemo(
    () => dropdownList.find(listItem => listItem.value === data.value) || {},
    [data, dropdownList]
  )

  const onCheckboxChange = useCallback(
    (e, value, isVisible) => updateData('timezone', { isVisible }),
    [updateData]
  )
  const { isVisible } = data

  return (
    <>
      <Checkbox
        isChecked={isVisible}
        className="checkbox"
        data={ADD_LOCATION_CHECKBOX_DATA}
        onChange={onCheckboxChange}
      />
      {isVisible ? (
        <Dropdown
          isSearchDropdown
          label={translate('time_zone_label')}
          dropdownList={dropdownList}
          activeItem={dropdownActiveValue}
          onListItemClick={onListItemClick}
          listMaxHeight={240}
        />
      ) : null}
      <Line />
    </>
  )
}

const mapStateToProps = state => ({
  timeZonesData: selectTimeZonesData(state)
})

const mapDispatchToProps = { fetchTimeZonesData }

export default connect(mapStateToProps, mapDispatchToProps)(memo(TimezoneBlock))
