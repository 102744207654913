//@flow
import React, { useCallback, useState, memo, useEffect } from 'react'
import { connect } from 'react-redux'

import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { translate } from '@editor/common/utils/translations'
import { useResponsive } from '@shared/hooks'
import { selectPopupsData, selectEventSuccessMessage } from '@selectors'
import { updateEventData, setEventSuccessMessage } from '@actions'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'

import ContentTitle from '../../components/ContentTitle'
import MobilePreviewButton from '../MobilePreviewButton'
import MobilePreviewHeader from '../MobilePreviewHeader'
import LeftSection from './LeftSection'
import RightSection from './RightSection'
import { TabContainer, Content, MainSection, PreviewSlideLeft } from '../styled'
import * as Styled from './styled'

import type { TPopupsProps } from './types'

import { YES } from './consts'

const Popups = ({
  popupsData,
  successMessage,
  updateEventData,
  setHasChangesInTab,
  closePopup,
  setEventSuccessMessage
}: TPopupsProps) => {
  const [activeTab, setActiveTab] = useState(YES)
  const [data, setData] = useState(popupsData)

  const hasChanges = JSON.stringify(data) !== JSON.stringify(popupsData)
  const isTabletOrMobile = useResponsive(1024)
  const [isMobilePreviewOpen, setMobilePreviewState] = useState(false)

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  const onSave = useCallback(() => {
    updateEventData(data, 'popups')
  }, [data, updateEventData])

  const { formErrors, register, handleSubmit } = useFormValidation(onSave)

  return (
    <TabContainer>
      <Content>
        <ContentTitle
          title={translate('registration_popups_title')}
          subTitle={translate('registration_popups_subtitle')}
        />
        <MainSection>
          <LeftSection
            data={data}
            activeTab={activeTab}
            formErrors={formErrors}
            register={register}
            setData={setData}
            setActiveTab={setActiveTab}
          />
          {isTabletOrMobile && !isMobilePreviewOpen ? (
            <MobilePreviewButton onClick={() => setMobilePreviewState(true)} />
          ) : null}
          {!isTabletOrMobile ? (
            <RightSection data={data} activeTab={activeTab} />
          ) : null}
        </MainSection>
      </Content>
      <SaveAndDiscardFooter
        successMessage={successMessage}
        isSaveBtnDisabled={!hasChanges}
        onDiscard={closePopup}
        onSave={handleSubmit}
        setSuccessMessage={setEventSuccessMessage}
      />
      <PreviewSlideLeft isOpen={isMobilePreviewOpen && isTabletOrMobile}>
        <MobilePreviewHeader onClick={() => setMobilePreviewState(false)} />
        <RightSection data={data} activeTab={activeTab} />
      </PreviewSlideLeft>
    </TabContainer>
  )
}

const mapStateToProps = state => ({
  popupsData: selectPopupsData(state),
  successMessage: selectEventSuccessMessage(state)
})

const mapDispatchToProps = { updateEventData, setEventSuccessMessage }

export default connect(mapStateToProps, mapDispatchToProps)(memo(Popups))
