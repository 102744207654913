//@flow
import React, { memo } from 'react'

import { EmailBlockCard, Divider } from '../styled'
import type { TProps } from '../types'

import ConfirmationEmail from './ConfirmationEmail'
import RegretEmail from './RegretEmail'
import EmailSettings from './EmailSettings'

const PaidUserView = ({ setHasChangesInTab }: TProps) => (
  <EmailBlockCard>
    <ConfirmationEmail setHasChangesInTab={setHasChangesInTab} />
    <Divider />
    <RegretEmail setHasChangesInTab={setHasChangesInTab} />
    <Divider />
    <EmailSettings setHasChangesInTab={setHasChangesInTab} />
  </EmailBlockCard>
)

export default memo(PaidUserView)
