import styled from 'styled-components'

import Button from '@editor/common/lib/Button'
import { gradientTextStyles } from '@editor/common/styles'
import { secondary } from '@website/common/styles/colors'
import _UpgradeButton from '../../../components/UpgradeButton'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
`

export const UpgradeButton = styled(_UpgradeButton)`
  font-size: 16px;
  margin-top: 30px;

  & > svg {
    width: 24px;
    height: 24px;
  }
`

export const Title = styled.p`
  color: ${secondary.ExtraDark};
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  max-width: 750px;
`

export const GradientText = styled.p`
  ${gradientTextStyles};
  font-size: 24px;
  margin: 0 6px;
`

export const Description = styled.p`
  color: ${secondary.SemiDark};
  font-size: 16px;
  text-align: center;
  margin-top: ${({ hasMarginFromTop }) => (hasMarginFromTop ? '10px' : 0)};

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

export const Banner = styled.video`
  width: 628px;
  max-height: 183px;
  margin: 30px 0 20px;
  border-radius: 20px;

  @media screen and (max-width: 500px) {
    border-radius: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const GoMultilingualBtn = styled(Button)`
  padding: 11px 30px;
  margin-top: 30px;
`

export const Illustration = styled.img`
  width: 150px;
  margin-bottom: 30px;
`
