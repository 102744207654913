import styled from 'styled-components'

import { primary, secondary } from '@website/common/styles/colors'
import { DatePickerWrapper } from '../styled'

export const TimePickerWrapper = styled(DatePickerWrapper)`
  .react-datepicker-popper {
    z-index: 3;
    width: 100%;
    .react-datepicker {
      width: 100%;
      font-family: inherit;
      border-radius: 5px;
      border: 1px solid ${secondary.Light};
      box-shadow: 0 3px 6px 0 rgba(50, 113, 230, 0.2);
      box-sizing: border-box;
      overflow: hidden;

      .react-datepicker__time-container,
      .react-datepicker__time-box {
        width: 100%;
      }

      .react-datepicker__time-list-item {
        display: flex;
        align-items: center;
        padding: 0 15px !important;
        box-sizing: border-box;
        font-size: 14px;
        color: ${secondary.SemiDark};

        &:hover {
          color: ${secondary.ExtraDark};
          background-color: ${primary.Light} !important;
          font-weight: 500;
        }
      }

      .react-datepicker__time-list-item--selected {
        background-color: #3271e6 !important;
        font-weight: 500 !important;

        &:hover {
          background-color: #3271e6 !important;
        }
      }

      .react-datepicker__header,
      .react-datepicker__triangle {
        display: none;
      }
    }
  }
`
