//@flow
import React, { memo } from 'react'

import { translate } from '@editor/common/utils/translations'
import PopupContent from '@website/common/components/RSVPRegistrationPopup/PopupContent'
import { useResponsive } from '@shared/hooks'
import Preview from '../../components/Preview'
import * as Styled from './styled'
import { TABS_LABLES } from './consts'
import { TRightSectionProps } from './types'

export const RightSection = ({ data, activeTab }: TRightSectionProps) => {
  const isTabletOrMobile = useResponsive(1024)

  return (
    <Styled.RightSection>
      <Preview>
        {!isTabletOrMobile ? (
          <PopupContent
            title={data[activeTab].title.text}
            paragraph={data[activeTab].paragraph.text}
            buttonText={data[activeTab]?.buttonText?.text}
            showButton={data[activeTab]?.showButton}
          />
        ) : (
          <Styled.PopupsContainer>
            {Object.values(data).map((dataItem, idx) => {
              return (
                <Styled.PopupWrapper>
                  <Styled.PopupHeader>{`"${
                    Object.values(TABS_LABLES)[idx]
                  }" ${translate('message_label')}`}</Styled.PopupHeader>
                  <PopupContent
                    title={dataItem.title.text}
                    paragraph={dataItem.paragraph.text}
                    buttonText={dataItem?.buttonText?.text}
                    showButton={dataItem?.showButton}
                  />
                </Styled.PopupWrapper>
              )
            })}
          </Styled.PopupsContainer>
        )}
      </Preview>
    </Styled.RightSection>
  )
}

export default memo(RightSection)
