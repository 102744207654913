//@flow

import React, { memo, useRef } from 'react'
import { connect } from 'react-redux'
import { useResponsive } from '@shared/hooks'

import {
  selectGuestsListData,
  selectIsGuestsListLoading,
  selectGuestListSortingInfo,
  selectGuestsCountsByLanguage,
  selectIsGuestListPaginationLoading
} from '@selectors'
import {
  setGuestsListSortingInfo,
  getGuestListNextPage,
  removeGuest
} from '@actions'
import useInfiniteScroll from '@hooks/useInfiniteScroll'
import MobileTable from './MobileTable'
import DesktopTable from './DesktopTable'
import type { TTableProps } from './types'

const Table = ({
  activeGuestInfo,
  isLangColumnVisible,
  setActiveGuestInfo,
  openGuestDetails,
  getGuestListNextPage,
  openEditGuestSidebar
}: TTableProps) => {
  const tableWrapperRef = useRef()

  const isTablet = useResponsive(1024)

  useInfiniteScroll(tableWrapperRef, getGuestListNextPage)

  return isTablet ? (
    <MobileTable
      isLangColumnVisible={isLangColumnVisible}
      activeGuestInfo={activeGuestInfo}
      setActiveGuestInfo={setActiveGuestInfo}
      openGuestDetails={openGuestDetails}
      openEditGuestSidebar={openEditGuestSidebar}
    />
  ) : (
    <DesktopTable
      activeGuestInfo={activeGuestInfo}
      setActiveGuestInfo={setActiveGuestInfo}
      openGuestDetails={openGuestDetails}
      openEditGuestSidebar={openEditGuestSidebar}
    />
  )
}

const mapStateToProps = state => ({
  isGuestListLoading: selectIsGuestsListLoading(state),
  guestsList: selectGuestsListData(state),
  guestListSortingInfo: selectGuestListSortingInfo(state),
  isLangColumnVisible: selectGuestsCountsByLanguage(state).length !== 0,
  isGuestListPaginationLoading: selectIsGuestListPaginationLoading(state)
})

const mapDispatchToProps = {
  setGuestsListSortingInfo,
  getGuestListNextPage,
  removeGuest
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(Table))
