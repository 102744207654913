//@flow
import React, { memo, useCallback, useMemo } from 'react'

import Checkbox from '@editor/common/lib/Checkbox'
import LibInput from '@editor/common/lib/Input'
import GoogleMap from '@editor/common/components/GoogleMap'
import { translate } from '@editor/common/utils/translations'
import Validatable from '@website/common/lib/ValidatableHOC'

import { LABELS_VALIDATIONS } from '../../../../consts'
import { ADD_LOCATION_CHECKBOX_DATA } from './consts'
import * as Styled from './styled'
import type { TLocationBlockProps } from './types'

const VALIDATIONS = LABELS_VALIDATIONS.details

const Input = Validatable(LibInput)

const LocationBlock = ({
  data,
  updateData,
  formErrors,
  register
}: TLocationBlockProps) => {
  const { isVisible, name, url, lat, lng } = data

  const onCheckboxChange = useCallback(
    (e, value, isVisible) => updateData('location', { isVisible }),
    [updateData]
  )

  const onInputChange = useCallback(
    name => updateData('location', { name }),
    [updateData]
  )

  const setMapSrc = useCallback(
    url => updateData('location', { url }),
    [updateData]
  )

  const setGeoLocation = useCallback(
    ({ lat, lng }) => updateData('location', { lat, lng }),
    [updateData]
  )

  const geoLocation = useMemo(
    () => ({
      lat,
      lng
    }),
    [lat, lng]
  )

  return (
    <Styled.MapContainer>
      <Checkbox
        isChecked={isVisible}
        className="checkbox"
        data={ADD_LOCATION_CHECKBOX_DATA}
        onChange={onCheckboxChange}
      />
      {isVisible ? (
        <>
          <Input
            isRequired={VALIDATIONS.locationName.isRequired}
            label={translate('name_of_the_venue_label')}
            value={name}
            onChange={onInputChange}
            charLimit={VALIDATIONS.locationName.charLimit}
            errorMessage={formErrors.locationName}
            className="location"
            {...register({
              fieldName: 'locationName',
              initialValue: name,
              onChange: onInputChange,
              validationOptions: {
                isRequired: VALIDATIONS.locationName.isRequired
              }
            })}
          />
          <GoogleMap
            areControlsHidden
            mapSrc={url}
            geoLocation={geoLocation}
            setMapSrc={setMapSrc}
            setGeoLocation={setGeoLocation}
          />
        </>
      ) : null}
    </Styled.MapContainer>
  )
}

export default memo(LocationBlock)
