import { EMPTY_FIELD_OPTION_DATA, EMPTY_DYNAMIC_FIELD_DATA } from './consts'
import { generateUniqId } from '@editor/common/utils'

export const getEmptyFieldOption = () => ({
  ...EMPTY_FIELD_OPTION_DATA,
  id: generateUniqId()
})

export const getEmptyDynamicField = () => ({
  ...EMPTY_DYNAMIC_FIELD_DATA,
  id: generateUniqId()
})