//@flow
import React, { memo } from 'react'
import { useResponsive } from '@shared/hooks'

import * as Styled from './styled'
import type { TDropdownProps } from './types'
import MobileDropdown from './MobileDropDown'

const STATIC_PROPS = {
  dropdownTogglerIcon: 'arrow_filled_bottom',
  isActiveItemWithCheckmark: false,
  closeDropDownBySelecting: true,
  listItemHeight: 40,
  dropdownWidth: '100%'
}

const Dropdown = ({
  label,
  isSearchDropdown,
  activeItem,
  dropdownList,
  onListItemClick,
  isDisabled,
  listMaxHeight,
  isSlideUpForMobile,
  isOpen,
  ...rest
}: TDropdownProps) => {
  const commonProps = {
    label,
    activeItem,
    dropdownList,
    onListItemClick,
    isDisabled,
    listMaxHeight,
    isOpen,
    ...STATIC_PROPS,
    ...rest
  }
  const isMobile = useResponsive(768)

  return isSearchDropdown ? (
    <Styled.SearchDropdown {...commonProps} />
  ) : isSlideUpForMobile && isMobile ? (
    <MobileDropdown {...commonProps} />
  ) : (
    <Styled.SimpleDropdown {...commonProps} />
  )
}

export default memo(Dropdown)
