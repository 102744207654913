//@flow
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import {
  selectEventSuccessMessage,
  selectSettingsData,
  selectIsRsvpGuestUnlimited,
  selectRsvpGuestLimit,
  selectGeneralSettings,
  selectSiteName,
  selectSettingsSuccessMessage
} from '@selectors'
import { setEventSuccessMessage, updateEventData } from '@actions/events'
import { changeGeneralSettings } from '@actions/meta'
import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { ImageUploadInfo } from '@editor/common/utils/image'

import ContentTitle from '../../components/ContentTitle'
import { Content, TabContainer } from '../styled'
import MainContent from './Content'
import type { TSettingsProps } from './types'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'

const Settings = ({
  eventSettings,
  eventSuccessMessage,
  updateEventData,
  setHasChangesInTab,
  closePopup,
  setEventSuccessMessage,
  isRsvpGuestUnlimited,
  rsvpGuestLimit,
  generalSettings,
  changeGeneralSettings,
  settingsSuccessMessage,
  siteName
}: TSettingsProps) => {
  const { socialImage, email } = generalSettings
  const _eventSettings = !!email
    ? eventSettings
    : {
        ...eventSettings,
        emailNotification: !!email
      }

  const [data, setData] = useState(_eventSettings)

  const socialImageWithUploadState = new ImageUploadInfo({
    failed: false,
    isLoading: false,
    url: socialImage
  })

  const [websiteSocialImageData, setWebsiteSocialImageData] = useState({
    socialImage: socialImageWithUploadState,
    socialImageWidth: 0,
    socialImageHeight: 0
  })
  const [websiteEmail, setWebsiteEmail] = useState(email)

  useEffect(() => {
    const _guestNumber = eventSettings.guestNumber

    const guestNumber =
      rsvpGuestLimit && typeof _guestNumber === 'number'
        ? Math.min(rsvpGuestLimit, _guestNumber)
        : _guestNumber

    setData({ ...data, guestNumber })
  }, [rsvpGuestLimit, eventSettings])

  const updateEventSettingsData = useCallback((key, value) => {
    setData(data => ({
      ...data,
      [key]: value
    }))
  }, [])

  const websiteSocialImage = websiteSocialImageData.socialImage
  const websiteSocialImageUrl = websiteSocialImage.toString()

  const hasGeneralSettingsChanges =
    websiteSocialImageUrl !== socialImage || websiteEmail !== email

  const hasEventChanges =
    JSON.stringify(data) !== JSON.stringify(_eventSettings)
  const hasChanges = hasEventChanges || hasGeneralSettingsChanges

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  const onSaveBtnClick = () => {
    const guestNumber =
      !data.guestNumber && !isRsvpGuestUnlimited ? 0 : data.guestNumber
    if (hasEventChanges) {
      updateEventData({ ...data, guestNumber }, 'settings')
    }

    if (hasGeneralSettingsChanges) {
      const settings = {
        ...generalSettings,
        ...websiteSocialImageData,
        socialImage: websiteSocialImageUrl,
        email: websiteEmail
      }
      changeGeneralSettings(
        settings,
        hasGeneralSettingsChanges,
        siteName,
        false
      )
    }
  }

  const { formErrors, register, handleSubmit } =
    useFormValidation(onSaveBtnClick)

  const isSaveBtnDisabled =
    !hasChanges || websiteSocialImage.isLoading || websiteSocialImage.failed
  const successMessage = eventSuccessMessage || settingsSuccessMessage

  return (
    <TabContainer>
      <Content>
        <ContentTitle
          withUnderline
          title={translate('settings_label')}
          subTitle={translate('event_settings_subtitle')}
        />
        <MainContent
          data={data}
          setData={updateEventSettingsData}
          websiteSocialImageData={websiteSocialImageData}
          setWebsiteSocialImageData={setWebsiteSocialImageData}
          websiteEmail={websiteEmail}
          setWebsiteEmail={setWebsiteEmail}
          register={register}
          formErrors={formErrors}
        />
      </Content>
      <SaveAndDiscardFooter
        successMessage={successMessage}
        isSaveBtnDisabled={isSaveBtnDisabled}
        onDiscard={closePopup}
        onSave={handleSubmit}
        setSuccessMessage={setEventSuccessMessage}
      />
    </TabContainer>
  )
}

const mapStateToProps = state => ({
  generalSettings: selectGeneralSettings(state),
  siteName: selectSiteName(state),
  eventSettings: selectSettingsData(state),
  eventSuccessMessage: selectEventSuccessMessage(state),
  settingsSuccessMessage: selectSettingsSuccessMessage(state),
  isRsvpGuestUnlimited: selectIsRsvpGuestUnlimited(state),
  rsvpGuestLimit: selectRsvpGuestLimit(state)
})

export default connect(mapStateToProps, {
  updateEventData,
  setEventSuccessMessage,
  changeGeneralSettings
})(memo(Settings))
