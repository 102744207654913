import styled, { css } from 'styled-components'

import LibSlideLeft from '@editor/common/lib/SlideLeft'
import { secondary, white } from '@website/common/styles/colors'
import Button from '@editor/common/lib/Button/Button'
export const MainContent = styled.div`
  width: 100%;
  padding: 10px 40px 40px;
  box-sizing: border-box;
  overflow-y: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    max-width: 768px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 600px) {
    max-width: 728px;
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
`

export const SlideLeft = styled(LibSlideLeft)`
  background-color: ${white};
  @media only screen and (min-width: 1025px) {
    border-left: 1px solid ${secondary.Light};
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    > div {
      width: 100%;
    }
  }
`

export const Layer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${white};
  opacity: 0.6;
  z-index: 1;
`

export const TextIconBtn = styled(Button)`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 10px;
  & > svg {
    margin-right: 7px;
    fill: #3271e6;
  }
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.5;
          box-shadow: none;
          cursor: default;
          pointer-events: none;
        `
      : ''};

  &:hover {
    & > svg {
      fill: ${white};
    }
  }
`
