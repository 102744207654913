import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  .info-icon-wrapper {
    display: flex;
    cursor: pointer;

    & > svg {
      fill: #8493bd;
    }
  }
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-right: 5px;
  color: ${secondary.ExtraDark};
`
