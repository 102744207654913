// @flow

import React, { memo } from 'react'
import * as Styled from './styled'
import { translate } from '@editor/common/utils/translations'
import UpgradeButton from '../../../components/UpgradeButton'

const UpgradeBanner = () => (
  <Styled.UpgradeBanner>
    <p>{translate('guest_list_upgrade_text')}</p>
    <UpgradeButton />
  </Styled.UpgradeBanner>
)

export default memo(UpgradeBanner)
