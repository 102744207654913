//@flow

import React, { memo } from 'react'
import { TContentTitleProps } from './types'
import * as Styled from './styled'

const ContentTitle = ({
  title,
  subTitle,
  className = '',
  withUnderline = true
}: TContentTitleProps) => (
  <Styled.Container className={className} withUnderline={withUnderline}>
    <Styled.Title>{title}</Styled.Title>
    <Styled.SubTitle>{subTitle}</Styled.SubTitle>
  </Styled.Container>
)

export default memo(ContentTitle)
