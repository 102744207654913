import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

export const getMessage = ({ searchStr, isFiltersApplied, guestLimit }) => {
  if (searchStr) {
    return guestLimit
      ? transformTextVariables(
          translate('no_guest_search_result_limitation_text'),
          [
            {
              value: guestLimit
            },
            {
              value: `"${searchStr}"`,
              Comp: BoldComp
            }
          ]
        )
      : transformTextVariables(translate('no_guest_search_result_text'), [
          {
            value: `"${searchStr}"`,
            Comp: BoldComp
          }
        ])
  }
  if (isFiltersApplied) {
    return guestLimit
      ? transformTextVariables(
          translate('no_guest_filter_result_limitation_text'),
          [
            {
              value: guestLimit
            }
          ]
        )
      : translate('no_guest_filter_result_text')
  }

  return translate('no_guests_yet_label')
}
