import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'

export const MapWrapper = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: 1px solid ${secondary.Light};
  box-sizing: border-box;
  margin-top: 10px;
`
