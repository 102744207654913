//@flow
import React, { memo } from 'react'

import { getFlagUrl } from '@website/common/utils/website-languages'
import type { TDropdownItemProps } from './types'
import * as Styled from './styled'

const DropdownItem = ({ language }: TDropdownItemProps) => (
  <Styled.DropdownItem>
    <img src={getFlagUrl(language.flagCode)} alt={language.name} />
    {language.name}
  </Styled.DropdownItem>
)

export default memo(DropdownItem)
