import styled, { css } from 'styled-components'

import { secondary } from '@website/common/styles/colors'
import { Radio } from '@editor/common/lib/Radio'
import Checkbox from '@editor/common/lib/Checkbox'

const radioBtnStyles = css`
  flex-direction: unset;
  flex-wrap: wrap;
  > label {
    margin: 0;

    .radio-button {
      margin: 0 10px 0 0;
    }

    .label-container {
      font-weight: 400;
      word-break: break-word;
      margin-right: 0;
    }

    :not(:nth-child(3n)) {
      margin-right: 30px;
    }
  }
`

export const InputPair = styled.div`
  display: flex;

  @media only screen and (max-width: 650px) {
    flex-direction: column;
    .input-container {
      width: 100%;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  ${({ isInEdit }) =>
    isInEdit
      ? css`
          flex-direction: column;
          .input-container {
            width: 320px;
            margin-bottom: 20px;
          }
        `
      : css`
          margin-bottom: 20px;
          .input-container {
            width: 275px;
            :first-child {
              @media only screen and (min-width: 651px) {
                margin-right: 20px;
              }
            }
          }
          @media only screen and (max-width: 1024px) {
            width: 100%;
            .input-container {
              width: 100%;
            }
          }
        `}
`

export const Section = styled.div`
  ${({ isInEdit }) =>
    isInEdit
      ? css`
          padding: 20px 30px;
        `
      : css`
          padding: 30px 50px;
          @media only screen and (max-width: 1024px) {
            padding-right: 40px;
            padding-left: 40px;
          }
          @media only screen and (max-width: 650px) {
            padding-right: 20px;
            padding-left: 20px;
          }
        `};
  border-bottom: 1px solid ${secondary.Light};
  box-sizing: border-box;
`

export const SectionTitle = styled.h4`
  color: ${secondary.ExtraDark};
  font-size: ${({ isInEdit }) => (isInEdit ? '14px' : '16px')};
  font-weight: 600;
  margin-bottom: 20px;
`

export const GuestDetailsForm = styled(Section)`
  ${({ isInEdit }) =>
    isInEdit
      ? css`
          padding: 20px 30px;
          border: unset;
          @media only screen and (max-width: 1024px) {
            > div,
            h4 {
              width: 100%;
              .input-container {
                width: 100%;
              }
            }
          }
        `
      : css`
          padding: 30px 50px 20px;
        `};
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    > h4,
    > div {
      width: 100%;
      max-width: 688px;
    }
  }
  @media only screen and (max-width: 650px) {
    padding-right: 20px;
    padding-left: 20px;
    max-width: 728px;
  }
`

export const AdditionalInformation = styled(Section)`
  max-width: 670px;
  border: unset;
  > div:last-child {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 768px;
  }
`

export const AdditionalField = styled.div`
  ${({ isTextInput }) =>
    isTextInput
      ? css`
          margin-bottom: 20px;
          .input-container {
            margin-top: 5px;
          }
        `
      : css`
          margin-bottom: 30px;
          .radio-btn-list {
            margin-top: 20px;
            @media only screen and (max-width: 600px) {
              flex-direction: column;
            }
          }
        `}
`

export const Question = styled.p`
  font-size: 14px;
  color: ${secondary.ExtraDark};
`

export const AdditionalGuest = styled.div`
  display: flex;
  font-size: 14px;
  color: ${secondary.ExtraDark};
  font-weight: 600;
  margin-bottom: 20px;
`

export const RemoveGuestButton = styled.button`
  display: block;
  margin-left: 20px;
  position: relative;
  color: #3271e6;
  background: transparent;
  border: none;
  font-weight: 600;
  &:after {
    content: '';
    position: absolute;
    height: 10px;
    width: 1px;
    background: ${secondary.Light};
    left: -10px;
    top: 4px;
  }
`

export const AdditionalGuestInputPair = styled.div`
  display: flex;
  position: relative;
  width: max-content;

  @media only screen and (max-width: 650px) {
    flex-direction: column;
    .input-container {
      width: 100%;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .input-container {
    width: 275px;
    margin-bottom: 30px;
    :first-child {
      @media only screen and (min-width: 651px) {
        margin-right: 20px;
      }
    }
  }

  .remove_icon {
    position: absolute;
    top: 36px;
    right: -25px;
    cursor: pointer;
    fill: #8493bd;
    &:hover {
      fill: ${secondary.SemiDark};
    }
  }
`

export const RSVPOptions = styled(Radio)`
  ${radioBtnStyles};
  row-gap: 20px;
`

export const RadioBtnList = styled(Radio)`
  ${radioBtnStyles};
  row-gap: 20px;
  > label {
    .label-container {
      width: 120px;
    }
  }
`

export const CheckboxList = styled(Checkbox)`
  flex-direction: unset;
  margin-top: 20px;
  flex-wrap: wrap;
  row-gap: 20px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  > label {
    margin-bottom: 0;
    > div:last-child {
      width: 120px;
      word-break: break-word;
      margin-right: 0;
    }

    :not(:nth-child(3n)) {
      margin-right: 30px;
    }
  }
`

export const Buttons = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  box-shadow: 0px -3px 12px 0px rgba(50, 113, 230, 0.1);
  > button {
    height: 40px;
    padding: 11px 30px;

    :first-child {
      margin-right: 20px;
    }
  }
`
