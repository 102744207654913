import React from 'react'
import CheckboxLabel from './CheckboxLabel'

export const getCheckboxData = data =>
  data.map(({ code, name, flagCode }) => ({
    label: (
      <CheckboxLabel
        data={{
          flagCode,
          language: name
        }}
      />
    ),
    value: code
  }))
