import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .save-cancel-footer {
    height: 65px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    @media only screen and (max-width: 768px) {
      justify-content: center;
    }
    ${({ isEdit }) =>
      isEdit
        ? css`
            padding-left: 20px;
            padding-right: 20px;
          `
        : css`
            padding-left: 30px;
            padding-right: 30px;
          `}

    > div:last-child {
      margin-left: 20px;
    }

    > div:last-child {
      flex-direction: row-reverse;
    }
  }
`

export const Form = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

export const Title = styled.h4`
  color: ${secondary.ExtraDark};
  font-weight: 500;
  padding: 20px 30px;
  border-bottom: 1px solid ${secondary.Light};
  box-sizing: border-box;
  box-shadow: 0 4px 10px 0 rgba(50, 113, 230, 0.05);
  @media only screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 650px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
