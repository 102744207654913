//@flow
import React, { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import produce from 'immer'

import LibInput from '@editor/common/lib/Input'
import PlusIcon from '@editor/common/assets/svgr-icons/Plus_icon.svg'
import RemoveIcon from '@editor/common/assets/svgr-icons/cancel.svg'
import { translate } from '@editor/common/utils/translations'
import { generateUniqId } from '@editor/common/utils'
import { useResponsive } from '@shared/hooks'
import Validatable from '@website/common/lib/ValidatableHOC'
import { INITIAL_GUEST_DATA } from '@website/common/components/RSVPForm/consts'
import { selectTotalGuestsCount, selectRsvpGuestLimit } from '@selectors'
import { LABELS_VALIDATIONS } from '@containers/edit/containers/event/consts'

import { TextIconBtn } from '../../styled'
import * as Styled from './styled'
import type { TAdditionalGuestsProps } from './types'

const Input = Validatable(LibInput)
const VALIDATIONS = LABELS_VALIDATIONS.form

const getInitialGuestData = () => ({
  ...INITIAL_GUEST_DATA,
  id: generateUniqId()
})

const AdditionalGuests = ({
  additionalGuests,
  additionalGuestNumber,
  firstName,
  lastName,
  setFormData,
  formErrors,
  register
}: TAdditionalGuestsProps) => {
  const rsvpGuestLimit = useSelector(selectRsvpGuestLimit)
  const totalCount = useSelector(selectTotalGuestsCount)
  const isMobile = useResponsive(650)

  const availableGuestNumber = Math.min(
    rsvpGuestLimit - totalCount - 1,
    additionalGuestNumber
  )

  const isBtnDisabled = additionalGuests.length >= availableGuestNumber

  const onInputChange = useCallback(
    (key, idx) => value => {
      setFormData(
        produce(draft => {
          draft.additionalGuests[idx][key] = value
        })
      )
    },
    []
  )

  const onRemoveIconClick = idx => {
    setFormData(
      produce(draft => {
        draft.additionalGuests.splice(idx, 1)
      })
    )
  }

  const onBtnClick = () => {
    if (isBtnDisabled) return

    setFormData(
      produce(draft => {
        draft.additionalGuests.push(getInitialGuestData())
      })
    )
  }

  return (
    <Styled.Section>
      <Styled.SectionTitle>
        {translate('additional_guests_label')}
      </Styled.SectionTitle>
      {additionalGuests.map((additionalGuest, idx) => {
        const { id } = additionalGuest
        return (
          <React.Fragment key={id}>
            <Styled.AdditionalGuest>
              {translate('additional_guest_label')} {idx + 1}{' '}
              {isMobile ? (
                <Styled.RemoveGuestButton
                  onClick={() => onRemoveIconClick(idx)}
                >
                  {translate('remove_guest_label')}
                </Styled.RemoveGuestButton>
              ) : null}
            </Styled.AdditionalGuest>
            <Styled.AdditionalGuestInputPair>
              <Input
                isRequired
                value={additionalGuest.firstName}
                label={firstName.text}
                placeholder={translate('enter_guest_name_placeholder')}
                charLimit={VALIDATIONS.firstName.charLimit}
                errorMessage={formErrors[`additionalGuest${id}_firstName`]}
                {...register({
                  fieldName: `additionalGuest${id}_firstName`,
                  initialValue: additionalGuest.firstName,
                  onChange: onInputChange('firstName', idx),
                  validationOptions: {
                    isRequired: true
                  }
                })}
              />
              <Input
                isRequired
                value={additionalGuest.lastName}
                label={lastName.text}
                placeholder={translate('enter_guest_lastname_placeholder')}
                charLimit={VALIDATIONS.lastName.charLimit}
                errorMessage={formErrors[`additionalGuest${id}_lastName`]}
                {...register({
                  fieldName: `additionalGuest${id}_lastName`,
                  initialValue: additionalGuest.lastName,
                  onChange: onInputChange('lastName', idx),
                  validationOptions: {
                    isRequired: true
                  }
                })}
              />
              {!isMobile ? (
                <RemoveIcon
                  className="remove_icon"
                  onClick={() => onRemoveIconClick(idx)}
                />
              ) : null}
            </Styled.AdditionalGuestInputPair>
          </React.Fragment>
        )
      })}
      <TextIconBtn
        type="tertiary"
        onClick={onBtnClick}
        disabled={isBtnDisabled}
      >
        <PlusIcon /> {translate('additional_guest_label')}
      </TextIconBtn>
    </Styled.Section>
  )
}

export default memo(AdditionalGuests)
