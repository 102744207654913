// @flow
import React, { memo } from 'react'

import DoneIcon from '@editor/common/assets/svgr-icons/Done.svg'
import Button from '@editor/common/lib/Button/Button'
import SuccessPopup from '@editor/common/components/SuccessPopup'
import { useResponsive } from '@shared/hooks'

import type { TSaveAndDiscardFooterProps } from './types'
import {
  DISCARD_BTN_TEXT,
  SAVE_BTN_TEXT,
  SMALL_SIZES_BREAKPOINT
} from './consts'
import * as Styled from './styled'

const SaveAndDiscardFooter = ({
  saveBtnText = SAVE_BTN_TEXT,
  discardBtnText = DISCARD_BTN_TEXT,
  className,
  successMessage,
  isSaveBtnDisabled,
  setSuccessMessage,
  onDiscard,
  onSave
}: TSaveAndDiscardFooterProps) => {
  const isSmallSizes = useResponsive(SMALL_SIZES_BREAKPOINT)

  return (
    <Styled.Footer className={className}>
      {isSmallSizes ? (
        <SuccessPopup
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
        />
      ) : (
        <Styled.SavingInfo show={!!successMessage}>
          <DoneIcon />
          {successMessage}
        </Styled.SavingInfo>
      )}
      <Styled.Buttons>
        <Button type="secondary" onClick={onDiscard}>
          {discardBtnText}
        </Button>
        <Button
          type="primary"
          className="save-btn"
          onClick={onSave}
          disabled={isSaveBtnDisabled}
        >
          {saveBtnText}
        </Button>
      </Styled.Buttons>
    </Styled.Footer>
  )
}

export default memo(SaveAndDiscardFooter)
