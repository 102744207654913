// @flow

import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectGuestsListFilters,
  selectIsGuestsListLoading,
  selectIsGuestListUpgradeVisible,
  selectRsvpGuestLimit
} from '@selectors'
import { NO_GUESTS_IMAGE_URL, NO_RESULT_IMAGE_URL } from './consts'
import { getMessage } from './utils'
import * as Styled from './styled'

const EmptyContent = () => {
  const filters = useSelector(selectGuestsListFilters)
  const isGuestListLoading = useSelector(selectIsGuestsListLoading)
  const isUpgradeVisible = useSelector(selectIsGuestListUpgradeVisible)
  const rsvpGuestLimit = useSelector(selectRsvpGuestLimit)

  const { answer, language, searchStr } = filters

  const isFiltersApplied = answer !== 'all' || language !== 'all'
  const imgUrl = isFiltersApplied || !!searchStr ? NO_RESULT_IMAGE_URL : NO_GUESTS_IMAGE_URL

  const message = useMemo(
    () =>
      getMessage({
        searchStr,
        isFiltersApplied,
        guestLimit: isUpgradeVisible ? rsvpGuestLimit : null
      }),
    [searchStr, isFiltersApplied, isUpgradeVisible, rsvpGuestLimit]
  )

  return (
    <Styled.EmptyContainer>
      {isGuestListLoading ? null : (
        <>
          <img alt="no_result" src={imgUrl} />
          <p>{message}</p>
        </>
      )}
    </Styled.EmptyContainer>
  )
}

export default memo(EmptyContent)
