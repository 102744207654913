//@flow
import React, { memo, useCallback } from 'react'
import LibInput from '@editor/common/lib/Input'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import Validatable from '@website/common/lib/ValidatableHOC'
import {
  DYNAMIC_LABELS_TRANSLATION,
  STATIC_LABELS_TRANSLATIONS
} from './consts'
import { LABELS_VALIDATIONS } from '../../../../consts'
import type { TSectionItemProps } from './types'
import * as Styled from './styled'

const Input = Validatable(LibInput)

const SectionItem = ({
  item: { idx, key, translationId, isDynamicField, inputType },
  tabKey,
  toLangText,
  fromLangText,
  setData,
  formErrors,
  register
}: TSectionItemProps) => {
  const label = isDynamicField
    ? transformTextVariables(DYNAMIC_LABELS_TRANSLATION[key], [{ value: idx }])
    : STATIC_LABELS_TRANSLATIONS[tabKey][key]

  const { charLimit, isRequired } = LABELS_VALIDATIONS[tabKey][key]

  const updateData = useCallback(
    value => {
      setData(tabKey, translationId, value)
    },
    [setData, tabKey, translationId]
  )

  return (
    <Styled.SectionItem key={translationId}>
      <Styled.TranslateFromSection>
        <Styled.TextsWrapper>
          <Styled.Label>{label}</Styled.Label>
          <Styled.Text>
            {fromLangText || (
              <Styled.FromEmptyText>
                {translate('your_text_label')}
              </Styled.FromEmptyText>
            )}
          </Styled.Text>
        </Styled.TextsWrapper>
      </Styled.TranslateFromSection>
      <Input
        isTextArea={inputType === 'textArea'}
        isRequired={isRequired}
        placeholder={
          !toLangText && !fromLangText ? translate('your_text_label') : ''
        }
        value={toLangText}
        charLimit={charLimit}
        errorMessage={formErrors[label]}
        {...register({
          fieldName: label,
          initialValue: toLangText,
          onChange: updateData,
          validationOptions: {
            isRequired
          }
        })}
      />
    </Styled.SectionItem>
  )
}

export default memo(SectionItem)
