import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const UpgradeBanner = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 40px;
  border-top: 1px solid ${secondary.Light};
  background: white;
  align-items: center;
  justify-content: center;
  & > p {
    font-size: 16px;
    font-weight: 600;
    color: #4136c1;
    margin-right: 20px;
  }
`