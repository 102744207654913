// @flow
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  forwardRef
} from 'react'

import * as Styled from './styled'
import type { TInputProps } from './types'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import AttentionIcon from '@editor/common/assets/svgr-icons/Attention_icon_red.svg'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { translate } from '@editor/common/utils/translations'

const getCharMaxLimitMessage = charLimit =>
  transformTextVariables(translate('max_char_message'), [{ value: charLimit }])

const Input = forwardRef(
  (
    {
      type = 'text',
      value,
      label,
      charLimit,
      placeholder,
      defaultValue = '',
      errorMessage: errorMessageFromProps,
      className,
      isRequired,
      disabled,
      isTextArea,
      onChange,
      onBlur,
      onKeyPress,
      isRemoveIconHidden = false
    }: TInputProps,
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState(errorMessageFromProps)
    const [isInputActive, setInputActiveState] = useState(false)
    const timerRef = useRef()

    const isRemoveIconVisible = useMemo(
      () => isInputActive && value && !isRemoveIconHidden,
      [isInputActive, value, isRemoveIconHidden]
    )

    const _setErrorMessage = useCallback(
      value => {
        if (timerRef.current) {
          clearTimeout(timerRef.current)
          timerRef.current = null
        }
        setErrorMessage(value)
      },
      [timerRef.current]
    )

    useEffect(() => {
      _setErrorMessage(errorMessageFromProps)
    }, [errorMessageFromProps])

    const handleRemoveIconClick = useCallback(() => {
      onChange('')
    }, [onChange])

    const handleFocus = useCallback(() => {
      setInputActiveState(true)
    }, [])

    const handleKeyPress = useCallback(
      e => {
        if (e.key === 'Enter') {
          e.target.blur()
        }

        typeof onKeyPress === 'function' && onKeyPress(e)
      },
      [onKeyPress]
    )

    const handleBlur = useCallback(
      e => {
        onBlur && onBlur(e)
        setInputActiveState(false)
        _setErrorMessage('')
      },
      [onBlur, onChange, isRequired, defaultValue, timerRef]
    )

    const handleChange = useCallback(
      e => {
        const { value } = e.target
        if (value.length > charLimit) {
          const charLimitMessage = getCharMaxLimitMessage(charLimit)
          _setErrorMessage(charLimitMessage)
          return
        }

        onChange && onChange(value, e)
        _setErrorMessage('')
      },
      [charLimit, _setErrorMessage, onChange]
    )

    const handlePaste = useCallback(
      e => {
        e.preventDefault()
        const copiedText = e.clipboardData.getData('text')
        const selected = document.getSelection().toString()
        const replacedText = selected
          ? value.replace(selected, copiedText)
          : `${value || ''}${copiedText}`

        const currentText = `${replacedText}`.slice(0, charLimit)

        if (currentText.length >= charLimit) {
          const charLimitMessage = getCharMaxLimitMessage(charLimit)

          _setErrorMessage(charLimitMessage)
        }

        onChange && onChange(currentText)
      },
      [value, _setErrorMessage, onChange]
    )

    return (
      <Styled.InputContainer className="input-container">
        {label ? (
          <Styled.Label>{isRequired ? <>{label} *</> : label}</Styled.Label>
        ) : null}
        <Styled.InputWrapper isTextArea={isTextArea}>
          {isTextArea ? (
            <Styled.TextArea
              ref={ref}
              value={value}
              disabled={disabled}
              className={className}
              placeholder={placeholder}
              isInputActive={isInputActive}
              hasError={!!errorMessage}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onPaste={handlePaste}
            />
          ) : (
            <Styled.Input
              ref={ref}
              type={type}
              value={value}
              disabled={disabled}
              className={className}
              placeholder={placeholder}
              isInputActive={isInputActive}
              hasError={!!errorMessage}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onPaste={handlePaste}
              onKeyPress={handleKeyPress}
            />
          )}
          {isRemoveIconVisible ? (
            <div
              onMouseDown={handleRemoveIconClick}
              onTouchStart={handleRemoveIconClick}
            >
              <Icon
                className="remove-icon"
                name="cancel"
                size="medium"
                color="#9AA6C8"
              />
            </div>
          ) : null}
        </Styled.InputWrapper>
        {errorMessage ? (
          <Styled.ErrorMessage className="error-message">
            <AttentionIcon />
            {errorMessage}
          </Styled.ErrorMessage>
        ) : null}
        {charLimit ? (
          <Styled.CharLimit hasExceedLimit={!!errorMessage}>
            {value.length}/{charLimit}
          </Styled.CharLimit>
        ) : null}
      </Styled.InputContainer>
    )
  }
)

export default memo(Input)
