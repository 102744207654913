//@flow

import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { selectIsWebsiteMultilingual } from '@selectors'

import MultilingualContent from './MultilingualContent'
import MonolingualContent from './MonolingualContent'
import { TTranslationProps } from './types'

const Translation = (props: TTranslationProps) => {
  const isWebsiteMultilingual = useSelector(selectIsWebsiteMultilingual)

  return isWebsiteMultilingual ? (
    <MultilingualContent {...props} />
  ) : (
    <MonolingualContent />
  )
}

export default memo(Translation)
