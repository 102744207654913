//@flow
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { connect } from 'react-redux'
import { setEventSuccessMessage, updateEventData } from '@actions/events'
import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { omit } from '@editor/common/utils'
import { translate } from '@editor/common/utils/translations'
import { useResponsive } from '@shared/hooks'
import { selectEventSuccessMessage, selectFormData } from '@selectors'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'
import ContentTitle from '../../components/ContentTitle'
import { TabContainer, MainSection, PreviewSlideLeft } from '../styled'
import { SINGLE_FIELD_KEYS_TO_OMIT } from './consts'
import { INPUT } from './DynamicFields/consts'
import LeftSection from './LeftSection'
import RightSection from './RightSection'
import * as Styled from './styled'
import MobilePreviewButton from '../MobilePreviewButton'
import MobilePreviewHeader from '../MobilePreviewHeader'

import type { TFormProps } from './types'

const Forms = ({
  eventForm,
  successMessage,
  updateEventData,
  setHasChangesInTab,
  closePopup,
  setEventSuccessMessage
}: TFormProps) => {
  let dynamicFieldsRef = useRef([])
  const { dynamicFields: _dynamicFields, ...rest } = eventForm

  const [staticFields, setStaticFields] = useState({ ...rest })
  const [dynamicFields, setDynamicFields] = useState(_dynamicFields)

  const omitedDynamicFields = useMemo(
    () =>
      dynamicFields.map(field =>
        field.type === INPUT ? omit(field, SINGLE_FIELD_KEYS_TO_OMIT) : field
      ),
    [dynamicFields]
  )

  useEffect(() => {
    setDynamicFields(_dynamicFields)
  }, [_dynamicFields])

  const hasChanges =
    JSON.stringify({
      dynamicFields: omitedDynamicFields,
      ...staticFields
    }) !== JSON.stringify(eventForm)

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  const onSaveBtnClick = useCallback(() => {
    if (!omitedDynamicFields.length) {
      dynamicFieldsRef.current = []
    }

    updateEventData(
      { dynamicFields: omitedDynamicFields, ...staticFields },
      'form'
    )
  }, [
    omitedDynamicFields,
    staticFields,
    updateEventData,
    dynamicFieldsRef.current
  ])

  const isTabletOrMobile = useResponsive(1024)
  const [isMobilePreviewOpen, setMobilePreviewState] = useState(false)

  const { formErrors, register, handleSubmit } =
    useFormValidation(onSaveBtnClick)

  return (
    <TabContainer>
      <Styled.Content>
        <ContentTitle
          title={translate('event_forms_label')}
          subTitle={translate('event_forms_subtitle')}
        />
        <MainSection>
          <LeftSection
            dynamicFieldsRef={dynamicFieldsRef}
            register={register}
            formErrors={formErrors}
            dynamicFields={dynamicFields}
            staticFields={staticFields}
            setStaticFields={setStaticFields}
            setDynamicFields={setDynamicFields}
          />
          {isTabletOrMobile && !isMobilePreviewOpen ? (
            <MobilePreviewButton onClick={() => setMobilePreviewState(true)} />
          ) : null}
          {!isTabletOrMobile ? (
            <RightSection
              formData={{ ...staticFields, dynamicFields }}
              formDataFromProps={{
                dynamicFields: omitedDynamicFields,
                ...staticFields
              }}
            />
          ) : null}
        </MainSection>
      </Styled.Content>
      <SaveAndDiscardFooter
        successMessage={successMessage}
        isSaveBtnDisabled={!hasChanges}
        onDiscard={closePopup}
        onSave={handleSubmit}
        setSuccessMessage={setEventSuccessMessage}
      />
      <PreviewSlideLeft isOpen={isMobilePreviewOpen && isTabletOrMobile}>
        <MobilePreviewHeader onClick={() => setMobilePreviewState(false)} />
        <RightSection
          formData={{ ...staticFields, dynamicFields }}
          formDataFromProps={{
            dynamicFields: omitedDynamicFields,
            ...staticFields
          }}
        />
      </PreviewSlideLeft>
    </TabContainer>
  )
}

const mapStateToProps = state => ({
  eventForm: selectFormData(state),
  successMessage: selectEventSuccessMessage(state)
})

export default connect(mapStateToProps, {
  updateEventData,
  setEventSuccessMessage
})(memo(Forms))
