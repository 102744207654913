import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'
import { Content as CommonContent } from '../../styled'

export const DropdownItem = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${secondary.SemiDark};

  & > img {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #dee3f0;
    box-sizing: unset;
  }

  &:hover {
    color: ${secondary.ExtraDark};
  }
`

export const MainSection = styled.div`
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 768px;
    margin-right: auto;
    margin-left: auto;
  }
`

export const Content = styled(CommonContent)`
  @media only screen and (min-width: 1025px) {
    overflow-y: auto;
  }
`
