//@flow
import React, { memo, useCallback, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { isSubscriber } from '@selectors'
import { UpgradePopupContext } from '@contexts'
import { generateUniqId } from '@editor/common/utils'
import { translate } from '@editor/common/utils/translations'
import LibInput from '@editor/common/lib/Input'
import Validatable from '@website/common/lib/ValidatableHOC'
import { isValidEmail } from '@website/common/utils'

import CommonTitleWrapper from './CommonTitleWrapper'
import {
  notificationsSubTitle,
  notificationsTitle,
  NOTIFICATIONS_RADIO_DATA,
  EMAIL_NOTIFICATION_PLACEHOLDER,
  EMAIL_NOTIFICATION_LABEL
} from './consts'
import * as Styled from './styled'
import type { TEmailNotificationsProps } from './types'

const Input = Validatable(LibInput)

const EmailNotifications = ({
  emailNotification,
  setData,
  email,
  setEmail,
  formErrors,
  register
}: TEmailNotificationsProps) => {
  const [keyForRadio, setKeyForRadio] = useState(generateUniqId())
  const isUserSubscribed = useSelector(isSubscriber)
  const { setUpgradePopupState } = useContext(UpgradePopupContext)
  const _emailNotification = isUserSubscribed ? emailNotification : false

  const onRadioChange = useCallback(
    (e, value) => {
      const _value = JSON.parse(value)
      if (!isUserSubscribed && _value) {
        setUpgradePopupState({ isOpen: true })
        setKeyForRadio(generateUniqId())
        return
      }
      setData('emailNotification', _value)
    },
    [isUserSubscribed]
  )

  // To fix radio component issue
  const radioValue = JSON.stringify(_emailNotification)
  return (
    <Styled.EmailNotifications>
      <CommonTitleWrapper
        tooltipText={translate('event_settings_notifications_tooltip')}
        title={notificationsTitle}
        subTitle={notificationsSubTitle}
      />
      <Styled.RadioWrapper>
        <Styled.Radio
          key={keyForRadio}
          onChange={onRadioChange}
          checked={radioValue}
          list={NOTIFICATIONS_RADIO_DATA}
        />
      </Styled.RadioWrapper>
      {_emailNotification ? (
        <Input
          isRequired
          value={email}
          errorMessage={formErrors.email}
          placeholder={EMAIL_NOTIFICATION_PLACEHOLDER}
          label={EMAIL_NOTIFICATION_LABEL}
          className="email-notification"
          {...register({
            fieldName: 'email',
            initialValue: email,
            onChange: setEmail,
            validationOptions: {
              isRequired: true,
              isEmail: true
            }
          })}
        />
      ) : null}
      <Styled.Line />
    </Styled.EmailNotifications>
  )
}
export default memo(EmailNotifications)
