import styled, { css } from 'styled-components'

import { secondary } from '@website/common/styles/colors'

export const Container = styled.div`
  ${({ withUnderline }) =>
    withUnderline
      ? css`
          border-bottom: 1px solid ${secondary.Light};
        `
      : ''}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;

  @media only screen and (max-width: 650px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Title = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: ${secondary.ExtraDark};
  @media only screen and (max-width: 1024px) {
    max-width: 688px;
  }
`

export const SubTitle = styled.div`
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  color: ${secondary.SemiDark};
  @media only screen and (max-width: 1024px) {
    max-width: 688px;
  }
`
