import styled, { css } from 'styled-components'

import {
  accentRed,
  primary,
  secondary,
  white
} from '@website/common/styles/colors'

export const InputContainer = styled.div`
  position: relative;
`

export const InputWrapper = styled.div`
  position: relative;

  .remove-icon {
    position: absolute;
    right: 10px;
    ${({ isTextArea }) =>
      isTextArea
        ? css`
            top: 11px;
          `
        : css`
            bottom: 11px;
          `};
    cursor: pointer;

    &:hover {
      svg {
        fill: ${secondary.SemiDark};
      }
    }
  }
`

const getInputStyles = ({ hasError, isInputActive }) =>
  css`
    width: 100%;
    font-size: 14px;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    background-color: ${white};
    color: inherit;
    padding: ${isInputActive ? '10px 35px 10px 15px' : '10px 15px'};
    ::placeholder {
      color: #8493bd;
    }
    border: 1px solid
      ${hasError ? accentRed : isInputActive ? primary.Dark : secondary.Light};

    &:disabled {
      background-color: #edeff6;
      color: ${secondary.Light};
      opacity: 0.5;
    }

    &:hover {
      &:not(:disabled) {
        border: 1px solid
          ${hasError ? accentRed : isInputActive ? primary.Dark : '#8493BD'};
      }
    }
  `

export const Input = styled.input`
  ${getInputStyles};
  height: 40px;
`

export const TextArea = styled.textarea`
  ${getInputStyles};
  resize: none;
  height: 150px;
  width: 100%;
`

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 5px;
  color: ${secondary.SemiDark};
  font-size: 14px;
`

export const CharLimit = styled.div`
  position: absolute;
  right: 2px;
  color: ${({ hasExceedLimit }) => (hasExceedLimit ? accentRed : '#8493BD')};
  padding-top: 5px;
  font-size: 12px;
`

export const ErrorMessage = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  color: ${accentRed};
  padding-top: 5px;
  max-width: 400px;

  @media only screen and (max-width: 568px) {
    max-width: 200px;
  }

  svg {
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    padding-right: 5px;

    & * {
      fill: ${accentRed};
    }
  }
`
