import styled, { css } from 'styled-components'

import { primary, secondary } from '@website/common/styles/colors'

export const DatePickerInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 458px) {
    & > div:not(:last-child) {
      margin-right: 20px;
    }
  }
  @media only screen and (max-width: 457px) {
    flex-direction: column;
    > div {
      margin-bottom: 20px;
    }
  }
`

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 5px;
  color: ${secondary.SemiDark};
  font-size: 14px;
`

export const getInputStyles = ({ isInputActive }) => css`
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 14px;
  color: ${secondary.ExtraDark};
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${isInputActive ? primary.Dark : secondary.Light};
  outline: none;

  &:hover {
    border: 1px solid ${isInputActive ? primary.Dark : '#8493bd'};
  }
`

export const DatePickerContainer = styled.div`
  width: 100%;
`

export const DatePickerWrapper = styled.div`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;

    .date-picker-input {
      ${getInputStyles};
    }
  }

  & > svg {
    pointer-events: none;
    position: absolute;
    z-index: 2;
    top: 8px;
    right: 10px;
    fill: ${({ isInputActive }) =>
      isInputActive ? secondary.ExtraDark : secondary.SemiDark};
  }

  &:hover {
    & > svg {
      fill: ${secondary.ExtraDark};
    }
  }
`
