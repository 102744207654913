import { translate } from '@editor/common/utils/translations'

export const INPUT = 'input'
export const CHECKBOX = 'checkbox'
export const RADIO = 'radio'

const TEXT_INPUT_LABEL = translate('event_text_input_label')
const CHECKBOX_LABEL = translate('event_checkbox_label')
const RADIO_LABEL = translate('event_radio_label')

export const RESPONSE_TYPES = {
  [INPUT]: {
    value: INPUT,
    label: TEXT_INPUT_LABEL,
    component: TEXT_INPUT_LABEL
  },
  [CHECKBOX]: {
    value: CHECKBOX,
    label: CHECKBOX_LABEL,
    component: CHECKBOX_LABEL
  },
  [RADIO]: {
    value: RADIO,
    label: RADIO_LABEL,
    component: RADIO_LABEL
  }
}

export const RESPONSE_TYPES_WITH_OPTIONS = [RADIO, CHECKBOX]

export const RESPONSE_TYPES_LIST_ITEMS = Object.values(RESPONSE_TYPES)
