import styled from 'styled-components'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import { secondary } from '@website/common/styles/colors'

const BORDER_COLOR = '#8493BD'
const HEADER_HEIGHT = 60

export const EventPopup = styled(Popup)`
  z-index: 50;
  height: 100%;
  & > div {
    padding: 0;
    max-height: 90%;
    width: 95%;
    max-width: 1400px;
    height: 90%;
    & > div {
      padding: 0;
    }

    & > button {
      z-index: 5;
    }
  }

  @media only screen and (max-width: 1024px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    margin-top: 90px;
  }
`

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  border-right: 1px solid ${BORDER_COLOR};
  flex-shrink: 0;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    border: none;
    position: absolute;
    top: 0;
    background: #fff;
    z-index: 4;
    border-right: none;
    border-bottom: 1px solid ${secondary.Light};
    ul {
      flex-wrap: nowrap;
      max-width: 688px;
      margin-top: 30px;
      margin-right: auto;
      margin-left: auto;
      @media only screen and (min-width: 601px) {
        li {
          div {
            margin-right: 20px;
            margin-left: 20px;
          }
        }
      }
    }
    .right > div,
    .left > div {
      margin-top: 25px;
    }
  }
`

const Header = styled.div`
  min-height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${BORDER_COLOR};
`

export const SidebarHeader = styled(Header)`
  & > h3 {
    color: ${secondary.ExtraDark};
    font-size: 16px;
    font-weight: 600;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media only screen and (min-width: 1025px) {
    overflow: hidden;
  }
`

export const ContentHeader = styled(Header)`
  border-radius: 0 10px 0 0;
  z-index: 1;
`
