// @flow

import React, { useCallback, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { translate } from '@editor/common/utils/translations'
import { PAGE_BASE } from '@editor/conf/routes'
import { useHistoryWithSearch, usePopupCustomMouseDown } from '@hooks'
import { useResponsive } from '@shared/hooks'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import {
  selectHasWebsiteEvent,
  selectEventLoadingState,
  selectIsWebsiteMultilingual
} from '@selectors'
import { RfLoader } from '@editor/common/components/Loaders'

import Content from './content'
import SidebarMenu from './sidebar-menu'
import * as Styled from './styled'
import type { TEventProps } from './types'

const Event = ({
  isWebsiteMultilingual,
  isEventsLoading,
  hasWebsiteEvent
}: TEventProps) => {
  const [hasChangesInTab, setHasChangesInTab] = useState(false)

  const popupRef = useRef(null)

  const { url } = useRouteMatch(PAGE_BASE)
  const history = useHistoryWithSearch()

  const isMobileOrTablet = useResponsive(1024)

  const closePopup = useCallback(() => {
    history.push(url)
  }, [url])

  const onClose = useDiscardDialog(closePopup, {
    id: 'event',
    hasChanges: hasChangesInTab
  })

  usePopupCustomMouseDown(popupRef, '.event-popup', onClose)

  return (
    <Styled.EventPopup
      isOpen
      onClose={onClose}
      isMobileFullScreen
      className="event-popup"
    >
      <RfLoader
        isVisible={isEventsLoading}
        text={translate('saving_your_changes_label')}
      />
      <Styled.Container ref={popupRef}>
        <Styled.SidebarWrapper>
          {!isMobileOrTablet ? (
            <Styled.SidebarHeader>
              <h3>{translate('event_settings')}</h3>
            </Styled.SidebarHeader>
          ) : null}
          <SidebarMenu
            isWebsiteMultilingual={isWebsiteMultilingual}
            hasChangesInTab={hasChangesInTab}
          />
        </Styled.SidebarWrapper>
        <Styled.ContentWrapper>
          {!isMobileOrTablet ? <Styled.ContentHeader /> : null}
          <Content
            hasWebsiteEvent={hasWebsiteEvent}
            setHasChangesInTab={setHasChangesInTab}
            closePopup={onClose}
          />
        </Styled.ContentWrapper>
      </Styled.Container>
    </Styled.EventPopup>
  )
}

const mapStateToProps = state => ({
  isWebsiteMultilingual: selectIsWebsiteMultilingual(state),
  isEventsLoading: selectEventLoadingState(state),
  hasWebsiteEvent: selectHasWebsiteEvent(state)
})

export default connect(mapStateToProps)(Event)
