//@flow
import React, { memo, useCallback } from 'react'
import produce from 'immer'

import { Tabs } from '@editor/common/components/Tabs'
import { useResponsive } from '@shared/hooks'
import type { TLeftSectionProps } from './types'
import * as Styled from './styled'
import { getTabsData } from './utils'
import ActiveTabContent from './ActiveContent'

export const LeftSection = ({
  data,
  activeTab,
  formErrors,
  register,
  setData,
  setActiveTab
}: TLeftSectionProps) => {
  const isTabletOrMobile = useResponsive(1024)

  const onInputChange = useCallback(
    (key, tabName) => value => {
      setData(
        produce(draft => {
          draft[tabName][key].text = value
        })
      )
    },
    []
  )

  const onCheckBoxChange = useCallback(() => {
    setData(
      produce(draft => {
        draft.yes.showButton = !draft.yes.showButton
      })
    )
  }, [])

  return (
    <Styled.LeftSection>
      {!isTabletOrMobile ? (
        <Tabs
          className="popups-tabs"
          activeTab={activeTab}
          data={getTabsData(formErrors)}
          setActiveTab={setActiveTab}
        />
      ) : null}
      <ActiveTabContent
        isVisible={activeTab === 'yes' || isTabletOrMobile}
        data={data.yes}
        tabName="yes"
        formErrors={formErrors}
        register={register}
        onInputChange={onInputChange}
        onCheckBoxChange={onCheckBoxChange}
      />
      <ActiveTabContent
        isVisible={activeTab === 'no' || isTabletOrMobile}
        data={data.no}
        tabName="no"
        formErrors={formErrors}
        register={register}
        onInputChange={onInputChange}
      />
      <ActiveTabContent
        isVisible={activeTab === 'closed' || isTabletOrMobile}
        data={data.closed}
        tabName="closed"
        formErrors={formErrors}
        register={register}
        onInputChange={onInputChange}
      />
    </Styled.LeftSection>
  )
}

export default memo(LeftSection)
