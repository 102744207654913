import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'

export const MapContainer = styled.div`
  padding: 30px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid ${secondary.Light};

  & > div {
    margin-top: 20px;
  }

  .checkbox {
    margin: 0;
    display: inline-flex;
  }
`
