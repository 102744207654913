export const getDropdownList = timeZonesData =>
  timeZonesData.map(timeZoneData => {
    const timeZone = `(${timeZoneData.offset}) ${timeZoneData.name}`

    return {
      component: timeZone,
      label: timeZone,
      value: timeZone
    }
  })
