import styled, { css } from 'styled-components'

import { getDisabledStyles } from '@editor/common/lib/Button/styled'
import { secondary, white } from '@website/common/styles/colors'

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (min-width: 1101px) {
    margin-top: 10px;
  }
  & > div:first-child {
    @media only screen and (min-width: 1201px) {
      margin-right: 10px;
    }
    @media only screen and (max-width: 1200px) {
      margin-left: 10px;
    }
  }
  > div:nth-child(2) {
    button {
      @media only screen and (max-width: 1200px) {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        position: absolute;
        right: 40px;
        bottom: 20px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        box-shadow: 0px 4px 4px rgba(37, 46, 72, 0.1);
        > span {
          display: none;
        }
        & > svg {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 650px) {
        right: 20px;
      }
    }
  }
`

export const DownloadBtn = styled.button`
  background-color: ${white};
  display: flex;
  padding: 7px;
  border: 1px solid ${secondary.Light};
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  & > svg {
    fill: #8493bd;
    transform: rotate(180deg);
  }
  ${({ disabled }) =>
    disabled
      ? css`
          ${getDisabledStyles};
        `
      : ''}

  &:hover {
    & > svg {
      fill: ${secondary.SemiDark};
    }
  }
  @media only screen and (max-width: 1100px) and (min-width: 769px) {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    position: absolute;
    right: 40px;
    bottom: 80px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: #eef5ff;
    box-shadow: 0px 4px 4px rgba(37, 46, 72, 0.1);
    border: none;
    & > svg {
      fill: #3271e6;
    }
  }
`
