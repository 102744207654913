import styled, { css } from 'styled-components'
import { secondary, primary } from '@website/common/styles/colors'
import ArrowTop from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'

const borderStyles = `1px solid ${secondary.Light}`
const svgColor = '#8493BD'

export const TableContainer = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  ${({ isEmptyContentVisible }) =>
    isEmptyContentVisible
      ? css`
          height: 100%;
        `
      : ''}
  & .circle {
    transform: scale(0.8);
  }

  .circle-loader {
    z-index: 5;
  }
`

export const TableWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  ${({ hasGuests }) =>
    hasGuests
      ? css`
          @media only screen and (min-width: 1201px) {
            border-bottom: ${borderStyles};
          }
        `
      : css`
          @media only screen and (min-width: 1025px) {
            border-bottom: ${borderStyles};
          }
        `}
`

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 12px;
  color: ${secondary.ExtraDark};
  ${({ hasGuests }) =>
    hasGuests
      ? css`
          @media only screen and (max-width: 1200px) {
            margin-bottom: 90px;
            border-bottom: ${borderStyles};
          }

          @media only screen and (max-width: 1024px) {
            margin-bottom: 100px;
          }
        `
      : css`
          + div {
            @media only screen and (max-width: 1024px) {
              width: 900px;
              overflow-x: auto;
            }
            @media only screen and (max-width: 550px) {
              width: 600px;
            }
          }
        `}
  @media only screen and (max-width: 1024px) {
    width: 900px;
    overflow-x: auto;
  }
  @media only screen and (max-width: 550px) {
    width: 600px;
  }
  & thead > tr {
    position: sticky;
    top: 0;
    box-shadow: 0px 3px 6px rgba(50, 113, 230, 0.1);
  }

  & th {
    border-top: ${borderStyles};
    border-bottom: ${borderStyles};
    border-right: ${borderStyles};
    background-color: white;
    padding: 10px 15px;
    text-align: left;
    font-weight: 600;
  }

  & td {
    border-bottom: ${borderStyles};
    border-right: ${borderStyles};
    padding: 18px 15px;
    text-align: left;
  }

  & th:first-child,
  & td:first-child {
    border-left: ${borderStyles};
  }
  & tbody tr:last-child td {
    border-bottom: none;
  }

  & .full-name-column {
    width: 280px;
  }
  & .contacts-column {
    width: 250px;
  }
`

export const TableRow = styled.tr`
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? primary.Light : 'white')};
  @media only screen and (max-width: 1024px) {
    background-color: white;
  }
  &:hover {
    background-color: ${primary.Light};
  }
`

export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    > div {
      margin-right: 32px;
    }
  }
`

export const ActionButton = styled.div`
  cursor: pointer;
  transition: transform 300ms ease-in;
  @media only screen and (max-width: 768px) {
    margin: 0 16px;
    line-height: 1;
  }

  ${({ isActive }) =>
    isActive
      ? css`
          &.arrow-button {
            transform: rotate(90deg);
          }
        `
      : css`
          &.arrow-button {
            transform: rotate(270deg);
          }
        `}
  &.arrow-button {
    margin-right: 10px;
  }
  & svg {
    width: 16px;
    height: auto;
    fill: ${svgColor};
  }
  &:hover {
    & svg {
      fill: ${secondary.SemiDark};
    }
  }
`

export const Flag = styled.img`
  width: 16px;
  height: auto;
  border-radius: 2px;
`

export const TableHeadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`

export const SortButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
  height: 11px;
`

export const SortArrowIcon = styled(ArrowTop)`
  fill: ${({ isActive }) => (isActive ? secondary.SemiDark : svgColor)};
  width: 5px;
  height: auto;
`

export const SortArrowTopIcon = SortArrowIcon

export const SortArrowBottomIcon = styled(SortArrowIcon)`
  transform: rotate(180deg);
`

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1025px) {
    border-left: ${borderStyles};
    border-right: ${borderStyles};
  }
  & > img {
    width: 96px;
    height: auto;
  }
  & > p {
    margin-top: 5px;
    font-size: 14px;
    color: ${secondary.Normal};
    text-align: center;
    max-width: 280px;
  }
`

export const PaginationLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  border: ${borderStyles};
  border-top: none;
`

export const GuestListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const GuestListRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: ${borderStyles};
  ${({ isActive }) =>
    isActive
      ? css`
          &:not(:last-child) {
            border-bottom: none;
          }
        `
      : ''}
`

export const GuestListName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${secondary.SemiDark};
  width: 40%;
  word-break: break-all;
  padding-right: 10px;
`

export const GuestListResponse = styled.div`
  min-width: 46px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  border-left: ${borderStyles};
  border-right: ${borderStyles};
`

export const GuestListInfo = styled.div`
  display: flex;
  align-items: center;
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    > div {
      border-bottom: none;
    }
  }
`
