import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'

export const SuccessPopupContainer = styled.div`
  text-align: center;
  min-width: 275px;
  .title {
    font-size: 20px;
    color: ${secondary.Dark};
    font-weight: 600;
    margin-bottom: 10px;
  }

  .info {
    font-size: 16px;
    color: ${secondary.SemiDark};
  }

  & > button {
    margin: 30px 0 0;
  }
`

export const Image = styled.img`
  width: 110px;
  height: 110px;
  display: block;
  margin: 0 auto 10px;
  animation: scale-image 700ms ease-out;
  @keyframes scale-image {
    0% {
      transform: scale(0.5);
    }
    90% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`
