import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { PAGE_BASE } from '@editor/conf/routes'
import { RSVP_MULTILINGUAL_HINT_UPGRADE_REFERRER } from '@editor/conf/consts'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import { translate } from '@editor/common/utils/translations'
import { SectionComp } from '@editor/common/styled-components/simple-tags'
import { useHistoryWithSearch } from '@hooks'
import { isSubscriber } from '@selectors'

import { GO_MULTILINGUAL_ILLUSTRATION, GO_MULTILINGUAL_VIDEO } from './consts'
import * as Styled from './styled'

const MonolingualContent = () => {
  const isPaidUser = useSelector(isSubscriber)

  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(PAGE_BASE)

  const navigateToLangSettings = useCallback(() => {
    history.push(`${url}/settings/languages`)
  }, [history, url])

  return (
    <Styled.Container>
      <Styled.Content>
        {isPaidUser ? (
          <>
            <Styled.Illustration
              src={GO_MULTILINGUAL_ILLUSTRATION}
              alt="go-multilingual"
            />
            <Styled.Title>
              {translate('set_up_the_languages_of_your_website')}
            </Styled.Title>
            <Styled.Description hasMarginFromTop>
              {translate('go_multilingual_description')}
            </Styled.Description>
            <Styled.GoMultilingualBtn onClick={navigateToLangSettings}>
              {translate('go_multilingual_label')}
            </Styled.GoMultilingualBtn>
          </>
        ) : (
          <>
            <Styled.Title>
              {transformTextVariables(
                translate('go_global_go_multilingual_label'),
                [
                  {
                    value: (
                      <Styled.GradientText>
                        {translate('go_global_go_multilingual_label_tr1')}
                      </Styled.GradientText>
                    ),
                    Comp: SectionComp
                  }
                ]
              )}
            </Styled.Title>
            <Styled.Banner
              playsInline
              autoPlay
              muted
              loop
              src={GO_MULTILINGUAL_VIDEO}
            />
            <Styled.Description>
              {translate(
                'expand_your_audience_by_translating_your_event_label'
              )}
            </Styled.Description>
            <Styled.UpgradeButton
              referer={RSVP_MULTILINGUAL_HINT_UPGRADE_REFERRER}
            />
          </>
        )}
      </Styled.Content>
    </Styled.Container>
  )
}

export default MonolingualContent
