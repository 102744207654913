import { translate } from '@editor/common/utils/translations'

export const STATIC_LABELS_TRANSLATIONS = {
  details: {
    title: translate('title_label'),
    subTitle: translate('subtitle_label'),
    paragraph: translate('paragraph_label'),
    button: translate('rsvp_button_title')
  },
  popups: {
    yes_title: translate('yes_title_label'),
    yes_paragraph: translate('yes_paragraph_label'),
    yes_buttonText: translate('link_button_label'),
    no_title: translate('no_title_label'),
    no_paragraph: translate('no_paragraph_label'),
    closed_title: translate('closed_title_label'),
    closed_paragraph: translate('closed_paragraph_label')
  },
  form: {
    rsvpOption: translate('rsvp_option_title'),
    rsvpOption_yes: translate('yes_label'),
    rsvpOption_no: translate('no_label'),
    firstName: translate('first_name_label'),
    lastName: translate('last_name_label'),
    email: translate('email_label'),
    phone: translate('phone_label'),
    guestBtn: translate('link_button_title'),
    submitBtn: translate('submit_button_label')
  }
}

export const TABS_TRANSLATIONS = {
  details: translate('event_details_label'),
  popups: translate('event_popups_label'),
  form: translate('event_forms_label')
}

export const DYNAMIC_LABELS_TRANSLATION = {
  question: translate('question_label'),
  answer: translate('answer_label')
}
