import styled, { css } from 'styled-components'

import LibDropdown from '@editor/common/lib/Dropdown'

export const Filters = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (min-width: 1101px) {
    max-width: ${({ isLanguageFilterVisible }) =>
      isLanguageFilterVisible ? '620px' : '410px'};
  }
  & > div:not(:last-child) {
    margin-right: 10px;
  }
  & > div:first-child {
    width: 200px;
    flex-shrink: 0;
    flex-grow: 1;
    max-width: 410px;
    margin-top: 10px;
    @media only screen and (max-width: 1100px) {
      width: 100%;
      max-width: initial;
      margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
      width: auto;
      margin-top: 0;
      flex-shrink: 1;
    }
  }
  @media only screen and (max-width: 1100px) and (min-width: 769px) {
    flex-wrap: wrap;
    width: 100%;
    min-width: 100%;
    max-width: unset;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const GuestFiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 200px;
    margin-top: 10px;
  }
  ${({ isLanguageFilterVisible }) =>
    isLanguageFilterVisible
      ? css`
          min-width: 410px;
          > div {
            width: calc(50% - 5px);
            max-width: unset;
          }
          @media only screen and (max-width: 1100px) {
            width: 100%;
          }
        `
      : css`
          width: 100%;
          > div {
            width: 200px;
            max-width: 200px;
            @media only screen and (max-width: 1100px) {
              width: 100%;
              max-width: unset;
            }
          }
        `};
  @media only screen and (max-width: 650px) {
    min-width: unset;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`

export const Dropdown = styled(LibDropdown)`
  .dropdown-wrapper-active {
    padding: 0 30px 0 15px;
  }
`
