/* @flow */
import React, { memo, useMemo } from 'react'
import { formatTime } from '@website/common/utils/event'
import { DEFAULT_TEXT, DEFAULT_DATE, DEFAULT_BUTTON_TEXT } from './consts'
import * as S from './styled'

const RSVPEmptyComponent = memo(({ detailsData }) => {
  const {
    subTitle,
    button,
    title,
    paragraph,
    startDateInfo,
    endDateInfo,
    timezone,
    location
  } = detailsData

  const eventDate = useMemo(() => {
    return (
      startDateInfo.date +
      ' | ' +
      formatTime(startDateInfo.time) +
      (endDateInfo.isVisible
        ? ' - ' +
          (endDateInfo.date !== startDateInfo.date
            ? endDateInfo.date + ' | '
            : '') +
          formatTime(endDateInfo.time) +
          ' ' +
          timezone.value.split(')')[0] +
          ')'
        : '')
    )
  }, [startDateInfo, endDateInfo, timezone])

  return (
    <S.ComponentWrap>
      <S.SubTitle className="WM_GLOBAL_secondary-font">
        {subTitle.text || DEFAULT_TEXT}
      </S.SubTitle>
      <S.Title className="WM_GLOBAL_primary-font">
        {title.text || DEFAULT_TEXT}
      </S.Title>
      <S.Paragraph className="WM_GLOBAL_secondary-font">
        {paragraph.text || DEFAULT_TEXT}
      </S.Paragraph>
      <S.DateInfo className="WM_GLOBAL_secondary-font">
        {eventDate || DEFAULT_DATE}
      </S.DateInfo>
      {location.isVisible ? (
        <S.Location className="WM_GLOBAL_secondary-font">
          {location.name || DEFAULT_TEXT}
        </S.Location>
      ) : null}
      <S.Button className="WM_GLOBAL_secondary-font">
        {button.text || DEFAULT_BUTTON_TEXT}
      </S.Button>
    </S.ComponentWrap>
  )
})

export default RSVPEmptyComponent

export { DEFAULT_COMP_DATA } from './consts'
