export const TITLE_LABEL_CHAR_LIMIT = 60
export const DETAILS_TITLE_LABEL_CHAR_LIMIT = 40
export const BUTTON_LABEL_CHAR_LIMIT = 19
export const POPUPS_PARAGRAPH_LABEL_CHAR_LIMIT = 200
export const DETAILS_PARAGRAPH_LABEL_CHAR_LIMIT = 100
export const RSVP_ANSWER_LABEL_CHAR_LIMIT = 30
export const ADDITIONAL_FIELD_LABEL_CHAR_LIMIT = 60
export const DETAILS_SUBTITLE_LABEL_CHAR_LIMIT = 100

export const LABELS_VALIDATIONS = {
  details: {
    title: {
      charLimit: DETAILS_TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    subTitle: {
      charLimit: DETAILS_SUBTITLE_LABEL_CHAR_LIMIT,
      isRequired: false
    },
    paragraph: {
      charLimit: DETAILS_PARAGRAPH_LABEL_CHAR_LIMIT,
      isRequired: false
    },
    button: {
      charLimit: BUTTON_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    locationName: {
      charLimit: DETAILS_SUBTITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    }
  },
  popups: {
    yes_title: {
      charLimit: TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    yes_paragraph: {
      charLimit: POPUPS_PARAGRAPH_LABEL_CHAR_LIMIT,
      isRequired: false
    },
    yes_buttonText: {
      charLimit: BUTTON_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    no_title: {
      charLimit: TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    no_paragraph: {
      charLimit: POPUPS_PARAGRAPH_LABEL_CHAR_LIMIT,
      isRequired: false
    },
    closed_title: {
      charLimit: TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    closed_paragraph: {
      charLimit: POPUPS_PARAGRAPH_LABEL_CHAR_LIMIT,
      isRequired: false
    }
  },
  form: {
    rsvpOption: {
      charLimit: TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    rsvpOption_yes: {
      charLimit: RSVP_ANSWER_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    rsvpOption_no: {
      charLimit: RSVP_ANSWER_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    firstName: {
      charLimit: TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    lastName: {
      charLimit: TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    email: {
      charLimit: TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    phone: {
      charLimit: TITLE_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    guestBtn: {
      charLimit: ADDITIONAL_FIELD_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    question: {
      charLimit: ADDITIONAL_FIELD_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    answer: {
      charLimit: ADDITIONAL_FIELD_LABEL_CHAR_LIMIT,
      isRequired: true
    },
    submitBtn: {
      charLimit: BUTTON_LABEL_CHAR_LIMIT,
      isRequired: true
    }
  }
}
