//@flow
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectDetailsData } from '@selectors'

import { translate } from '@editor/common/utils/translations'
import PreviewIcon from '@editor/common/assets/svgr-icons/Preview_icon.svg'
import { useResponsive } from '@shared/hooks'

import { PopupCloseIconState } from '../../styled'
import {
  SlideLeft,
  Layer,
  ButtonWithIcon,
  ContentTitle,
  Divider
} from '../styled'
import usePreviewData from '../usePreviewData'
import {
  CONFIRMATION_EMAIL_DEFAULT_MESSAGE,
  EMAIL_DEFAULT_GREETING,
  REGRET_EMAIL_DEFAULT_MESSAGE
} from '../consts'
import { UPGRADE_BANNER, MOBILE_UPGRADE_BANNER, UPGRADE_LABEL } from './consts'
import Preview from './Preview'
import * as Styled from './styled'

const FreeUserView = () => {
  const [isConfirmationPreviewOpened, setConfirmationPreviewOpenedState] =
    useState(false)
  const [isRegretPreviewOpened, setRegretPreviewOpenedState] = useState(false)

  const detailsData = useSelector(selectDetailsData)
  const isMobile = useResponsive(600)

  const emailDefaultData = useMemo(
    () => ({
      title: detailsData.title.text,
      subject: detailsData.title.text,
      message: isRegretPreviewOpened
        ? REGRET_EMAIL_DEFAULT_MESSAGE
        : CONFIRMATION_EMAIL_DEFAULT_MESSAGE,
      greeting: EMAIL_DEFAULT_GREETING
    }),
    [detailsData, isRegretPreviewOpened]
  )

  const previewData = usePreviewData(isRegretPreviewOpened)

  const openConfirmationPreview = useCallback(() => {
    setConfirmationPreviewOpenedState(true)
  }, [])

  const openRegretPreview = useCallback(() => {
    setRegretPreviewOpenedState(true)
  }, [])

  const closePreview = useCallback(() => {
    isConfirmationPreviewOpened && setConfirmationPreviewOpenedState(false)
    isRegretPreviewOpened && setRegretPreviewOpenedState(false)
  }, [isConfirmationPreviewOpened, isRegretPreviewOpened])

  const isPreviewOpened = isConfirmationPreviewOpened || isRegretPreviewOpened

  return (
    <>
      <Styled.EmailBlockCard>
        <ContentTitle
          title={translate('confirmation_email_title')}
          subTitle={translate('confirmation_email_subtitle')}
          withUnderline={false}
        />
        <ButtonWithIcon onClick={openConfirmationPreview}>
          <PreviewIcon />
          {translate('preview_label')}
        </ButtonWithIcon>
        <Divider />
        <ContentTitle
          title={translate('regret_email_title')}
          subTitle={translate('regret_email_subtitle')}
          withUnderline={false}
        />
        <ButtonWithIcon onClick={openRegretPreview}>
          <PreviewIcon />
          {translate('preview_label')}
        </ButtonWithIcon>
        <Styled.UpgradeLabel src={UPGRADE_LABEL} alt="upgrade-label" />
      </Styled.EmailBlockCard>
      <Styled.UpgradeBanner
        title={translate('email_upgrade_banner_title')}
        paragraph={translate('email_upgrade_banner_paragraph')}
        bgImageUrl={isMobile ? MOBILE_UPGRADE_BANNER : UPGRADE_BANNER}
      />
      <SlideLeft
        isOpen={isPreviewOpened}
        onClose={closePreview}
        shouldCloseOnBackdropClick
        backdropDisablingSelectors={['.upgrade-popup']}
      >
        <Preview
          onClose={closePreview}
          {...emailDefaultData}
          {...previewData}
        />
      </SlideLeft>
      {isPreviewOpened ? (
        <>
          <Layer />
          <PopupCloseIconState />
        </>
      ) : null}
    </>
  )
}

export default FreeUserView
