import { translate } from '@editor/common/utils/translations'

export const YES = 'yes'

export const TABS_DESCRIPTIONS = {
  yes: translate('yes_description'),
  no: translate('no_description')
}

export const REGISTRATION_CLOSED_DESCRIPTION = translate(
  'registration_closed_description'
)

export const TABS_LABLES = {
  yes: translate('yes_label'),
  no: translate('no_label'),
  closed: translate('registration_closed_label')
}

export const CHECKBOX_DATA = [
  {
    label: translate('add_a_button_to_create_a_calendar_event')
  }
]
