// @flow
import React, { memo } from 'react'

import Button from '@editor/common/lib/Button'
import SemrushDependedPopup from '@editor/common/components/SemrushDependedPopup'
import SuccessSvg from '@website/common/assets/saved-icon.svg'
import { translate } from '@editor/common/utils/translations'

import { PRIMARY_MESSAGE, SECONDARY_MESSAGE } from './consts'
import type { TSuccessPopupProps } from './types'
import * as Styled from './styled'

const SuccessPopup = ({
  primaryMessage = PRIMARY_MESSAGE,
  secondaryMessage = SECONDARY_MESSAGE,
  successMessage,
  setSuccessMessage
}: TSuccessPopupProps) => {
  const handleClose = () => {
    setSuccessMessage('')
  }

  return (
    <SemrushDependedPopup isOpen={!!successMessage} onClose={handleClose}>
      <Styled.SuccessPopupContainer>
        <Styled.Image src={SuccessSvg} alt="success" />
        <p className="title">{primaryMessage}</p>
        <p className="info">{secondaryMessage}</p>
        <Button type="secondary" onClick={handleClose}>
          {translate('ok_got_it_label')}
        </Button>
      </Styled.SuccessPopupContainer>
    </SemrushDependedPopup>
  )
}

export default memo(SuccessPopup)
