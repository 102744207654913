import styled, { createGlobalStyle } from 'styled-components'

import { secondary } from '@website/common/styles/colors'
import { scrollBarStyles } from '@editor/common/styles'
import SlideLeft from '@editor/common/lib/SlideLeft'

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media only screen and (min-width: 1025px) {
    overflow-y: hidden;
  }
  @media only screen and (max-width: 1024px) {
    align-items: center;
    > div:nth-child(2) {
      justify-content: center;
      position: sticky;
      bottom: 0px;
      z-index: 3;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media only screen and (min-width: 1025px) {
    overflow-y: hidden;
    position: relative;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

export const MainSection = styled.div`
  display: flex;
  flex-grow: 1;
  @media only screen and (min-width: 1025px) {
    overflow-y: hidden;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`

export const LeftSection = styled.div`
  ${scrollBarStyles};
  width: 45%;
  padding: 30px 40px;
  box-sizing: border-box;
  @media only screen and (min-width: 1025px) {
    overflow-y: auto;
    border-right: 1px solid ${secondary.Light};
  }
  @media only screen and (max-width: 1100px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 728px;
  }
`

export const RightSection = styled.div`
  ${scrollBarStyles};
  width: 55%;
  background-color: #f8fbff;
  padding: 30px 50px;
  box-sizing: border-box;
  @media only screen and (min-width: 1025px) {
    overflow-y: auto;
  }
  @media only screen and (max-width: 1100px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 728px;
  }
`

export const PreviewButton = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #eef5ff;
  position: fixed;
  bottom: 80px;
  right: 40px;
  z-index: 3;
  box-shadow: 0px 4px 4px rgba(37, 46, 72, 0.1);
  cursor: pointer;
  @media only screen and (max-width: 457px) {
    right: 20px;
  }
  svg {
    fill: #3271e6;
  }
`

export const Back = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #545f7e;
  @media only screen and (max-width: 457px) {
    left: 20px;
  }
  .arrow-icon {
    fill: #545f7e;
    margin-right: 5px;
  }
`

export const PreviewHeader = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: white;
  border-bottom: 1px solid ${secondary.Light};
`

export const PreviewLabel = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${secondary.ExtraDark};
`
export const PreviewSlideLeft = styled(SlideLeft)`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8fbff;
  > div:nth-child(2) {
    max-width: 100%;
    max-width: 688px;
    overflow-y: auto;
    > div {
      box-shadow: none;
      > div:first-child {
        display: none;
      }
    }
  }
`

export const PopupCloseIconState = createGlobalStyle`
  .event-popup > div > button {
    display: none
  }
`
