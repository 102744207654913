import { translate } from '@editor/common/utils/translations'

export const getGuestsAnswerDropdownList = counts => {
  const { yes = 0, no = 0 } = counts
  const yesLabel = `${translate('yes_label')} (${yes})`
  const noLabel = `${translate('no_label')} (${no})`
  const allLabel = `${translate('all_guests_label')} (${yes + no})`

  return [
    {
      component: allLabel,
      label: allLabel,
      value: 'all'
    },
    {
      component: yesLabel,
      label: yesLabel,
      value: 'yes'
    },
    {
      component: noLabel,
      label: noLabel,
      value: 'no'
    }
  ]
}

export const getLanguageDropdownList = data => {
  const languages = data.map(({ name, code }) => {
    return {
      component: name,
      label: name,
      value: code
    }
  })

  const allLanguagesItemLabel = translate('all_languages_label')

  const allLanguagesItem = {
    component: allLanguagesItemLabel,
    label: allLanguagesItemLabel,
    value: 'all'
  }

  return [allLanguagesItem, ...languages]
}
