//@flow
import React, { memo, useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import MinusIcon from '@editor/common/assets/svgr-icons/event_minus_icon.svg'
import PlusIcon from '@editor/common/assets/svgr-icons/Plus_icon.svg'
import { selectRsvpGuestLimit, selectIsRsvpGuestUnlimited } from '@selectors'
import { UpgradePopupContext } from '@contexts'

import { allowTypeOnlyNumbers } from '../../../utils'
import CommonTitleWrapper from './CommonTitleWrapper'
import { guestNumberTitle, guestNumberSubTitle } from './consts'
import type { TGuestNumberProps } from './types'
import * as Styled from './styled'

const GuestNumber = ({ guestNumber, setData }: TGuestNumberProps) => {
  const { setUpgradePopupState } = useContext(UpgradePopupContext)
  const guestLimitByPlan = useSelector(selectRsvpGuestLimit)
  const isRsvpGuestUnlimited = useSelector(selectIsRsvpGuestUnlimited)

  const handleGuestNumberSet = useCallback(
    value => {
      if (value > guestLimitByPlan) {
        setUpgradePopupState({ isOpen: true, showUpgradeFlow: true })
        return
      }
      if (value < 0) {
        return
      }

      const _value = value === '' ? null : value
      setData('guestNumber', _value)
    },
    [guestLimitByPlan]
  )

  const onInputChange = useCallback(
    value => {
      const numValue = value ? parseInt(value, 10) : ''
      handleGuestNumberSet(numValue)
    },
    [handleGuestNumberSet]
  )

  const onPlusIconClick = useCallback(() => {
    const value = guestNumber || 0
    handleGuestNumberSet(value + 1)
  }, [guestNumber, handleGuestNumberSet])

  const onMinusIconClick = useCallback(() => {
    handleGuestNumberSet(guestNumber - 1)
  }, [guestNumber, handleGuestNumberSet])

  return (
    <>
      <CommonTitleWrapper
        title={guestNumberTitle}
        subTitle={guestNumberSubTitle}
        tooltipText={translate('event_settings_registration_tooltip')}
      />
      <Styled.GuestNumberInputWrapper>
        <Styled.Input
          isRemoveIconHidden
          type="number"
          value={guestNumber}
          placeholder={isRsvpGuestUnlimited ? translate('unlimited_label') : ''}
          label={translate('guest_number_label')}
          onChange={onInputChange}
          onKeyPress={allowTypeOnlyNumbers}
        />
        <Styled.ButtonsWrapper>
          <Styled.IconButton onClick={onPlusIconClick}>
            <PlusIcon />
          </Styled.IconButton>
          <Styled.IconButton onClick={onMinusIconClick}>
            <MinusIcon />
          </Styled.IconButton>
        </Styled.ButtonsWrapper>
      </Styled.GuestNumberInputWrapper>
    </>
  )
}

export default memo(GuestNumber)
