import styled from 'styled-components'

import _SlideLeft from '@editor/common/lib/SlideLeft'
import { white, secondary } from '@website/common/styles/colors'

import _ContentTitle from '../../components/ContentTitle'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    position: unset;
    padding: 20px;
  }
`

export const EmailBlockCard = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${secondary.Light};
  padding: 20px;
  box-sizing: border-box;
`

export const ButtonWithIcon = styled.button`
  display: flex;
  align-items: center;
  background-color: ${white};
  border-radius: 8px;
  border: 1px solid ${secondary.Light};
  font-size: 16px;
  font-weight: 600;
  color: ${secondary.SemiDark};
  margin-top: 20px;
  padding: 5px 16px 5px 12px;
  box-sizing: border-box;
  cursor: pointer;

  & > svg {
    fill: ${secondary.SemiDark};
    margin-right: 5px;
  }

  &:hover {
    border: 1px solid ${secondary.SemiDark};
  }
`
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${secondary.ExtraLight};
  margin: 30px 0;
`
export const SlideLeft = styled(_SlideLeft)`
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`

export const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${white};
  opacity: 0.6;
  z-index: 1;
`

export const TextHeader = styled.div`
  color: ${secondary.ExtraDark};
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid ${secondary.Light};
  padding: 24px;
  box-sizing: border-box;
`

export const ContentTitle = styled(_ContentTitle)`
  padding: 0;

  @media only screen and (max-width: 1024px) {
    & > div {
      max-width: unset;
    }
  }
`
