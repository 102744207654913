import styled from 'styled-components'

import LibRadio from '@renderforest/rf-ui-library/molecules/Radio'

const Radio = styled(LibRadio)`
  .radio-button {
    flex-shrink: 0;
  }

  .label-container {
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
  }
`

export default Radio
