//@flow

import React, { memo } from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import { PAGE_BASE } from '@editor/conf/routes'
import type { TContentProps } from './types'
import Translation from './Translation'
import GuestList from './GuestList'
import Settings from './Settings'
import Details from './Details'
import Popups from './Popups'
import Form from './Form'
import Email from './Email'

const Content = ({
  hasWebsiteEvent,
  setHasChangesInTab,
  closePopup
}: TContentProps) => {
  const { path, url } = useRouteMatch()
  const { url: pageBaseUrl } = useRouteMatch(PAGE_BASE)

  return hasWebsiteEvent ? (
    <Switch>
      <Route path={`${path}/details`}>
        <Details
          setHasChangesInTab={setHasChangesInTab}
          closePopup={closePopup}
        />
      </Route>
      <Route path={`${path}/forms`}>
        <Form setHasChangesInTab={setHasChangesInTab} closePopup={closePopup} />
      </Route>
      <Route path={`${path}/popups`}>
        <Popups
          setHasChangesInTab={setHasChangesInTab}
          closePopup={closePopup}
        />
      </Route>
      <Route path={`${path}/email`}>
        <Email
          setHasChangesInTab={setHasChangesInTab}
          closePopup={closePopup}
        />
      </Route>
      <Route path={`${path}/settings`}>
        <Settings
          setHasChangesInTab={setHasChangesInTab}
          closePopup={closePopup}
        />
      </Route>
      <Route path={`${path}/guest-list`}>
        <GuestList setHasChangesInTab={setHasChangesInTab} />
      </Route>
      <Route path={`${path}/translation`}>
        <Translation
          closePopup={closePopup}
          setHasChangesInTab={setHasChangesInTab}
        />
      </Route>
      <Redirect to={`${url}/details`} />
    </Switch>
  ) : (
    <Redirect to={pageBaseUrl} />
  )
}

export default memo(Content)
