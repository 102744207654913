import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const Content = styled.div`
  border: 1px solid #c1c9e0;
  border-radius: 5px;
`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px 40px;
  @media only screen and (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const SectionHeader = styled.div`
  padding: 15px 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${secondary.ExtraDark};
  background: #f8fbff;

  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:not(:first-child) {
    border-top: 1px solid #c1c9e0;
  }
  @media only screen and (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`

export const SectionItemsGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const SectionItem = styled.div`
  display: flex;
  padding: 20px 30px 40px;
  border-top: 1px solid ${secondary.Light};
  .input-container {
    width: 50%;
    margin-top: 20px;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    .input-container {
      width: 100%;
    }
    textarea {
      height: 80px;
    }
  }
`

export const Label = styled.span`
  max-width: 500px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  line-break: anywhere;
  color: ${secondary.ExtraDark};
`

export const Text = styled.span`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  line-break: anywhere;
  color: ${secondary.SemiDark};
  @media only screen and (max-width: 600px) {
    font-style: italic;
  }
  @media only screen and (min-width: 601px) {
    max-width: 500px;
  }
`

export const TranslateFromSection = styled.div`
  width: 60%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 601px) {
    max-width: 470px;
    margin-right: 30px;
  }
`

export const FromEmptyText = styled.span`
  opacity: 0.5;
`
