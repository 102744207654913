import React from 'react'

import * as Styled from './styled'
import { translate } from '@editor/common/utils/translations'

export const SELECT_ALL_CHECKBOX_DATA = [
  {
    label: <Styled.SelectAll>{translate('select_all_label')}</Styled.SelectAll>,
    value: 'all'
  }
]
