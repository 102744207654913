// @flow

import React, { memo, useMemo } from 'react'
import * as Styled from './styled'
import { getGuestsAnswerDropdownList } from './utils'

const AnswerDropdown = ({
  guestsCountsByAnswer,
  answer,
  setGuestsListAnswer
}) => {
  const guestAnswerDropdownList = useMemo(
    () => getGuestsAnswerDropdownList(guestsCountsByAnswer),
    [guestsCountsByAnswer]
  )

  const filteredGuestAnswerDropdownList = useMemo(
    () =>
      guestAnswerDropdownList.filter(
        guestAnswer => guestAnswer.value !== answer
      ),
    [guestAnswerDropdownList, answer]
  )

  const guestAnswerDropdownActiveItem = useMemo(
    () => guestAnswerDropdownList.find(guest => guest.value === answer),
    [answer, guestAnswerDropdownList]
  )

  return (
    <Styled.Dropdown
      dropdownList={filteredGuestAnswerDropdownList}
      activeItem={guestAnswerDropdownActiveItem}
      onListItemClick={setGuestsListAnswer}
    />
  )
}

export default memo(AnswerDropdown)
