import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'

export const Header = styled.div`
  padding: 30px 40px;

  @media only screen and (max-width: 768px) {
    padding: 20px 20px 30px;
  }
`

export const Title = styled.h2`
  font-size: 20px;
  color: ${secondary.ExtraDark};
  font-weight: 700;
`

export const Description = styled.p`
  font-size: 14px;
  color: ${secondary.ExtraDark};
  max-width: 351px;
  margin-top: 8px;
  line-height: 20px;
`

export const Main = styled.div`
  .checkbox-all {
    width: 100%;
    padding: 0 40px 20px;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0;
    box-sizing: border-box;
    & > div {
      margin-right: 0;
      font-weight: 400;
    }
  }

  .checkbox-list {
    width: 100%;
    padding: 10px 40px;
    box-sizing: border-box;
    border-top: 1px solid ${secondary.Light};
    border-bottom: 1px solid ${secondary.Light};

    & > label {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin-right: 0;
        font-weight: 400;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .checkbox-all {
      padding: 0 20px 20px;
    }

    .checkbox-list {
      padding: 10px 20px;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;

  & > button {
    padding: 11px 30px;
    &:first-child {
      margin-right: 20px;
    }
  }
`

export const CheckBoxLabel = styled.div`
  display: flex;
  align-items: center;

  .flag {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid ${secondary.ExtraLight};
    box-sizing: border-box;
  }

  .language {
    font-size: 14px;
    color: ${secondary.ExtraDark};
    margin-left: 15px;
    margin-right: 5px;
  }
`

export const SelectAll = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${secondary.ExtraDark};
`
