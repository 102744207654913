//@flow
import React, { memo } from 'react'
import produce from 'immer'

import Validatable from '@website/common/lib/ValidatableHOC'
import LibInput from '@editor/common/lib/Input'
import { translate } from '@editor/common/utils/translations'
import { LABELS_VALIDATIONS } from '@containers/edit/containers/event/consts'

import * as Styled from './styled'
import type { TGuestDetailsFormProps } from './types'

const Input = Validatable(LibInput)
const VALIDATIONS = LABELS_VALIDATIONS.form

const GuestDetailsForm = ({
  isInEdit,
  firstName,
  lastName,
  email,
  phone,
  formData,
  setFormData,
  formErrors,
  register
}: TGuestDetailsFormProps) => {
  const onInputChange = key => value => {
    setFormData(
      produce(draft => {
        draft[key] = value
      })
    )
  }

  return (
    <Styled.GuestDetailsForm isInEdit={isInEdit}>
      <Styled.SectionTitle isInEdit={isInEdit}>
        {isInEdit
          ? translate('guest_details_label')
          : translate('add_guest_details_label')}
      </Styled.SectionTitle>
      <Styled.InputPair isInEdit={isInEdit}>
        <Input
          isRequired={firstName.isRequired}
          value={formData.firstName}
          label={firstName.text}
          placeholder={translate('enter_guest_name_placeholder')}
          charLimit={VALIDATIONS.firstName.charLimit}
          errorMessage={formErrors.firstName}
          {...register({
            fieldName: 'firstName',
            initialValue: formData.firstName,
            onChange: onInputChange('firstName'),
            validationOptions: {
              isRequired: firstName.isRequired
            }
          })}
        />
        <Input
          isRequired={lastName.isRequired}
          value={formData.lastName}
          label={lastName.text}
          placeholder={translate('enter_guest_lastname_placeholder')}
          charLimit={VALIDATIONS.lastName.charLimit}
          errorMessage={formErrors.lastName}
          {...register({
            fieldName: 'lastName',
            initialValue: formData.lastName,
            onChange: onInputChange('lastName'),
            validationOptions: {
              isRequired: lastName.isRequired
            }
          })}
        />
      </Styled.InputPair>
      <Styled.InputPair isInEdit={isInEdit}>
        <Input
          isRequired={email.isRequired}
          value={formData.email}
          label={email.text}
          placeholder={translate('enter_guest_email_placeholder')}
          charLimit={VALIDATIONS.email.charLimit}
          errorMessage={formErrors.email}
          {...register({
            fieldName: 'email',
            initialValue: formData.email,
            onChange: onInputChange('email'),
            validationOptions: {
              isRequired: email.isRequired,
              isEmail: true
            }
          })}
        />
        <Input
          isRequired={phone.isRequired}
          value={formData.phone}
          label={phone.text}
          placeholder={translate('enter_guest_phone_number_placeholder')}
          charLimit={VALIDATIONS.phone.charLimit}
          errorMessage={formErrors.phone}
          {...register({
            fieldName: 'phone',
            initialValue: formData.phone,
            onChange: onInputChange('phone'),
            validationOptions: {
              isRequired: phone.isRequired,
              isPhone: true
            }
          })}
        />
      </Styled.InputPair>
    </Styled.GuestDetailsForm>
  )
}

export default memo(GuestDetailsForm)
