//@flow
import React, { memo, useCallback } from 'react'

import Checkbox from '@editor/common/lib/Checkbox'
import DatePickerInputs from '@editor/common/components/DatePickerInputs'

import { ADD_END_DATE_CHECKBOX_DATA } from './consts'
import type { TDateBlockProps } from './types'

const DateBlock = ({ data, updateData }: TDateBlockProps) => {
  const { startDateInfo, endDateInfo } = data

  const onDateSelect = key => date => updateData(key, { date })

  const onTimeSelect = key => time => updateData(key, { time })

  const onCheckboxChange = useCallback(
    (e, value, isVisible) => {
      updateData('endDateInfo', { isVisible })
    },
    [updateData]
  )

  return (
    <>
      <DatePickerInputs
        isDateRequired
        isTimeRequired
        date={startDateInfo.date}
        time={startDateInfo.time}
        onDateSelect={onDateSelect('startDateInfo')}
        onTimeSelect={onTimeSelect('startDateInfo')}
      />
      <Checkbox
        isChecked={endDateInfo.isVisible}
        className="checkbox"
        data={ADD_END_DATE_CHECKBOX_DATA}
        onChange={onCheckboxChange}
      />
      {endDateInfo.isVisible ? (
        <DatePickerInputs
          isDateRequired
          isTimeRequired
          date={endDateInfo.date}
          time={endDateInfo.time}
          onDateSelect={onDateSelect('endDateInfo')}
          onTimeSelect={onTimeSelect('endDateInfo')}
        />
      ) : null}
    </>
  )
}

export default memo(DateBlock)
