/* @flow */
import React, { useState, memo, useCallback } from 'react'
import Timepicker from 'react-datepicker'

import TimeIcon from '@editor/common/assets/svgr-icons/rsvp/time.svg'
import { translate } from '@editor/common/utils/translations'

import type { TTimePickerInputProps } from './types'
import { Label, DatePickerContainer } from '../styled'
import * as Styled from './styled'
import { formatTime, getSelectedTime } from './utils'

const TimePickerInput = ({
  time,
  onTimeSelect,
  isRequired
}: TTimePickerInputProps) => {
  const [isTimePickerOpened, setTimePickerOpenedState] = useState(false)

  const openTimePicker = useCallback(() => {
    setTimePickerOpenedState(true)
  }, [])

  const closeTimePicker = useCallback(() => {
    setTimePickerOpenedState(false)
  }, [])

  const onChange = useCallback(
    time => {
      if (time === null) return

      onTimeSelect(formatTime(time))
    },
    [onTimeSelect]
  )

  return (
    <DatePickerContainer>
      <Label>
        {translate('time_label')} {isRequired ? '*' : ''}
      </Label>
      <Styled.TimePickerWrapper isInputActive={isTimePickerOpened}>
        <TimeIcon />
        <Timepicker
          showTimeSelect
          showTimeSelectOnly
          dateFormat="h:mm aa"
          selected={getSelectedTime(time)}
          onChange={onChange}
          onCalendarOpen={openTimePicker}
          onCalendarClose={closeTimePicker}
          timeIntervals={10}
          className="date-picker-input"
        />
      </Styled.TimePickerWrapper>
    </DatePickerContainer>
  )
}

export default memo(TimePickerInput)
