import styled from 'styled-components'
import {
  RequiredControl as CommonRequiredControl,
  InputWrapper as CommonInputWrapper
} from '../styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .add-phone-number-label {
    margin-top: 30px;
  }
`

export const RSVPOptionForm = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputWrapper = styled(CommonInputWrapper)`
  .input-container {
    margin-top: 20px;
  }

  .tooltip {
    height: 100%;
  }
`

export const RequiredControl = styled(CommonRequiredControl)`
  top: 15px;
`
