/* @flow */
import React, { useCallback } from 'react'
import PreviewIcon from '@editor/common/assets/svgr-icons/Preview_icon.svg'
import * as Styled from './styled'

const MobilePreviewButton = ({ onClick }) => {
  //fix mobilePreview lazyLoad issue
  const handleClick = useCallback(() => {
    onClick()
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 100)
  }, [onClick])
  return (
    <Styled.PreviewButton onClick={handleClick}>
      <PreviewIcon />
    </Styled.PreviewButton>
  )
}

export default MobilePreviewButton
