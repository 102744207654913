import styled from 'styled-components'

import { white } from '@website/common/styles/colors'
import { Divider as _Divider } from '../../styled'
import { saveDiscardFooterStyles } from '../styled'

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  width: 468px;
  height: 100%;

  .save-cancel-footer {
    ${saveDiscardFooterStyles};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const MainContent = styled.div`
  flex-grow: 1;
  padding: 24px;
  overflow-y: auto;

  .input-container {
    margin-top: 20px;
  }
`

export const Divider = styled(_Divider)`
  margin: 40px 0 30px;
`

export const ReplyToInputWrapper = styled.div`
  .error-message {
    max-width: unset;
  }
`
