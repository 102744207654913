//@flow
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { isSubscriber } from '@selectors'

import FreeUserView from './FreeUserView'
import PaidUserView from './PaidUserView'
import * as Styled from './styled'
import { TProps } from './types'

const Email = ({ setHasChangesInTab }: TProps) => {
  const isPaidUser = useSelector(isSubscriber)

  return (
    <Styled.Container>
      {isPaidUser ? (
        <PaidUserView setHasChangesInTab={setHasChangesInTab} />
      ) : (
        <FreeUserView />
      )}
    </Styled.Container>
  )
}

export default memo(Email)
