//@flow
import React, { useContext, memo } from 'react'

import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import EmailBody from '@renderforest/email-body-generator'

import MobilePreviewHeader from '../../../MobilePreviewHeader'
import { SUBJECT_PLACEHOLDER } from './consts'
import * as Styled from './styled'
import type { TProps } from './types'

const Preview = ({ onClose, subject, ...rest }: TProps) => {
  const isMobile = useContext(MobileDetectContext)

  return (
    <Styled.PreviewContent>
      {isMobile ? (
        <MobilePreviewHeader onClick={onClose} />
      ) : (
        <Styled.TextHeader>{translate('preview_label')}</Styled.TextHeader>
      )}
      <Styled.PreviewWrapper>
        <Styled.PreviewContentWrapper>
          <Styled.SubjectSection>
            <Styled.SubjectLabel>
              {`${translate('email_subject_label')}: `}{' '}
              <Styled.SubjectPlaceholder>
                {!subject ? SUBJECT_PLACEHOLDER : ''}
              </Styled.SubjectPlaceholder>
            </Styled.SubjectLabel>
            <Styled.Subject>{subject}</Styled.Subject>
          </Styled.SubjectSection>
          <EmailBody {...rest} />
        </Styled.PreviewContentWrapper>
      </Styled.PreviewWrapper>
    </Styled.PreviewContent>
  )
}

export default memo(Preview)
