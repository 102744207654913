import styled from 'styled-components'
import Button from '@editor/common/lib/Button'

export const UpgradeButton = styled(Button)`
  display: flex;
  align-items: center;
  & > svg {
    fill: white;
    width: 16px;
    height: auto;
    margin-right: 8px;
  }
`
