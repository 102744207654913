//@flow
import React, { memo } from 'react'

import * as S from './styled'
import type { TProps } from './types'

const UpgradeBanner = ({
  title = '',
  paragraph = '',
  bgImageUrl,
  className
}: TProps) => (
  <S.BannerContainer bgImageUrl={bgImageUrl} className={className}>
    <S.Title>{title}</S.Title>
    <S.Paragraph>{paragraph}</S.Paragraph>
    <S.UpgradeButton />
  </S.BannerContainer>
)
export default memo(UpgradeBanner)
