// @flow

import React, { memo } from 'react'

import { translate } from '@editor/common/utils/translations'
import { Answer } from './styled'
import type { TRsvpAnswerProps } from './types'

const RsvpAnswer = ({ answer }: TRsvpAnswerProps) => (
  <Answer answer={answer}>
    {answer === 'yes' ? translate('yes_label') : translate('no_label')}
  </Answer>
)

export default memo(RsvpAnswer)
