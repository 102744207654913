import styled from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'
import LibButton from '@editor/common/lib/Button'
import {
  RequiredControl as CommonRequiredControl,
  InputWrapper as CommonInputWrapper
} from '../styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .input-container {
    width: 100%;
  }

  #field-0 {
    margin-top: 20px;
  }
`

export const AdditionalFieldsSubtitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${secondary.ExtraDark};
  position: relative;
`

export const Button = styled(LibButton)`
  width: fit-content;
`

export const AnswerButton = styled(Button)`
  margin-top: 30px;
`

export const AnswerWrapper = styled(CommonInputWrapper)`
  .input-container {
    margin-top: 35px;
  }

  .answer-delete-icon {
    height: 24px;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 1;
  }
`

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: ${white};
  border: 1px solid ${secondary.Light};
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1px;

  svg {
    margin-top: 3px;
    width: 20px;
    fill: #8493bd;
  }

  :hover {
    cursor: pointer;
    border: 1px solid ${secondary.SemiDark};

    svg {
      fill: ${secondary.SemiDark};
    }
  }
`

export const IconsSection = styled.div`
  display: flex;
  height: 24px;
  position: absolute;
  right: 0;
  top: -5px;
  z-index: 1;
`

export const RequiredControl = styled(CommonRequiredControl)`
  position: initial;
`

export const FieldInput = styled.div`
  border: 1px solid ${secondary.Light};
  border-radius: 5px;
  padding: 10px 0 10px 15px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${secondary.ExtraDark};
`
