import styled from 'styled-components'
import LibNotificationMessage from '@editor/common/lib/NotificationMessage'
import { secondary } from '@website/common/styles/colors'

export const InputsContainer = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid ${secondary.Light};

  .input-container .error-message {
    @media only screen and (max-width: 568px) {
      max-width: 100% !important;
    }
  }

  & > div:not(:first-child) {
    margin-top: 20px;
  }

  .checkbox {
    margin: 30px 0 0;
    max-width: max-content;
  }

  .paragraph {
    height: 80px;
  }
`

export const NotificationMessage = styled(LibNotificationMessage)`
  margin-top: 20px;
  .error-message {
    @media only screen and (max-width: 568px) {
      max-width: 100% !important;
    }
  }
`

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${secondary.Light};
  margin: 30px 0;
  z-index: 2;
`
