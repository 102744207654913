import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectConfirmationEmailData, selectDetailsData } from '@selectors'

import {
  CONFIRMATION_EMAIL_DEFAULT_MESSAGE,
  EMAIL_DEFAULT_GREETING
} from '../../consts'

const useInitialData = () => {
  const detailsData = useSelector(selectDetailsData)
  const confirmationEmailData = useSelector(selectConfirmationEmailData)

  return useMemo(
    () =>
      confirmationEmailData || {
        title: { text: detailsData.title.text },
        subject: { text: detailsData.title.text },
        message: { text: CONFIRMATION_EMAIL_DEFAULT_MESSAGE },
        greeting: { text: EMAIL_DEFAULT_GREETING }
      },
    [confirmationEmailData, detailsData]
  )
}
export default useInitialData
