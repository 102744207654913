import styled, { css } from 'styled-components'

import LibCheckbox from '@renderforest/rf-ui-library/molecules/Checkbox'
import { secondary, primary, accentRed } from '@website/common/styles/colors'

const getCheckboxStyles = ({ isChecked, hasError }) => css`
  margin-right: 10px;
  font-size: 14px;

  &:after {
    border: 1px solid
      ${hasError ? accentRed : isChecked ? primary.Dark : secondary.Light};
  }
`

export const Checkbox = styled(LibCheckbox)`
  & > div {
    ${getCheckboxStyles};
  }

  > div:first-child {
    flex-shrink: 0;
  }
`

export const CheckboxList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const ErrorMessage = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  font-weight: 500;
  color: ${accentRed};
  padding-top: 5px;
  max-width: 400px;

  svg {
    width: 14px;
    height: 14px;
    padding-right: 5px;

    & * {
      fill: ${accentRed};
    }
  }
`
