//@flow
import React, { memo, Fragment } from 'react'

import ArrowLeft from '@website/common/assets/svgr-icons/keyboard_arrow_left.svg'
import { translate } from '@editor/common/utils/translations'

import RsvpAnswer from '../RsvpAnswer'
import { SlideLeft, Layer } from '../styled'
import * as Styled from './styled'
import type { TGuestDetailsProps } from './types'

const GuestDetails = ({ isOpen, onClose, data }: TGuestDetailsProps) => {
  const {
    rsvpOption,
    firstName,
    lastName,
    email,
    phone,
    additionalGuests,
    dynamicFields
  } = data || {}

  return (
    <>
      <SlideLeft isOpen={isOpen} onClose={onClose} shouldCloseOnBackdropClick>
        <Styled.Container>
          <Styled.Header>
            <Styled.FullName>
              {firstName} {lastName}
            </Styled.FullName>
            <RsvpAnswer answer={rsvpOption?.answer} />
          </Styled.Header>
          <Styled.MainContent>
            <Styled.BlockHeader>
              {translate('contacts_label')}
            </Styled.BlockHeader>
            <Styled.BlockContent>
              <Styled.BlockTitle>{translate('email_label')}</Styled.BlockTitle>
              <Styled.BlockText>{email}</Styled.BlockText>
              <Styled.BlockTitle>{translate('phone_label')}</Styled.BlockTitle>
              <Styled.BlockText>{phone}</Styled.BlockText>
            </Styled.BlockContent>
            <Styled.BlockHeader>
              {translate('additional_guests_label')}
            </Styled.BlockHeader>
            <Styled.BlockContent>
              {additionalGuests && additionalGuests.length ? (
                <>
                  <Styled.BlockTitle>
                    {translate('full_name_label')}
                  </Styled.BlockTitle>
                  <Styled.List>
                    {additionalGuests.map((additionalGuest, idx) => (
                      <Styled.ListItem key={idx}>
                        {additionalGuest.firstName} {additionalGuest.lastName}
                      </Styled.ListItem>
                    ))}
                  </Styled.List>
                </>
              ) : (
                <Styled.NotFountMessage>
                  {translate('no_additional_guests')}
                </Styled.NotFountMessage>
              )}
            </Styled.BlockContent>
            <Styled.BlockHeader>
              {translate('more_information_label')}
            </Styled.BlockHeader>
            <Styled.BlockContent>
              {dynamicFields && dynamicFields.length ? (
                dynamicFields.map((dynamicField, idx) => {
                  const answer = dynamicField.answer

                  return (
                    <Fragment key={idx}>
                      <Styled.BlockTitle>{dynamicField.text}</Styled.BlockTitle>
                      <Styled.BlockText key={idx}>
                        {Array.isArray(answer)
                          ? answer.map(answer => answer.text).join(', ')
                          : answer}
                      </Styled.BlockText>
                    </Fragment>
                  )
                })
              ) : (
                <Styled.NotFountMessage>
                  {translate('no_information')}
                </Styled.NotFountMessage>
              )}
            </Styled.BlockContent>
          </Styled.MainContent>
          <Styled.Footer>
            <Styled.BackButton onClick={onClose}>
              <ArrowLeft />
              {translate('back_label')}
            </Styled.BackButton>
          </Styled.Footer>
        </Styled.Container>
      </SlideLeft>
      {isOpen ? <Layer /> : null}
    </>
  )
}

export default memo(GuestDetails)
