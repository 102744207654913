/* @flow */
import React from 'react'
import { translate } from '@editor/common/utils/translations'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_left.svg'
import * as Styled from './styled'

const MobilePreviewHeader = ({ onClick }) => (
  <Styled.PreviewHeader>
    <Styled.PreviewLabel>{translate('preview_label')}</Styled.PreviewLabel>
    <Styled.Back onClick={onClick}>
      <ArrowIcon className="arrow-icon" />
      {translate('back_label')}
    </Styled.Back>
  </Styled.PreviewHeader>
)

export default MobilePreviewHeader
