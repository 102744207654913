//@flow
import React, { memo } from 'react'
import LibInput from '@editor/common/lib/Input'
import { translate } from '@editor/common/utils/translations'
import Validatable from '@website/common/lib/ValidatableHOC'

import * as Styled from './styled'
import type { TProps } from './types'

const Input = Validatable(LibInput)

const EmailForm = ({
  emailData,
  setEmailData,
  formErrors,
  register
}: TProps) => {
  const { subject, title, greeting, message } = emailData

  return (
    <Styled.From>
      <Input
        isRequired
        label={translate('email_subject_label')}
        value={subject.text}
        charLimit={60}
        errorMessage={formErrors.subject}
        {...register({
          fieldName: 'subject',
          initialValue: subject.text,
          onChange: setEmailData('subject'),
          validationOptions: {
            isRequired: true
          }
        })}
      />
      <Input
        isRequired
        label={translate('title_label')}
        value={title.text}
        charLimit={40}
        errorMessage={formErrors.title}
        {...register({
          fieldName: 'title',
          initialValue: title.text,
          onChange: setEmailData('title'),
          validationOptions: {
            isRequired: true
          }
        })}
      />
      <LibInput
        label={translate('greeting_label')}
        value={greeting.text}
        charLimit={30}
        onChange={setEmailData('greeting')}
      />
      <Input
        isRequired
        isTextArea
        label={translate('message_label')}
        value={message.text}
        charLimit={300}
        errorMessage={formErrors.message}
        {...register({
          fieldName: 'message',
          initialValue: message.text,
          onChange: setEmailData('message'),
          validationOptions: {
            isRequired: true
          }
        })}
      />
    </Styled.From>
  )
}

export default memo(EmailForm)
