//@flow
import React, { useCallback, useState, memo } from 'react'
import { useSelector } from 'react-redux'

import { useResponsive } from '@shared/hooks'
import { SM_API_PATH } from '@shared/ApiConfig'
import Popup from '@editor/common/lib/Popup'
import PlusIcon from '@editor/common/assets/svgr-icons/Plus_icon.svg'
import UploadIcon from '@editor/common/assets/svgr-icons/Upload.svg'
import { translate } from '@editor/common/utils/translations'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import {
  getSiteId,
  selectEventId,
  selectGuestsCountsByLanguage
} from '@selectors'
import { openUrl } from '@website/common/utils'

import { TextIconBtn } from '../styled'
import CSVPopupContent from './CSVPopupContent'
import * as Styled from './styled'
import type { TProps } from './types'

const Buttons = ({
  onAddGuestClick,
  isDownloadBtnDisabled,
  isAddGuestBtnDisabled
}: TProps) => {
  const siteId = useSelector(getSiteId)
  const eventId = useSelector(selectEventId)
  const guestsCountsByLanguage = useSelector(selectGuestsCountsByLanguage)

  const [isCSVPopupOpened, setCSVPopupOpenedState] = useState(false)
  const [selectedLanguages, setSelectedLanguages] = useState([])

  const haveGuestsByLang = guestsCountsByLanguage.length > 1

  const isMobileOrTablet = useResponsive(1024)

  const downloadCSV = useCallback(() => {
    const queryString = haveGuestsByLang
      ? `langs=${JSON.stringify(selectedLanguages)}`
      : ''

    const reqUrl = `${SM_API_PATH}/sites/${siteId}/events/guestList/csv?eventId=${eventId}&${queryString}`

    openUrl(reqUrl)
  }, [siteId, eventId, haveGuestsByLang, selectedLanguages])

  const onDownloadBtnClick = useCallback(() => {
    if (haveGuestsByLang) {
      setCSVPopupOpenedState(true)
      return
    }

    downloadCSV()
  }, [haveGuestsByLang, downloadCSV])

  const closeCSVPopup = useCallback(() => {
    setCSVPopupOpenedState(false)
  }, [])

  return (
    <>
      <Styled.Buttons>
        <TooltipWrapper
          text={
            isDownloadBtnDisabled || isMobileOrTablet
              ? ''
              : translate('download_csv_label')
          }
        >
          <Styled.DownloadBtn
            onClick={onDownloadBtnClick}
            disabled={isDownloadBtnDisabled}
          >
            <UploadIcon />
          </Styled.DownloadBtn>
        </TooltipWrapper>
        <TooltipWrapper
          text={
            isAddGuestBtnDisabled
              ? translate('please_publish_your_website_to_add_guests')
              : ''
          }
        >
          <TextIconBtn
            type="tertiary"
            onClick={onAddGuestClick}
            isDisabled={isAddGuestBtnDisabled}
          >
            <PlusIcon /> <span>{translate('add_guest_label')}</span>
          </TextIconBtn>
        </TooltipWrapper>
      </Styled.Buttons>
      <Popup isOpen={isCSVPopupOpened} onClose={closeCSVPopup}>
        <CSVPopupContent
          data={guestsCountsByLanguage}
          selectedLanguages={selectedLanguages}
          setSelectedLanguages={setSelectedLanguages}
          onCancel={closeCSVPopup}
          onDownload={downloadCSV}
        />
      </Popup>
    </>
  )
}

export default memo(Buttons)
