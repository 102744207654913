//@flow
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import { addGuest, setEventSuccessMessage } from '@actions'
import SaveAndDiscardFooter from '@editor/common/components/SaveAndDiscardFooter'
import { translate } from '@editor/common/utils/translations'
import { generateUniqId } from '@editor/common/utils'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import { selectEventSuccessMessage } from '@selectors'
import {
  getInitialState,
  preparePayloadData
} from '@website/common/components/RSVPForm/utils'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'

import { SlideLeft } from '../styled'
import {
  AdditionalGuests,
  AdditionalInformation,
  GuestDetailsForm,
  RSVPOptions
} from './components'
import * as Styled from './styled'
import { TAddGuestProps } from './types'

const AddGuest = ({
  isOpen,
  formDataFromRevision,
  onClose,
  setHasChangesInTab,
  addGuest,
  successMessage,
  setEventSuccessMessage
}: TAddGuestProps) => {
  const initialState = useMemo(
    () => getInitialState(formDataFromRevision),
    [formDataFromRevision]
  )
  const [formData, setFormData] = useState(initialState)
  const [formKey, setFormKey] = useState(generateUniqId())

  const {
    firstName,
    lastName,
    email,
    phone,
    rsvpOption,
    dynamicFields,
    additionalGuestNumber
  } = formDataFromRevision

  const hasChanges = JSON.stringify(initialState) !== JSON.stringify(formData)

  useEffect(() => {
    setHasChangesInTab(hasChanges)
  }, [hasChanges])

  const _onClose = useCallback(() => {
    onClose()
    setFormData(initialState)
    successMessage && setEventSuccessMessage('')
  }, [initialState, onClose, successMessage, setEventSuccessMessage])

  const closeSidebar = useDiscardDialog(_onClose, {
    id: 'edit-guest',
    hasChanges
  })

  const resetData = () => {
    setFormData(initialState)
    setFormKey(generateUniqId())
  }

  const onSave = () => {
    addGuest(preparePayloadData(formData), resetData)
  }

  const { register, formErrors, handleSubmit } = useFormValidation(onSave)

  return (
    <SlideLeft
      isOpen={isOpen}
      onClose={closeSidebar}
      shouldCloseOnBackdropClick
      backdropDisablingSelectors={['.global-error-popup']}
    >
      <Styled.Content>
        <Styled.Form key={formKey}>
          <GuestDetailsForm
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            formData={formData}
            setFormData={setFormData}
            formErrors={formErrors}
            register={register}
          />
          {rsvpOption.isVisible ? (
            <RSVPOptions
              rsvpOption={rsvpOption}
              rsvpOptionAnswer={formData.rsvpOption.answer}
              setFormData={setFormData}
            />
          ) : null}
          {formData.rsvpOption.answer === 'yes' ? (
            <>
              <AdditionalGuests
                additionalGuests={formData.additionalGuests}
                additionalGuestNumber={additionalGuestNumber}
                firstName={firstName}
                lastName={lastName}
                setFormData={setFormData}
                formErrors={formErrors}
                register={register}
              />
              {!!dynamicFields.length ? (
                <AdditionalInformation
                  dynamicFields={dynamicFields}
                  formData={formData}
                  setFormData={setFormData}
                  register={register}
                  formErrors={formErrors}
                />
              ) : null}
            </>
          ) : null}
        </Styled.Form>
        <SaveAndDiscardFooter
          className="save-cancel-footer"
          discardBtnText={translate('cancel_label')}
          successMessage={successMessage}
          isSaveBtnDisabled={!hasChanges}
          onDiscard={closeSidebar}
          onSave={handleSubmit}
          setSuccessMessage={setEventSuccessMessage}
        />
      </Styled.Content>
    </SlideLeft>
  )
}

const mapStateToProps = state => ({
  successMessage: selectEventSuccessMessage(state)
})

const mapDispatchToProps = { addGuest, setEventSuccessMessage }

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddGuest))
