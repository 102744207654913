import styled from 'styled-components'
import THEME from './theme'
import { styleProvider } from '../../utils'
export const getStyleForProp = styleProvider(THEME)

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.palette.primaryDark};
  margin-bottom: 10px;
  font-size: 14px;
`

export const Title = styled.h6`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-bottom: 20px;
  font-size: 42px;
  word-break: break-word;
`

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  margin-bottom: 30px;
  font-size: 18px;
`

export const DateInfo = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-bottom: 30px;
  font-size: 18px;
`

export const Location = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-bottom: 30px;
  font-size: 18px;
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('buttonRadius')};
  white-space: nowrap;
  ${getStyleForProp('buttonShadow')};
  ${getStyleForProp('buttonBackground')};
  text-decoration: none;
  padding: 0 40px;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
  height: 50px;
  background-repeat: no-repeat !important;
  font-size: 14px;
  font-weight: 500;
`

export const ComponentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  ${SubTitle} {
    font-weight: 500;
  }
`
