import { translate } from '@editor/common/utils/translations'

import DetailsIcon from '@editor/common/assets/svgr-icons/rsvp/event_details.svg'
import FormsIcon from '@editor/common/assets/svgr-icons/rsvp/event_forms.svg'
import PopupsIcon from '@editor/common/assets/svgr-icons/rsvp/popups.svg'
import SettingsIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'
import GuestListIcon from '@editor/common/assets/svgr-icons/rsvp/guest_list.svg'
import TranslationsIcon from '@editor/common/assets/svgr-icons/translate_icon.svg'
import EmailIcon from '@editor/common/assets/svgr-icons/event_category_icon.svg'

export const MENU_METADATA = [
  {
    label: translate('event_details_label'),
    icon: DetailsIcon,
    route: '/details'
  },
  {
    label: translate('event_forms_label'),
    icon: FormsIcon,
    route: '/forms'
  },
  {
    label: translate('event_popups_label'),
    icon: PopupsIcon,
    route: '/popups'
  },
  {
    label: translate('email_label'),
    icon: EmailIcon,
    route: '/email'
  },
  {
    label: translate('event_settings_label'),
    icon: SettingsIcon,
    route: '/settings'
  },
  {
    label: translate('event_guest_list_label'),
    icon: GuestListIcon,
    route: '/guest-list'
  },
  {
    label: translate('event_translation_label'),
    icon: TranslationsIcon,
    route: '/translation'
  }
]

export const MOBILE_TABS_DATA = [
  {
    label: translate('event_details_label'),
    value: 'details'
  },
  {
    label: translate('event_forms_label'),
    value: 'forms'
  },
  {
    label: translate('event_popups_label'),
    value: 'popups'
  },
  {
    label: translate('email_label'),
    value: 'email'
  },
  {
    label: translate('event_settings_label'),
    value: 'settings'
  },
  {
    label: translate('event_guest_list_label'),
    value: 'guest-list'
  },
  {
    label: translate('event_translation_label'),
    value: 'translation'
  }
]

export const LEAVE_TAB_DIALOG_BUTTONS = {
  cancel: translate('stay_label'),
  confirm: translate('leave_label', true)
}
