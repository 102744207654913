import styled from 'styled-components'
import { secondary, primary } from '@website/common/styles/colors'

import { LeftSection as CommonLeftSection } from '../styled'
import { RightSection as CommonRightSection } from '../styled'
import _SectionTitle from '../../components/SectionTitle'

export const LeftSection = styled(CommonLeftSection)`
  width: 45%;
  padding: 30px 40px;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    padding-top: 0;
    margin-top: -5px;
  }
  .input-container .error-message {
    @media only screen and (max-width: 568px) {
      max-width: 100% !important;
    }
  }

  .popups-tabs {
    border-bottom: 1px solid ${secondary.Light};

    li > div {
      margin: 0 10px 6px;
    }

    li:not(:first-child) {
      margin-left: 19px;
    }

    .active-line {
      height: 6px;
    }
  }
`

export const RightSection = styled(CommonRightSection)`
  @media only screen and (max-width: 1024px) {
    padding-top: 0;
  }
`

export const SectionTitle = styled(_SectionTitle)``

export const ActiveContent = styled.div`
  padding-top: 20px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  .input-container {
    margin-top: 20px;
  }

  .paragraph-text-area {
    height: 93px;
  }
  &:not(:first-child) {
    ${SectionTitle} {
      padding-top: 30px;
      border-top: 1px solid ${secondary.Light};
    }
  }
  @media only screen and (max-width: 1024px) {
    &:nth-child(3) {
      margin-top: 30px;
    }
  }
`

export const Description = styled.p`
  font-size: 14px;
  color: ${secondary.SemiDark};
`

export const CheckboxContainer = styled.div`
  margin-top: 30px;

  .add-calendar-checkbox {
    div:nth-child(2) {
      width: 95%;
    }
    + .input-container {
      @media only screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
  }
`

export const PopupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
  zoom: unset;
`

export const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &:not(:first-child) {
    border-top: 1px solid ${secondary.Light};
  }
`

export const PopupHeader = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
  color: ${secondary.SemiDark};
`

export const ResigtrationClosedMessage = styled.div`
  border-radius: 5px;
  border: 1px solid ${primary.Normal};
  background: ${primary.Light};
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${secondary.Dark};
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
`
