//@flow
import React, { memo } from 'react'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import Checkbox from '@editor/common/lib/Checkbox'

import * as Styled from './styled'

const SectionTitleWithCheckbox = ({
  label,
  isChecked,
  onChange,
  tooltipText,
  className
}) => (
  <Styled.SectionTitleWrapper className={className}>
    <Checkbox
      className="checkbox-label"
      data={[{ label }]}
      isChecked={isChecked}
      onChange={onChange}
    />
    {tooltipText ? (
      <TooltipWrapper
        text={tooltipText}
        className="info-icon-wrapper"
        position="top"
      >
        <InfoIcon />
      </TooltipWrapper>
    ) : null}
  </Styled.SectionTitleWrapper>
)

export default memo(SectionTitleWithCheckbox)
